import i18n from "i18n";
import axios from "axios";
import RouteForm from "./route_form";
import { Loader } from "react-loaders";
import React, { Fragment } from "react";
import { withRouter } from "react-router";
import Selector from "ops/common/selector";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import { AlertNotification } from "ops/common/alert_notification";
import { Form, Label, Row, Col, Card, CardBody, Button } from "reactstrap";

class NewPlan extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			routes: [],
			projects: [],
			projectsLegs: [],
			formLoading: false,
			selectedProjects: [],
		};
	}

	getSelectedProjects = (e, v) => {
		if (v) {
			this.setState({ selectedProjects: v });
		} else this.setState({ selectedProjects: [] });
	};

	getProjectsLegs = () => {
		if (this.state.selectedProjects.length === 0) {
			AlertNotification("Please select at least one project first", "warning");
			return;
		}
		let projectsIDs = this.state.selectedProjects.map((p) => {
			return p.id;
		});
		axios.get(`projectleg/?serializer=newplan&standard__isArchive=false&project_id__in=${projectsIDs}`).then((res) => {
			this.setState({ projectsLegs: res.data });
		});
	};

	addRoute = () => {
		this.setState({ routes: this.state.routes.concat({ projectLegs: [] }) });
	};

	removeRoute = (e) => {
		let routes = [...this.state.routes];
		routes.splice(e.target.getAttribute("index"), 1);
		this.setState({ routes: routes });
	};

	getRouteValues = (e) => {
		let value = e.target.value;
		let routes = this.state.routes;
		let index = e.target.getAttribute("index");
		routes[index][e.target.id] = value;
		routes[index][`invalid${e.target.id.charAt(0).toUpperCase()}${e.target.id.slice(1, e.target.id.length)}`] = false;
		this.setState({ routes: routes });
	};

	getRouteSelectedValues = (e, v, index) => {
		if (v) {
			let legsIDS = v.map((leg, indx) => {
				return { projectLeg: leg.id, order: indx + 1 };
			});
			let targetID = e.target.id.split("-")[0];
			let routes = this.state.routes;
			routes[index][targetID] = legsIDS;
			routes[index][targetID + "Obj"] = v;
			routes[index][`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = false;
			this.setState({ routes: routes });
		}
	};

	validate = (e) => {
		let routes = [...this.state.routes];
		let index = e.target.getAttribute("name") ? e.target.getAttribute("name") : e.target.getAttribute("index");
		let targetID = e.target.id.split("-")[0];
		routes[index][`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = true;
		this.setState({ routes: routes });
	};

	submit = (e) => {
		e.preventDefault();
		if (this.state.selectedProjects.length === 0) {
			AlertNotification("Please select at least one project first", "warning");
			return;
		}
		if (this.state.routes.length === 0) {
			AlertNotification("Please add at least one route first", "warning");
			return;
		}
		let projectsIDs = this.state.selectedProjects.map((project) => {
			return project.id;
		});
		this.setState({ formLoading: true });
		axios
			.post(`plan/`, { projects: projectsIDs, routes: this.state.routes })
			.then((res) => {
				this.setState({ formLoading: false });
				if (res) this.props.history.push({ pathname: "/viewplan/", planID: res.data.id });
			})
			.catch((err) => {
				this.setState({ formLoading: false });
				if (err.response) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	getInitialData = () => {
		axios
			.get(`project/?serializer=id&plan__isnull=${true}&workOrders__isnull=${true}`)
			.then((res) => {
				this.setState({ projects: res.data });
			})
			.catch((err) => {
				console.error(err.response);
			});
	};

	componentDidMount() {
		this.getInitialData();
	}

	render() {
		const t = i18n.t;
		return (
			<Fragment>
				<PageTitleAlt2 heading={t("createPlan")} icon="bi bi-signpost-2 icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					active={this.state.formLoading}
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Form onInvalid={this.validate} onSubmit={this.submit}>
						<Row>
							<Col xs="12" sm="12" md="12" lg="12" xl="12">
								<Card className="main-card mb-3" key="0">
									<CardBody>
										<Row className="d-flex justify-content-start">
											<Col xs="12">
												<Label>{t("projects")}</Label>
												<Selector
													multiple
													size="small"
													id="projects"
													isObjectOption
													optionAccessor="code"
													options={this.state.projects}
													onChange={this.getSelectedProjects}
												/>
											</Col>
											<Col xs="12" className="d-flex justify-content-end mt-2">
												<GetPermission perm="ops.add_plan">
													<div code="perm">
														<Button color="dark" onClick={this.getProjectsLegs} className="mr-2">
															{t("getLegs")}
														</Button>
														<Button
															color="dark"
															className="mr-2"
															onClick={this.addRoute}
															hidden={this.state.projectsLegs.length === 0}>
															{t("addRoute")}
														</Button>
														<Button color="info" type="submit">
															{t("submit")}
														</Button>
													</div>
												</GetPermission>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Row hidden={this.state.routes.length === 0}>
							<Col xs="12">
								<Card className="main-card mb-3">
									<CardBody>
										<div>
											<div className="dropdown-menu-header m-0">
												<div className="dropdown-menu-header-inner m-0 rounded bg-primary">
													<div className="menu-header-content">
														<h5 className="menu-header-title">{t("routes")}</h5>
													</div>
												</div>
											</div>
											<Row className="mt-3">
												{this.state.routes.map((route, index) => {
													return (
														<Col lg="6" key={index}>
															<RouteForm
																index={index}
																route={route}
																onDelete={this.removeRoute}
																legs={this.state.projectsLegs}
																getValue={this.getRouteValues}
																getSelectedValue={this.getRouteSelectedValues}
															/>
														</Col>
													);
												})}
												<Col xs="12" className="d-flex justify-content-end">
													<GetPermission perm="ops.add_route">
														<div code="perm">
															<Button color="dark" onClick={this.addRoute}>
																{t("addRoute")}
															</Button>
															<Button color="info" className="ml-2">
																{t("submit")}
															</Button>
														</div>
													</GetPermission>
												</Col>
											</Row>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Form>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default withRouter(NewPlan);
