function generateValue(kmTravelled, totalFixedCost, totalVariableCost, sellingPriceFixed, sellingPriceVariable) {
	let totalCost = parseFloat(totalFixedCost) + parseFloat(kmTravelled) * parseFloat(totalVariableCost);
	let totalRevenue = parseFloat(sellingPriceFixed) + parseFloat(kmTravelled) * parseFloat(sellingPriceVariable);
	let grossMargin = totalRevenue - parseFloat(kmTravelled) * parseFloat(totalVariableCost);
	let netProfit = grossMargin - parseFloat(totalFixedCost);
	let newObj = {
		kmTravelled: kmTravelled,
		totalCost: totalCost.toFixed(2),
		totalRevenue: totalRevenue.toFixed(2),
		grossMargin: grossMargin.toFixed(2),
		netProfit: netProfit.toFixed(2),
	};
	return newObj;
}

function calculateLumpSum(data) {
	let arrayOfKmTravelled = [];
	let values = [];
	let value = {};
	for (let i = parseInt(data.start); i <= parseInt(data.end); i += parseInt(data.increment)) {
		arrayOfKmTravelled.push(i);
		value = generateValue(i, data.totalFixedCost, data.totalVariableCost, data.sellingPriceFixed, data.sellingPriceVariable);
		values.push(value);
	}
	if (!(arrayOfKmTravelled[arrayOfKmTravelled.length - 1] === parseInt(data.end))) {
		arrayOfKmTravelled.push(parseInt(data.end));
		value = generateValue(
			parseInt(data.end),
			data.totalFixedCost,
			data.totalVariableCost,
			data.sellingPriceFixed,
			data.sellingPriceVariable
		);
		values.push(value);
	}
	return values;
}

function calculateRental(data) {
	let values = [];
	let value = generateValue(8000, data.totalFixedCost, data.totalVariableCost, data.sellingPriceFixed, data.sellingPriceVariable);
	values.push(value);
	return values;
}

function calculateCPT(data) {
	let trips = data.trips;
	let {
		roundTripKM,
		payload,
		loadingDischargingTime,
		fuelRate,
		maintCostPerKM,
		roadScalesPerTrip,
		roadTollsPerTrip,
		portScalesPerTrip,
		portPermitsPerTrip,
		VATInsideThePortPerTrip,
		tipsPerTrip,
		otherExp,
	} = trips[data.index];
	roundTripKM = roundTripKM.trim() !== "" ? parseFloat(roundTripKM) : 0;
	payload = payload.trim() !== "" ? parseFloat(payload) : 0;
	loadingDischargingTime = loadingDischargingTime.trim() !== "" ? parseFloat(loadingDischargingTime) : 0;
	fuelRate = fuelRate.trim() !== "" ? parseFloat(fuelRate) : 0;
	maintCostPerKM = maintCostPerKM.trim() !== "" ? parseFloat(maintCostPerKM) : 0;
	roadScalesPerTrip = roadScalesPerTrip.trim() !== "" ? parseFloat(roadScalesPerTrip) : 0;
	roadTollsPerTrip = roadTollsPerTrip.trim() !== "" ? parseFloat(roadTollsPerTrip) : 0;
	portScalesPerTrip = portScalesPerTrip.trim() !== "" ? parseFloat(portScalesPerTrip) : 0;
	portPermitsPerTrip = portPermitsPerTrip.trim() !== "" ? parseFloat(portPermitsPerTrip) : 0;
	VATInsideThePortPerTrip = VATInsideThePortPerTrip.trim() !== "" ? parseFloat(VATInsideThePortPerTrip) : 0;
	tipsPerTrip = tipsPerTrip.trim() !== "" ? parseFloat(tipsPerTrip) : 0;
	otherExp = otherExp.trim() !== "" ? parseFloat(otherExp) : 0;

	if (roundTripKM) {
		const averageSpeed = parseFloat(roundTripKM) < 120 ? 35 : 50;
		const drivingTime = parseFloat(roundTripKM) / averageSpeed;
		const totalTime = drivingTime + (parseFloat(loadingDischargingTime) ?? 0);
		const SDT = data.workingHoursPerDay / totalTime;
		const driverIncomePerTrip = data.driverIncomePerDay / SDT;
		const fuelCostPerTrip = roundTripKM * (parseFloat(fuelRate) / 100) * data.fuelPrice;
		const maintCostPerTrip = roundTripKM * parseFloat(maintCostPerKM);
		const totalVariablePricePerTrip =
			driverIncomePerTrip +
			fuelCostPerTrip +
			maintCostPerTrip +
			parseFloat(roadScalesPerTrip) +
			parseFloat(roadTollsPerTrip) +
			parseFloat(portScalesPerTrip) +
			parseFloat(portPermitsPerTrip) +
			parseFloat(VATInsideThePortPerTrip) +
			parseFloat(tipsPerTrip) +
			parseFloat(otherExp);
		const totalFixedPricePerTrip = data.fixedCostPerDay / SDT;
		const totalPricePerTrip = totalVariablePricePerTrip + totalFixedPricePerTrip;
		const FCPerKM = totalFixedPricePerTrip / parseFloat(roundTripKM);
		const VCPerKM = totalVariablePricePerTrip / parseFloat(roundTripKM);
		const FCPerTon = totalFixedPricePerTrip / parseFloat(payload);
		const VCPerTon = totalVariablePricePerTrip / parseFloat(payload);
		const TCPerTon = totalPricePerTrip / parseFloat(payload);
		const sellingPrice = TCPerTon * (1 + data.profitMargin / 100);
		const calculated = {
			averageSpeed: averageSpeed,
			drivingTime: drivingTime,
			totalTime: totalTime,
			SDT: SDT,
			driverIncomePerTrip: driverIncomePerTrip,
			fuelCostPerTrip: fuelCostPerTrip,
			maintCostPerTrip: maintCostPerTrip,
			totalVariablePricePerTrip: totalVariablePricePerTrip,
			totalFixedPricePerTrip: totalFixedPricePerTrip,
			totalPricePerTrip: totalPricePerTrip,
			FCPerKM: FCPerKM,
			VCPerKM: VCPerKM,
			FCPerTon: FCPerTon,
			VCPerTon: VCPerTon,
			TCPerTon: TCPerTon,
			sellingPrice: sellingPrice,
		};
		const updatedTrip = {
			...trips[data.index],
			calculated,
		};
		const updatedTrips = [...trips];
		updatedTrips[data.index] = updatedTrip;
		return updatedTrips;
	}
	return trips;
}

export default function calculate(CostingType, data) {
	let values = [];
	if (CostingType === "Lump Sum") {
		values = calculateLumpSum(data);
	} else if (CostingType === "Rental") {
		values = calculateRental(data);
	} else if (CostingType === "CPT") {
		values = calculateCPT(data);
	}
	return values;
}
