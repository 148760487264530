import React, { Component } from "react";
import NewStandard from "../components/new_standard";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class NewStandardScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<NewStandard />
			</CSSTransitionGroup>
		);
	}
}

export default NewStandardScene;
