import i18n from "i18n";
import ReactToPrint from "react-to-print";
import React, { Component, Fragment } from "react";
import GetPermission from "ops/common/get_permission";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, ListGroup, ListGroupItem, Row } from "reactstrap";

class WorkOrderCard extends Component {
	render() {
		const t = i18n.t;
		const {
			close,
			addTrip,
			initExpense,
			getExpenses,
			viewPlan,
			plan,
			edit,
			confirmEdit,
			onCancelEdit,
			onConfirmEdit,
			logs,
			viewProject,
			editable,
			content,
			expensesForPrint,
			getExpensesForPrint,
		} = this.props;
		const {
			code,
			note,
			startDate,
			endDate,
			driver,
			assisstant,
			route,
			asset,
			testDriveRequest,
			testDriveRequest__code,
			attachedAsset,
			serviceType,
			project,
			startKM,
			endKM,
			enableCreateRFR,
			driver_hrcode,
			driver_phone,
		} = this.props.wo;
		return (
			<Fragment>
				<Card className="main-card mb-3">
					<CardHeader className="card-header-tab">
						<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
							<i className="header-icon pe-7s-note2 icon-gradient bg-malibu-beach"> </i>
							{t("woDetails")}
						</div>
					</CardHeader>
					<CardBody className="pt-0 pb-0">
						<Row className="no-gutters">
							<Col xs="12">
								<Row>
									<Col lg="3" className="pr-5">
										<ListGroup flush>
											<ListGroupItem>
												<div className="widget-content p-0">
													<div className="widget-content-outer">
														<div className="widget-content-wrapper">
															<div className="widget-content-left">
																<div className="widget-heading">{t("woCode")}</div>
																<div className="widget-heading text-info">{code}</div>
															</div>
														</div>
													</div>
												</div>
											</ListGroupItem>
											{project && (
												<ListGroupItem>
													<div className="widget-content p-0">
														<div className="widget-content-outer">
															<div className="widget-content-wrapper">
																<div className="widget-content-left">
																	<div className="widget-heading">{t("project")}</div>
																	<div className="widget-heading text-info">{project.code}</div>
																</div>
															</div>
														</div>
													</div>
												</ListGroupItem>
											)}
											<ListGroupItem>
												<div className="widget-content p-0">
													<div className="widget-content-outer">
														<div className="widget-content-wrapper">
															<div className="widget-content-left">
																<div className="widget-heading">{t("driver")}</div>
																<div className="widget-heading text-info">{driver}</div>
															</div>
														</div>
													</div>
												</div>
											</ListGroupItem>
											<ListGroupItem>
												<div className="widget-content p-0">
													<div className="widget-content-outer">
														<div className="widget-content-wrapper">
															<div className="widget-content-left">
																<div className="widget-heading">{t("driverPhone")}</div>
																<div className="widget-heading text-info">{driver_phone}</div>
															</div>
														</div>
													</div>
												</div>
											</ListGroupItem>
											<ListGroupItem>
												<div className="widget-content p-0">
													<div className="widget-content-outer">
														<div className="widget-content-wrapper">
															<div className="widget-content-left">
																<div className="widget-heading">{t("driverCode")}</div>
																<div className="widget-heading text-info">{driver_hrcode}</div>
															</div>
														</div>
													</div>
												</div>
											</ListGroupItem>
										</ListGroup>
									</Col>
									<Col lg="3" className="pr-5">
										<ListGroup flush>
											{startKM && (
												<ListGroupItem>
													<div className="widget-content p-0">
														<div className="widget-content-outer">
															<div className="widget-content-wrapper">
																<div className="widget-content-left">
																	<div className="widget-heading">{t("startKM")}</div>
																	<div className="widget-heading text-info">{Number(startKM).toLocaleString()}</div>
																</div>
															</div>
														</div>
													</div>
												</ListGroupItem>
											)}
											{endKM && (
												<ListGroupItem>
													<div className="widget-content p-0">
														<div className="widget-content-outer">
															<div className="widget-content-wrapper">
																<div className="widget-content-left">
																	<div className="widget-heading">{t("endKM")}</div>
																	<div className="widget-heading text-info">{Number(endKM).toLocaleString()}</div>
																</div>
															</div>
														</div>
													</div>
												</ListGroupItem>
											)}
											<ListGroupItem>
												<div className="widget-content p-0">
													<div className="widget-content-outer">
														<div className="widget-content-wrapper">
															<div className="widget-content-left">
																<div className="widget-heading">{t("asset")}</div>
																<div className="widget-heading text-info">{asset.code}</div>
															</div>
														</div>
													</div>
												</div>
											</ListGroupItem>
											{attachedAsset && (
												<ListGroupItem>
													<div className="widget-content p-0">
														<div className="widget-content-outer">
															<div className="widget-content-wrapper">
																<div className="widget-content-left">
																	<div className="widget-heading">{t("attachedAsset")}</div>
																	<div className="widget-heading text-info">{attachedAsset.code}</div>
																</div>
															</div>
														</div>
													</div>
												</ListGroupItem>
											)}
											{assisstant && (
												<ListGroupItem>
													<div className="widget-content p-0">
														<div className="widget-content-outer">
															<div className="widget-content-wrapper">
																<div className="widget-content-left">
																	<div className="widget-heading">{t("assistant")}</div>
																	<div className="widget-heading text-info">{assisstant}</div>
																</div>
															</div>
														</div>
													</div>
												</ListGroupItem>
											)}
										</ListGroup>
									</Col>
									<Col lg="3" className="pr-5">
										<ListGroup flush>
											<ListGroupItem>
												<div className="widget-content p-0">
													<div className="widget-content-outer">
														<div className="widget-content-wrapper">
															<div className="widget-content-left">
																<div className="widget-heading">{t("startDate")}</div>
																<div className="widget-heading text-info">{startDate}</div>
															</div>
														</div>
													</div>
												</div>
											</ListGroupItem>
											{endDate && (
												<ListGroupItem>
													<div className="widget-content p-0">
														<div className="widget-content-outer">
															<div className="widget-content-wrapper">
																<div className="widget-content-left">
																	<div className="widget-heading">{t("endDate")}</div>
																	<div className="widget-heading text-info">{endDate}</div>
																</div>
															</div>
														</div>
													</div>
												</ListGroupItem>
											)}
											{route && (
												<ListGroupItem>
													<div className="widget-content p-0">
														<div className="widget-content-outer">
															<div className="widget-content-wrapper">
																<div className="widget-content-left">
																	<div className="widget-heading">{t("route")}</div>
																	<div className="widget-heading text-info">{route.name}</div>
																</div>
															</div>
														</div>
													</div>
												</ListGroupItem>
											)}
											{serviceType && (
												<ListGroupItem>
													<div className="widget-content p-0">
														<div className="widget-content-outer">
															<div className="widget-content-wrapper">
																<div className="widget-content-left">
																	<div className="widget-heading">{t("serviceType")}</div>
																	<div className="widget-heading text-info">{serviceType}</div>
																</div>
															</div>
														</div>
													</div>
												</ListGroupItem>
											)}
											{note && (
												<ListGroupItem>
													<div className="widget-content p-0">
														<div className="widget-content-outer">
															<div className="widget-content-wrapper">
																<div className="widget-content-left">
																	<div className="widget-heading">{t("notes")}</div>
																	<div className="widget-heading text-info">{note}</div>
																</div>
															</div>
														</div>
													</div>
												</ListGroupItem>
											)}
											{this.props.wo.rfr.length !== 0 && (
												<ListGroupItem>
													<div className="widget-content p-0">
														<div className="widget-content-outer">
															<div className="widget-content-wrapper">
																<div className="widget-content-left">
																	<div className="widget-heading">{t("issuedRFRs")}</div>
																	<div className="widget-heading">
																		{this.props.wo.rfr.map((rfr) => (
																			<a
																				target="_blank"
																				rel="noopener noreferrer"
																				href={this.props.mntcURL + "#/viewsinglerfr/?id=" + rfr.id}
																				key={rfr.id}
																				className="badge badge-info m-1 ">
																				{rfr.code}
																			</a>
																		))}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</ListGroupItem>
											)}
										</ListGroup>
									</Col>
									<Col lg="3" className="pr-5">
										<ListGroup flush>
											{testDriveRequest && (
												<ListGroupItem>
													<div className="widget-content p-0">
														<div className="widget-content-outer">
															<div className="widget-content-wrapper">
																<div className="widget-content-left">
																	<div className="widget-heading">{t("testDriveRequest")}</div>
																	<div className="widget-heading text-info">{testDriveRequest__code}</div>
																</div>
															</div>
														</div>
													</div>
												</ListGroupItem>
											)}
											{this.props.wo.logs.map((log, index) => {
												return (
													<ListGroupItem
														key={index}
														hidden={
															log.event !== "Initial Settelment" &&
															log.event !== "Expense Review" &&
															log.event !== "Final Settlement"
														}>
														<div className="widget-content p-0">
															<div className="widget-content-outer">
																<div className="widget-content-wrapper">
																	<div className="widget-content-left">
																		<div className="widget-heading">{log.event}</div>
																		<div className="widget-heading text-info">
																			{t("by")}
																			{log.user}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</ListGroupItem>
												);
											})}
										</ListGroup>
									</Col>
								</Row>
							</Col>
						</Row>
					</CardBody>
					<CardFooter className="d-flex justify-content-end">
						<GetPermission perm="ops.view_expense">
							<div code="perm">
								<Button hidden={expensesForPrint.length > 0} onClick={getExpensesForPrint} className="ml-1" color="dark">
									{t("getExpenses")}
								</Button>
							</div>
							<ReactToPrint
								documentTitle={t("expensePrint")}
								trigger={() => {
									// Do NOT pass an `onClick` prop to the root node of the returned component as it will be overwritten.
									return (
										<div code="perm">
											<Button className="ml-1" color="dark" hidden={expensesForPrint.length === 0}>
												{t("printExpenses")}
											</Button>
										</div>
									);
								}}
								content={content}
							/>
						</GetPermission>
						<GetPermission perm="ops.view_project">
							<div code="perm">
								<Button color="dark" className="ml-1" onClick={viewProject} hidden={!project}>
									{t("viewProject")}
								</Button>
							</div>
						</GetPermission>
						<GetPermission perm="ops.view_plan">
							<div code="perm">
								<Button color="dark" className="ml-1" onClick={viewPlan} hidden={!plan}>
									{t("viewPlan")}
								</Button>
							</div>
						</GetPermission>
						<GetPermission perm="ops.add_trip">
							<div code="perm">
								<Button color="dark" onClick={addTrip} className="ml-1" hidden={Object.keys(logs).length !== 0}>
									{t("addTrip")}
								</Button>
							</div>
						</GetPermission>
						<GetPermission perm="ops.change_workorder">
							<div code="perm">
								<Button color="dark" onClick={onCancelEdit} hidden={!confirmEdit} className="ml-1">
									{t("cancel")}
								</Button>
								<Button color="info" onClick={onConfirmEdit} hidden={!confirmEdit} className="ml-1">
									{t("confirm")}
								</Button>
								<Button color="dark" onClick={edit} hidden={!endDate || confirmEdit || !editable} className="ml-1">
									{t("edit")}
								</Button>
								<Button color="info" onClick={close} hidden={endDate} className="ml-1">
									{t("close")}
								</Button>
								<Button
									color="danger"
									className="ml-1"
									onClick={initExpense}
									hidden={!endDate || (Object.keys(logs).length > 0 && logs["initialSettelment"])}>
									{t("initialExpense")}
								</Button>
							</div>
						</GetPermission>
						<GetPermission perm="ops.change_expense">
							<div code="perm">
								<Button
									color="danger"
									className="ml-1"
									onClick={getExpenses}
									hidden={!endDate || !logs["initialSettelment"] || logs["expenseReview"]}>
									{t("reviewExpense")}
								</Button>
								<Button
									color="danger"
									className="ml-1"
									onClick={getExpenses}
									hidden={!endDate || !(logs["initialSettelment"] && logs["expenseReview"]) || logs["finalSettlement"]}>
									{t("finalSettlement")}
								</Button>
							</div>
						</GetPermission>
						{!endDate && (
							<GetPermission perm="mntc.add_rfr">
								<Button className="ml-1" color="danger" code="perm" onClick={this.props.createRFR} disabled={!enableCreateRFR}>
									{t("createRFR")}
								</Button>
							</GetPermission>
						)}
					</CardFooter>
				</Card>
			</Fragment>
		);
	}
}

export default WorkOrderCard;
