import i18n from "i18n";
import axios from "axios";
import moment from "moment";
import Loader from "react-loaders";
import DriverForm from "./driver_form";
import DriverCard from "./driver_card";
import React, { Component } from "react";
import WOStatsCard from "./wo_stats_card";
import ViewIcon from "ops/common/view_icon";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AlertNotification } from "ops/common/alert_notification";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";

class ViewDriver extends Component {
	constructor() {
		super();
		this.state = {
			hold: null,
			driver: null,
			isEdit: false,
			loading: false,
			assetTypes: [],
			is_active: null,
			updatedDriver: {},
			updatedProfile: {},
			updatedLicense: {},
			is_unavailable: null,
			drivingWorkOrdersOpen: [],
			drivingWorkOrdersClose: [],
			licenseClassifications: [],
			assisstingWorkOrdersOpen: [],
			assisstingWorkOrdersClose: [],
			driverStatus: ["Dispatched", "Available"],
		};
	}

	getDriver = (id) => {
		this.setState({ loading: true });
		axios
			.get(`driver/${id}/?serializer=listdrivers`)
			.then((res) => {
				this.setState({
					loading: false,
					driver: res.data,
					is_active: res.data.is_active,
					updatedDriver: { id: res.data.id },
					updatedProfile: { id: res.data["profile"].id },
					updatedLicense: { id: res.data["profile"]["license"].id },
					hold: res.data["profile"]["license"].driverStatus === "Hold" ? true : false,
					is_unavailable: res.data["profile"]["license"].driverStatus === "Unavailable" ? true : false,
				});
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
				this.setState({ loading: false });
			});
	};

	getWorkorders = (id) => {
		this.setState({ loading: true });
		axios
			.get(`profile/driverwos/?driverID=${id}`)
			.then((res) => {
				this.setState((prevState) => {
					return {
						loading: false,
						drivingWorkOrdersOpen: [...prevState.drivingWorkOrdersOpen, ...res.data["drivingWorkOrdersOpen"]],
						drivingWorkOrdersClose: [...prevState.drivingWorkOrdersClose, ...res.data["drivingWorkOrdersClose"]],
						assisstingWorkOrdersOpen: [...prevState.assisstingWorkOrdersOpen, ...res.data["assisstingWorkOrdersOpen"]],
						assisstingWorkOrdersClose: [...prevState.assisstingWorkOrdersClose, ...res.data["assisstingWorkOrdersClose"]],
					};
				});
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");

				this.setState({ loading: false });
			});
	};

	getAssetTypes = () => {
		axios
			.get(`assetype/?serializer=identity`)
			.then((res) => {
				this.setState({ assetTypes: res.data });
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	getLicenseClassifications = () => {
		axios
			.get(`licenseclassification`)
			.then((res) => {
				this.setState({ licenseClassifications: res.data });
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	editView = () => {
		this.setState((prevState) => {
			return { isEdit: prevState.isEdit ? false : true };
		});
		this.getAssetTypes();
		this.getLicenseClassifications();
	};

	getUpdateValue = (e, v) => {
		let driver = { ...this.state.driver };
		let updatedDriver = { ...this.state.updatedDriver };
		if (v) {
			driver[e.target.id.split("-")[0]] = v;
			updatedDriver[e.target.id.split("-")[0]] = v.id;
		} else {
			if (e.target.name === "name") {
				driver["first_name"] = e.target.value;
				updatedDriver["first_name"] = e.target.value;
			} else {
				driver[e.target.name] = e.target.value;
				updatedDriver[e.target.name] = e.target.value;
			}
		}
		driver[`invalid${e.target.name.charAt(0).toUpperCase()}${e.target.name.slice(1, e.target.name.length)}`] = false;
		this.setState({ driver: driver, updatedDriver: updatedDriver });
	};

	getUpdateAssetTypeValue = (e, v) => {
		let driver = { ...this.state.driver };
		let updatedLicense = { ...this.state.updatedLicense };
		let values = v.map((value) => value.id);
		driver.profile.license["assetType"] = v;
		updatedLicense["assetType"] = values;
		driver[`invalidAssetType`] = false;
		this.setState({ driver: driver, updatedLicense: updatedLicense });
	};

	validate = (e) => {
		let driver = { ...this.state.driver };
		driver[`invalid${e.target.name.charAt(0).toUpperCase()}${e.target.name.slice(1, e.target.name.length)}`] = true;
		this.setState({ driver: driver });
	};

	getUpdateProfileValue = (e) => {
		let driver = { ...this.state.driver };
		let updatedProfile = { ...this.state.updatedProfile };
		driver.profile[e.target.name] = e.target.value;
		updatedProfile[e.target.name] = e.target.value;
		driver[`invalid${e.target.name.charAt(0).toUpperCase()}${e.target.name.slice(1, e.target.name.length)}`] = false;
		this.setState({ driver: driver, updatedProfile: updatedProfile });
	};

	getUpdateLicenseValue = (e, v) => {
		let driver = { ...this.state.driver };
		let updatedLicense = { ...this.state.updatedLicense };

		if (v) {
			driver.profile.license[e.target.id.split("-")[0]] = v;
			if (e.target.id.split("-")[0] === "driverStatus") {
				updatedLicense[e.target.id.split("-")[0]] = v;
			} else {
				updatedLicense[e.target.id.split("-")[0]] = v.id;
			}
			driver[
				`invalid${e.target.id
					.split("-")[0]
					.charAt(0)
					.toUpperCase()}${e.target.id.split("-")[0].slice(1, e.target.id.split("-")[0].length)}`
			] = false;
		} else {
			if (e.target.name === "expiryDate") {
				updatedLicense[e.target.name] = moment(e.target.value + moment().format("THH:MM")).format("YYYY-MM-DDTHH:MM");
			} else {
				updatedLicense[e.target.name] = e.target.value;
			}
			driver.profile.license[e.target.name] = e.target.value;
			driver[`invalid${e.target.name.charAt(0).toUpperCase()}${e.target.name.slice(1, e.target.name.length)}`] = false;
		}
		this.setState({ driver: driver, updatedLicense: updatedLicense });
	};

	terminatedORUnavailable = (e) => {
		const target = e.target.id;
		let hold = target === "Hold" && e.target.checked ? true : false;
		let is_active = target === "is_active" && e.target.checked ? false : true;
		let is_unavailable = target === "Unavailable" && e.target.checked ? true : false;
		this.setState({ is_active: is_active, is_unavailable: is_unavailable, hold: hold });
	};

	submit = (e) => {
		e.preventDefault();
		let data = {
			hold: this.state.hold,
			is_active: this.state.is_active,
			driver: { ...this.state.updatedDriver },
			profile: { ...this.state.updatedProfile },
			license: { ...this.state.updatedLicense },
			is_unavailable: this.state.is_unavailable,
		};
		this.setState({ loading: true });
		axios
			.put(`profile/updateDriver/?profileID=${this.state.driver.profile.id}`, data)
			.then((res) => {
				AlertNotification(i18n.t("updated"), "success");
				this.setState({ loading: false, isEdit: false });
				this.getDriver(this.state.driver.id);
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
				this.setState({ loading: false });
			});
	};

	componentDidMount() {
		let driverID = this.props.history.location.driverID;
		if (!driverID) driverID = new URLSearchParams(this.props.location.search).get("id");
		this.getDriver(driverID);
		this.getWorkorders(driverID);
	}

	render() {
		const t = i18n.t;
		const woDriverColumn = [
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "code",
				text: t("code"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "project",
				text: t("project"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "startDate",
				text: t("startDate"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "endDate",
				text: t("endDate"),
			},
			{
				sort: true,
				align: "center",
				dataField: "serviceType",
				headerAlign: "center",
				text: t("serviceType"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "assisstant",
				text: t("assistant"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "asset.code",
				text: t("asset"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "asset.assetType.name",
				text: t("assetType"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "attachedAsset.code",
				text: t("attachedAsset"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "attachedAsset.assetType.name",
				text: t("attachedAssetType"),
			},
			{
				dataField: "",
				align: "center",
				headerAlign: "center",
				text: t("actions"),
				formatter: (cell, wo) => {
					if (!wo.plan) {
						return (
							<GetPermission perm="ops.view_workorder">
								<ViewIcon
									code="perm"
									onview={() => this.props.history.push({ pathname: "/viewworkorder/", workOrderID: wo.id })}
								/>
							</GetPermission>
						);
					} else {
						return (
							<GetPermission perm="ops.view_workorder">
								<ViewIcon
									code="perm"
									onview={() =>
										this.props.history.push({
											woID: wo.id,
											plan: wo.plan,
											pathname: "/viewplannedwo/",
										})
									}
								/>
							</GetPermission>
						);
					}
				},
			},
		];
		const woAssisstantColumn = [
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "code",
				text: t("code"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "project",
				text: t("project"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "startDate",
				text: t("startDate"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "endDate",
				text: t("endDate"),
			},
			{
				sort: true,
				align: "center",
				dataField: "serviceType",
				headerAlign: "center",
				text: t("serviceType"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "driver",
				text: t("driver"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "asset.code",
				text: t("asset"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "asset.assetType.name",
				text: t("assetType"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "attachedAsset.code",
				text: t("attachedAsset"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "attachedAsset.assetType.name",
				text: t("attachedAssetType"),
			},
			{
				dataField: "",
				align: "center",
				headerAlign: "center",
				text: t("actions"),
				formatter: (cell, wo) => {
					if (wo.plan) {
						return (
							<ViewIcon
								onview={() =>
									this.props.history.push({
										woID: wo,
										plan: wo.plan,
										pathname: "/viewplannedwo/",
									})
								}
							/>
						);
					} else if (!wo.plan) {
						return <ViewIcon onview={() => this.props.history.push({ pathname: "/viewworkorder/", workOrderID: wo.id })} />;
					}
				},
			},
		];
		return (
			<>
				<PageTitleAlt2
					heading={this.props.assisstant ? t("viewDriverAssisstant") : t("viewDriver")}
					icon="bi bi-person-circle icon-gradient bg-happy-fisher"
				/>
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					{this.state.driver && (
						<>
							{this.state.isEdit ? (
								<Row>
									<Col>
										<DriverForm
											submit={this.submit}
											hold={this.state.hold}
											editView={this.editView}
											validate={this.validate}
											driver={this.state.driver}
											is_active={this.state.is_active}
											assetTypes={this.state.assetTypes}
											getUpdateValue={this.getUpdateValue}
											is_unavailable={this.state.is_unavailable}
											getUpdateProfileValue={this.getUpdateProfileValue}
											getUpdateLicenseValue={this.getUpdateLicenseValue}
											getUpdateAssetTypeValue={this.getUpdateAssetTypeValue}
											terminatedORUnavailable={this.terminatedORUnavailable}
											licenseClassifications={this.state.licenseClassifications}
										/>
									</Col>
								</Row>
							) : (
								<>
									<Row>
										<Col>
											<DriverCard editView={this.editView} driver={this.state.driver} />
										</Col>
									</Row>
									<Row className="mt-3" color="info">
										{this.state.driver.profile.license.assetType.map((assetType, index) => (
											<Col key={index}>
												<Card color="info">
													<CardBody className="badge">{assetType.name}</CardBody>
												</Card>
											</Col>
										))}
									</Row>
								</>
							)}
							<Row className="my-3 justify-content-center" color="info">
								{this.props.assisstant ? (
									<Col>
										<WOStatsCard
											title="noOfAssisstingWorkorder"
											openWO={this.state.assisstingWorkOrdersOpen.length}
											closedWO={this.state.assisstingWorkOrdersClose.length}
											totalWO={this.state.assisstingWorkOrdersOpen.length + this.state.assisstingWorkOrdersClose.length}
										/>
									</Col>
								) : (
									<Col className="my-2">
										<WOStatsCard
											title="noOfDrivingWorkorder"
											openWO={this.state.drivingWorkOrdersOpen.length}
											closedWO={this.state.drivingWorkOrdersClose.length}
											totalWO={this.state.drivingWorkOrdersOpen.length + this.state.drivingWorkOrdersClose.length}
										/>
									</Col>
								)}
							</Row>
							{this.state.drivingWorkOrdersOpen.length !== 0 && (
								<Row className="mt-4">
									<Col>
										<Card>
											<CardBody>
												<div className="dropdown-menu-header m-0 mb-2">
													<div className="dropdown-menu-header-inner m-0 rounded bg-primary">
														<div className="menu-header-content">
															<h5 className="menu-header-title">{t("drivingWorkOrdersOpen")}</h5>
														</div>
													</div>
												</div>
												<Row>
													<Col>
														<TableWithPaginationSearch
															scrollable
															exportable
															filename="driveropenworkorder.csv"
															search
															columns={woDriverColumn}
															data={this.state.drivingWorkOrdersOpen}
															keyfield="id"
														/>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
							)}
							{this.state.assisstingWorkOrdersOpen.length !== 0 && (
								<Row className="mt-4">
									<Col>
										<Card>
											<CardBody>
												<div className="dropdown-menu-header m-0 mb-2">
													<div className="dropdown-menu-header-inner m-0 rounded bg-primary">
														<div className="menu-header-content">
															<h5 className="menu-header-title">{t("assisstingWorkOrdersOpen")}</h5>
														</div>
													</div>
												</div>
												<Row>
													<Col>
														<TableWithPaginationSearch
															scrollable
															exportable
															filename="assisstantopenworkorder.csv"
															search
															columns={woAssisstantColumn}
															data={this.state.assisstingWorkOrdersOpen}
															keyfield="id"
														/>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
							)}

							{this.state.drivingWorkOrdersClose.length !== 0 && (
								<Row className="mt-4">
									<Col>
										<Card>
											<CardBody>
												<div className="dropdown-menu-header m-0 mb-2">
													<div className="dropdown-menu-header-inner m-0 rounded bg-dark">
														<div className="menu-header-content">
															<h5 className="menu-header-title">{t("drivingWorkOrdersClose")}</h5>
														</div>
													</div>
												</div>
												<Row>
													<Col>
														<TableWithPaginationSearch
															scrollable
															search
															exportable
															filename="driverclosedworkorder.csv"
															columns={woDriverColumn}
															data={this.state.drivingWorkOrdersClose}
															keyfield="id"
														/>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
							)}
							{this.state.assisstingWorkOrdersClose.length !== 0 && (
								<Row className="mt-4">
									<Col>
										<Card>
											<CardBody>
												<div className="dropdown-menu-header m-0 mb-2">
													<div className="dropdown-menu-header-inner m-0 rounded bg-dark">
														<div className="menu-header-content">
															<h5 className="menu-header-title">{t("assisstingWorkOrdersClose")}</h5>
														</div>
													</div>
												</div>
												<Row>
													<Col>
														<TableWithPaginationSearch
															scrollable
															search
															exportable
															filename="assisstanetclosedworkorders.csv"
															columns={woAssisstantColumn}
															data={this.state.assisstingWorkOrdersClose}
															keyfield="id"
														/>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
							)}
						</>
					)}
				</LoadingOverlay>
			</>
		);
	}
}

export default withRouter(ViewDriver);
