import React from "react";

export const CancelIcon = (props) => {
	return (
		<div className={`icon-wrapper text-dark ${props.parentClassName || ""}`} hidden={props.hide}>
			<i
				className={`bi bi-x-lg ${props.onHoverColor || "info"}-hover ${props.iconClassName || ""}`}
				style={{ fontSize: props.iconSize || "1rem", cursor: "pointer" }}
				onClick={props.onCancel}></i>
		</div>
	);
};
