import i18n from "i18n";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "react-loaders";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import { AlertNotification } from "ops/common/alert_notification";
import { Button, Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap";

class NewCommodityType extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			commodityType: { name: "" },
		};
	}

	getValue = (e) => {
		let value;
		let target;
		let commodityType = { ...this.state.commodityType };
		target = e.target.name;
		value = e.target.value;
		commodityType[target] = value;
		commodityType[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
		this.setState({ commodityType: commodityType });
	};

	validate = (e) => {
		let target = e.target.name;
		if (!target) target = e.target.id.split("-")[0];
		let commodityType = { ...this.state.commodityType };
		commodityType[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		this.setState({ commodityType: commodityType });
	};

	onSubmit = (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		axios
			.post(`commoditytype/`, this.state.commodityType)
			.then((res) => {
				if (res) {
					this.setState({ loading: false });
					AlertNotification(i18n.t("created"), "success");
					this.showAlert(res.data.name);
				}
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
				this.setState({ loading: false });
			});
	};

	showAlert = (commodityType) => {
		Swal.fire({
			icon: "success",
			buttonsStyling: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			showCancelButton: false,
			cancelButtonText: i18n.t("no"),
			title: `${i18n.t("commodityType")}:\n ${commodityType}`,
			confirmButtonText: i18n.t("ok"),
			customClass: {
				confirmButton: "btn btn-dark",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				this.setState({ commodityType: { name: "" } });
			}
		});
	};

	render() {
		const t = i18n.t;
		return (
			<>
				<PageTitleAlt2 heading={t("newCommodityType")} icon="bi bi-inboxes-fill icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row>
						<Col xs="12">
							<Card>
								<CardBody className="p-1 m-2">
									<Form onInvalid={this.validate} onSubmit={this.onSubmit}>
										<Row className="align-items-end justify-content-between">
											<Col xs="12" md="10">
												<Label>{t("name")}</Label>
												<Input
													required
													name="name"
													type="text"
													onChange={this.getValue}
													invalid={this.state.commodityType["invalidName"]}
													value={this.state.commodityType["name"]}
												/>
											</Col>
											<Col xs="12" md="2">
												<GetPermission perm="ops.add_commoditytype">
													<Button className="float-right mx-2" code="perm" color="info" type="submit">
														{t("submit")}
													</Button>
												</GetPermission>
											</Col>
										</Row>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}
export default NewCommodityType;
