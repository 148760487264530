import i18n from "i18n";
import React, { Component } from "react";
import Selector from "ops/common/selector";
import GetPermission from "ops/common/get_permission";
import { Button, Card, CardBody, CardFooter, Col, Form, Input, Label, Row } from "reactstrap";

class CommodityForm extends Component {
	render() {
		const t = i18n.t;
		const { getValue, validate, onSubmit, commodity, commodityTypes } = this.props;
		return (
			<>
				<Card>
					<CardBody>
						<Row>
							<Col xs="12">
								<Form id="commodityForm" onInvalid={validate} onSubmit={onSubmit}>
									<Row>
										<Col xs="12" md="6" lg="3">
											<Label>{t("name")}</Label>
											<Input
												required
												name="name"
												type="text"
												onChange={getValue}
												invalid={commodity["invalidName"]}
												value={commodity["name"]}
											/>
										</Col>

										<Col xs="12" md="6" lg={this.props.update ? 3 : 4}>
											<Label>{t("description")}</Label>
											<Input
												required
												name="description"
												type="text"
												onChange={getValue}
												invalid={commodity["invalidDescription"]}
												value={commodity["description"]}
											/>
										</Col>
										<Col xs="12" md="6" lg={this.props.update ? 3 : 4}>
											<Label>{t("commodityType")}</Label>
											<Selector
												required
												id="commodityType"
												size="small"
												width="100%"
												isObjectOption
												optionAccessor="name"
												options={commodityTypes}
												onChange={getValue}
												error={commodity["invalidCommodityType"]}
												value={commodity["commodityTypeObject"]}
											/>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</CardBody>
					<CardFooter className="d-flex justify-content-end">
						<GetPermission perm="ops.add_commodity">
							<Button code="perm" color="info" form="commodityForm" type="submit">
								{t("submit")}
							</Button>
						</GetPermission>
					</CardFooter>
				</Card>
			</>
		);
	}
}
export default CommodityForm;
