import React, {Component} from "react";
import {Label, FormGroup, Input} from "reactstrap";

class InputTypeSelect extends Component {
	render() {
		return (
			<FormGroup row={this.props.row} className={this.props.classes}>
				<Label className={this.props.lableclass}>{this.props.title}</Label>
				<Input
					type="select"
					id={this.props.id}
					index={this.props.index}
					value={this.props.selected}
					disabled={this.props.disable}
					required={this.props.required}
					onChange={this.props.onchange}
					className={this.props.inputclass}>
					{this.props.data.map((d, index) => {
						return (
							<option id={index} key={d.id} value={index}>
								{d[this.props.accessname]}
							</option>
						);
					})}
				</Input>
			</FormGroup>
		);
	}
}

export default InputTypeSelect;
