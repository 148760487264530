import React, { Component } from "react";
import ViewWO from "../components/view_wo";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class ViewPlannedWOScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<ViewWO />
			</CSSTransitionGroup>
		);
	}
}

export default ViewPlannedWOScene;
