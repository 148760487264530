import i18n from "i18n";
import moment from "moment";
import Selector from "ops/common/selector";
import DeleteIcon from "ops/common/delete_icon";
import React, { Component, Fragment } from "react";
import { Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row } from "reactstrap";

class TripForm extends Component {
	render() {
		const t = i18n.t;
		return (
			<Fragment>
				<Card className="main-card mb-3">
					<CardBody>
						<CardTitle className="d-flex justify-content-between">
							<div>{this.props.index + 1}</div>
							<div>
								<DeleteIcon index={this.props.index} ondelete={this.props.onDelete} />
							</div>
						</CardTitle>
						<Row>
							{this.props.projectLegs && this.props.projectLegs.length !== 0 ? (
								<Col xs="12">
									<FormGroup>
										<Label>{t("leg")}</Label>
										<Selector
											required
											size="small"
											id="projectLeg"
											isObjectOption
											optionAccessor="legName"
											options={this.props.projectLegs}
											name={this.props.index.toString()}
											error={this.props.trip["invalidProjectLeg"]}
											onChange={(e, v) => {
												if (this.props.getSelectedValue) {
													this.props.getSelectedValue(e, v, this.props.index);
												} else {
													this.props.getValue(e, v, this.props.index);
												}
											}}
											value={this.props.trip["projectLegObject"] || { legName: "" }}
										/>
									</FormGroup>
								</Col>
							) : (
								<Col xs="12">
									<FormGroup>
										<Label>{t("leg")}</Label>
										<Selector
											required
											size="small"
											id="leg"
											isObjectOption
											optionAccessor="name"
											options={this.props.legs}
											name={this.props.index.toString()}
											error={this.props.trip["invalidLeg"]}
											onChange={(e, v) => {
												this.props.getValue(e, v, this.props.index);
											}}
											value={this.props.trip["legObject"] || { name: "" }}
										/>
									</FormGroup>
								</Col>
							)}

							<Col xs="12">
								<FormGroup>
									<Label>{t("owner")}</Label>
									<Selector
										required
										size="small"
										id="owner"
										isObjectOption
										optionAccessor="fullName"
										options={this.props.companies}
										name={this.props.index.toString()}
										error={this.props.trip["invalidOwner"]}
										onChange={(e, v) => {
											this.props.getSelectedValue(e, v, this.props.index);
										}}
										value={this.props.trip["ownerObject"] || { fullName: "" }}
									/>
								</FormGroup>
							</Col>
							<Col xs="12" className="mt-2">
								<FormGroup>
									<Label>{t("loadingDate")}</Label>
									<Input
										bsSize="sm"
										type="date"
										id="loadingDate"
										index={this.props.index}
										onChange={this.props.getValue}
										name={this.props.index.toString()}
										invalid={this.props.trip["invalidLoadingDate"]}
										min={moment(this.props.woStartDate.split(" ")[0], "DD-MM-YYYY").format("YYYY-MM-DD")}
										value={moment(this.props.trip.loadingDate).format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")}
										max={
											this.props.woEndDate ? moment(this.props.woEndDate.split(" ")[0], "DD-MM-YYYY").format("YYYY-MM-DD") : null
										}
									/>
								</FormGroup>
							</Col>
							<Col xs="12">
								<FormGroup>
									<Label>{t("unLoadingDate")}</Label>
									<Input
										bsSize="sm"
										type="date"
										id="unLoadingDate"
										index={this.props.index}
										onChange={this.props.getValue}
										name={this.props.index.toString()}
										min={this.props.trip.loadingDate.split("T")[0]}
										invalid={this.props.trip["invalidUnLoadingDate"]}
										value={moment(this.props.trip.unLoadingDate).format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")}
										max={
											this.props.woEndDate ? moment(this.props.woEndDate.split(" ")[0], "DD-MM-YYYY").format("YYYY-MM-DD") : null
										}
									/>
								</FormGroup>
							</Col>
							<Col xs="6">
								<FormGroup>
									<Label>{t("loadedQTY")}</Label>
									<Input
										bsSize="sm"
										type="number"
										step={0.001}
										id="loadedQTY"
										index={this.props.index}
										onChange={this.props.getValue}
										value={this.props.trip.loadedQTY || ""}
									/>
								</FormGroup>
							</Col>
							<Col xs="6">
								<FormGroup>
									<Label>{t("deliveredQTY")}</Label>
									<Input
										bsSize="sm"
										type="number"
										step={0.001}
										id="deliveredQTY"
										index={this.props.index}
										onChange={this.props.getValue}
										value={this.props.trip.deliveredQTY || ""}
									/>
								</FormGroup>
							</Col>
							<Col xs="6">
								<FormGroup>
									<Label>{t("startKM")}</Label>
									<Input
										bsSize="sm"
										id="startKM"
										type="number"
										step={0.01}
										index={this.props.index}
										onChange={this.props.getValue}
										value={this.props.trip.startKM || ""}
									/>
								</FormGroup>
							</Col>
							<Col xs="6">
								<FormGroup>
									<Label>{t("endKM")}</Label>
									<Input
										id="endKM"
										bsSize="sm"
										type="number"
										step={0.01}
										index={this.props.index}
										onChange={this.props.getValue}
										value={this.props.trip.endKM || ""}
									/>
								</FormGroup>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Fragment>
		);
	}
}

export default TripForm;
