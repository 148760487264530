import { Row, Col, Card } from "reactstrap";
import React, { Component, Fragment } from "react";

class ExpenseCard extends Component {
	render() {
		return (
			<Fragment>
				<Row>
					{this.props.data.map((expense, index) => {
						return (
							<Col xs="12" md="3" key={index}>
								<Card className="card-shadow-primary mb-3 widget-chart widget-chart2 text-left">
									<div className="widget-content p-0 ">
										<div className="widget-content-outer">
											<div className="widget-content-wrapper">
												<div className="widget-content-left pr-2 fsize-1">
													<div className={`widget-numbers mt-0 fsize-1 text-dark`}>{expense.expenseType.name}</div>
												</div>
											</div>
											<div className="widget-content-left fsize-1">
												<div className={`widget-numbers mt-0 fsize-3 text-danger`}>{`${expense.refundAmount} ${
													expense.refundAmount > 0 ? (expense.isPercentage ? "%" : "EGP") : ""
												}`}</div>
											</div>
										</div>
									</div>
								</Card>
							</Col>
						);
					})}
				</Row>
			</Fragment>
		);
	}
}

export default ExpenseCard;
