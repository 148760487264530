import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function Selector(props) {
	return (
		<Autocomplete
			fullWidth
			id={props.id}
			size={props.size}
			value={props.value}
			disabled={props.disabled}
			onChange={props.onChange}
			limitTags={props.tagsLimit}
			options={props.options || []}
			defaultValue={props.defaultValue}
			multiple={props.multiple || false}
			disableClearable={props.clearable ? false : true}
			filterSelectedOptions={props.filterSelectedOptions || false}
			style={{ width: props.width, height: props.height, minWidth: props.minWidth }}
			getOptionDisabled={(option) => (props.isObjectOption ? option.id === 0 : option === "")}
			getOptionLabel={(option) => (props.isObjectOption ? option[props.optionAccessor] : option)}
			getOptionSelected={(option, value) => (props.isObjectOption ? option.id === value.id : option === value)}
			renderInput={(params) => (
				<TextField label="" {...params} name={props.name} variant="standard" error={props.error} required={props.required} />
			)}
		/>
	);
}
export default Selector;
