import i18n from "i18n";
import moment from "moment";
import React, { Component } from "react";
import InfoIcon from "ops/common/info_icon";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";

class ReviewExpenseForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hideExtraInfo: true,
		};
	}

	expenses = [];
	scroll = null;

	handleExtraInfo = () => {
		this.setState({ hideExtraInfo: !this.state.hideExtraInfo });
	};

	getScrolElement = (scrol) => {
		this.scroll = scrol;
	};

	moveScrol = (scrolPosition) => {
		this.scroll.scrollLeft = scrolPosition;
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.extraInfoActiveIndex !== this.props.extraInfoActiveIndex)
			if (this.props.extraInfoActiveIndex === this.props.tripIndex) this.setState({ hideExtraInfo: false });
			else this.setState({ hideExtraInfo: true });
		if (prevProps.scrolPosition !== this.props.scrolPosition) this.moveScrol(this.props.scrolPosition);
	};

	componentDidMount() {
		this.props.getExpensesTotal(this.props.tripIndex);
	}
	render() {
		const t = i18n.t;
		const { expense, getValue, tripIndex, trips } = this.props;
		return (
			<Row>
				<Col xs="12" lg="12" className="d-flex justify-content-center">
					<sub color="info" className="mr-3 mb-4 mt-2" hidden={this.state.hideExtraInfo}>{`Loaded Quantity: ${Number(
						trips[tripIndex].loadedQTY
					).toLocaleString()}`}</sub>
					<sub color="info" className="mr-3 mb-4 mt-2" hidden={this.state.hideExtraInfo}>{`Delivered Quantity: ${Number(
						trips[tripIndex].deliveredQTY
					).toLocaleString()}`}</sub>
					<sub color="info" className="mr-3 mb-4 mt-2" hidden={this.state.hideExtraInfo}>{`Start KM: ${Number(
						trips[tripIndex].startKM
					).toLocaleString()}`}</sub>
					<sub color="info" className="mr-3 mb-4 mt-2" hidden={this.state.hideExtraInfo}>{`End KM: ${Number(
						trips[tripIndex].endKM
					).toLocaleString()}`}</sub>
					<sub color="info" className="mr-3 mb-4 mt-2" hidden={this.state.hideExtraInfo}>{`Leg: ${trips[tripIndex].leg ||
						trips[tripIndex].projectLeg}`}</sub>
					<sub
						color="info"
						className="mr-3 mb-4 mt-2"
						hidden={this.state.hideExtraInfo}>{`Owner: ${trips[tripIndex].owner.fullName}`}</sub>
				</Col>
				<Col xs="1" lg="1" className="d-flex justify-content-center align-items-center">
					<div>
						<InfoIcon iconSize="1.5rem" cssclass="mt-1" onclick={this.handleExtraInfo} />
					</div>
					<div className="badge badge-pill badge-info ml-1">{tripIndex + 1}</div>
				</Col>
				<Col xs="11" lg="11">
					<PerfectScrollbar
						key={tripIndex}
						className="d-flex"
						onScrollLeft={this.props.setScrol}
						containerRef={this.getScrolElement}
						onScrollRight={this.props.setScrol}>
						<Col xs="2" lg="2" className="mb-3">
							<FormGroup>
								<Label style={{ minWidth: "fit-content" }}>{t("loadingReceiptNo")}</Label>
								<Input
									bsSize="sm"
									name="loadingReceiptNo"
									type="text"
									style={{ width: "50px", minWidth: "fit-content", maxWidth: "120px" }}
									tripid={trips[tripIndex].id}
									tripindex={tripIndex}
									value={trips[tripIndex].loadingReceiptNo}
									disabled
								/>
							</FormGroup>
						</Col>
						<Col xs="2" lg="2" className="mb-3">
							<FormGroup>
								<Label style={{ minWidth: "fit-content" }}>{t("couponNo")}</Label>
								<Input
									bsSize="sm"
									type="text"
									name="couponNo"
									style={{ width: "50px", minWidth: "fit-content", maxWidth: "120px" }}
									tripid={trips[tripIndex].id}
									tripindex={tripIndex}
									value={trips[tripIndex].couponNo}
									disabled
								/>
							</FormGroup>
						</Col>
						<Col xs="2" lg="2" className="mb-3">
							<FormGroup>
								<Label style={{ minWidth: "fit-content" }}>{t("loadingDate")}</Label>
								<Input
									disabled
									bsSize="sm"
									type="text"
									name="loadingDate"
									style={{ width: "50px", minWidth: "fit-content", maxWidth: "120px" }}
									value={moment(expense.loadingDate, "YYYY-MM-DDTHH:mm:ssZ").format("DD-MM-YYYY")}
								/>
							</FormGroup>
						</Col>
						{expense.expenses.map((expense, index) => {
							return (
								<Col xs="2" lg="2" className="mb-3" key={index}>
									<FormGroup>
										<Label style={{ minWidth: "fit-content" }}>{expense.expenseType}</Label>
										<Input
											bsSize="sm"
											type="number"
											step={0.01}
											index={index}
											defaultValue={expense.amount}
											onFocus={() => {
												this.props.onFocus(tripIndex);
											}}
											onChange={getValue}
											style={{ width: "80px", minWidth: "fit-content", maxWidth: "120px" }}
											expensetype={expense.id}
											tripindex={tripIndex}
											disabled={!expense.isMutable}
										/>
									</FormGroup>
								</Col>
							);
						})}
						<Col xs="2" lg="2" className="mb-3">
							<Label style={{ minWidth: "fit-content" }}>{t("total")}</Label>
							<Input
								bsSize="sm"
								style={{ width: "50px", minWidth: "fit-content", maxWidth: "120px" }}
								type="number"
								step={0.001}
								value={expense.total}
								disabled
							/>
						</Col>
					</PerfectScrollbar>
				</Col>
			</Row>
		);
	}
}

export default ReviewExpenseForm;
