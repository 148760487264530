import React, { Component } from "react";
import DataStudio from "../components/data_studio";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class Report extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<DataStudio />
			</CSSTransitionGroup>
		);
	}
}

export default Report;
