import React, { Component } from "react";
import ListSiteOwner from "../components/list_site_owner";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class ListSiteOwnerScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation"
			>
				<ListSiteOwner />
			</CSSTransitionGroup>
		);
	}
}

export default ListSiteOwnerScene;
