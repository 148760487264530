import React from "react";

const InfoIcon = (props) => {
	return (
		<i
			hidden={props.hidden}
			onClick={props.onclick}
			className={`pe-7s-info success-hover ${props.cssclass}`}
			style={{ fontSize: props.iconSize || "1rem", cursor: "pointer" }}></i>
	);
};
export default InfoIcon;
