import i18n from "i18n";
import axios from "axios";
import Loader from "react-loaders";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import ToggleSwitch from "ops/common/toggleSwitch";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import { AlertNotification } from "ops/common/alert_notification";
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";

class ListStandard extends Component {
	constructor() {
		super();
		this.state = {
			standards: [],
			loading: false,
		};
	}

	getStandards = () => {
		this.setState({ loading: true });
		axios
			.get("standard/?serializer=list&isArchive=false")
			.then((res) => {
				this.setState({ loading: false, standards: res.data });
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
			});
	};

	archiveStandard = (e, standard) => {
		this.setState({ loading: true });
		axios
			.put(`standard/archive/`, { pk: standard.id })
			.then((res) => {
				this.setState({ loading: false });
				AlertNotification(i18n.t("archived"), "success");
				this.getStandards();
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
			});
	};

	componentDidMount() {
		this.getStandards();
	}

	render() {
		const t = i18n.t;

		const standardsColumns = [
			{
				sort: true,
				align: "center",
				dataField: "name",
				headerAlign: "center",
				text: t("name"),
			},
			{
				sort: true,
				align: "center",
				dataField: "leg",
				headerAlign: "center",
				text: t("leg"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: t("archive"),
				dataField: "archive",
				formatter: (cell, standard, index) => {
					return (
						<div className="d-flex justify-content-center">
							<GetPermission perm="ops.change_standard">
								<ToggleSwitch
									code="perm"
									istoggleon={!standard.isArchive}
									handleclick={(e) => this.archiveStandard(e, standard)}
								/>
							</GetPermission>
						</div>
					);
				},
			},
		];

		const expandRow = {
			onlyOneExpanding: true,
			renderer: (standard) => {
				return (
					<Card>
						<CardBody>
							<Row>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("tripQuantity")}</Label>
										<Input type="number" step={0.01} id="tripQuantity" value={standard["tripQuantity"]} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("uom")}</Label>
										<Input type="text" id="uom" value={standard["uom"] || { id: 0, fullName: "" }} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("commodity")}</Label>
										<Input type="text" id="commodity" value={standard["commodity"] || { id: 0, name: "" }} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("averageSpeed")}</Label>
										<Input type="number" step={0.01} id="averageSpeed" value={standard["averageSpeed"] || 0} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("fuelRate")}</Label>
										<Input type="number" step={0.01} id="fuelRate" value={standard["fuelRate"] || 0} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("tolls")}</Label>
										<Input id="tolls" type="number" step={0.01} value={standard["tolls"] || 0} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("scales")}</Label>
										<Input id="scales" type="number" step={0.01} value={standard["scales"] || 0} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("tips")}</Label>
										<Input id="tips" type="number" step={0.01} value={standard["tips"] || 0} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("driverBonus")}</Label>
										<Input id="driverBonus" type="number" step={0.01} value={standard["driverBonus"] || 0} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("driverAllowance")}</Label>
										<Input type="number" step={0.01} id="driverAllowance" value={standard["driverAllowance"] || 0} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("driverAsstAllowance")}</Label>
										<Input
											type="number"
											step={0.01}
											id="driverAsstAllowance"
											value={standard["driverAsstAllowance"] || 0}
											disabled
										/>
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("loadingTime") + " (minutes)"}</Label>
										<Input type="number" step={0.01} id="loadingTime" value={standard["loadingTime"] || 0} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("dischargingTime") + " (minutes)"}</Label>
										<Input type="number" step={0.01} id="dischargingTime" value={standard["dischargingTime"] || 0} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("stoppage") + " (minutes)"}</Label>
										<Input type="number" step={0.01} id="stoppage" value={standard["stoppage"] || 0} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("drivingRestingTime") + " (minutes)"}</Label>
										<Input
											type="number"
											step={0.01}
											id="drivingRestingTime"
											value={standard["drivingRestingTime"] || 0}
											disabled
										/>
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("tripTime") + " (minutes)"}</Label>
										<Input type="number" step={0.01} id="tripTime" value={standard["tripTime"] || 0} disabled />
									</FormGroup>
								</Col>
								<Col xs="12" md="3">
									<FormGroup>
										<Label>{t("tripsPerDay") + " (minutes)"}</Label>
										<Input type="number" step={0.01} id="tripsPerDay" value={standard["tripsPerDay"] || 0} disabled />
									</FormGroup>
								</Col>
							</Row>
						</CardBody>
					</Card>
				);
			},
		};

		return (
			<>
				<PageTitleAlt2 heading={t("standards")} icon="bi bi-signpost-2-fill icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row className="m-3 justify-content-center">
						<Col xs="12">
							<Card>
								<CardBody>
									<Row>
										<Col>
											<TableWithPaginationSearch
												scrollable
												exportable
												filename="standards.csv"
												expandRow={expandRow}
												search
												data={this.state.standards}
												columns={standardsColumns}
												keyfield="id"
											/>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}

export default ListStandard;
