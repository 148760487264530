import i18n from "i18n";
import axios from "axios";
import Loader from "react-loaders";
import React, { Component } from "react";
import Selector from "ops/common/selector";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import { AlertNotification } from "ops/common/alert_notification";
import { Card, CardBody, Col, Row, Label, Button } from "reactstrap";

class ReplaceStandard extends Component {
	constructor() {
		super();
		this.state = {
			standard: {},
			standards: [],
			loading: false,
		};
	}

	getValue = (e, v) => {
		let value = v.id;
		let target = e.target.id.split("-")[0];
		let replacedStandard = { ...this.state.standard };
		replacedStandard[target] = value;
		this.setState({ standard: replacedStandard });
	};

	validate = () => {
		if (this.state.standard.pk !== undefined && this.state.standard.replacingStandard !== undefined) {
			return true;
		}
		return false;
	};

	onSubmit = () => {
		if (this.validate()) {
			this.setState({ loading: true });
			axios
				.put(`standard/replace/`, this.state.standard)
				.then((res) => {
					if (res) {
						AlertNotification(i18n.t("replaced"), "success");
					}
				})
				.catch((err) => {
					if (err) {
						console.error(err.response);
						AlertNotification(i18n.t("errorMessage"), "error");
					}
				})
				.finally(() => {
					this.setState({ loading: false });
				});
		} else AlertNotification(i18n.t("selectStandard"), "error");
	};

	getData = () => {
		axios.get(`standard/?serializer=names`).then((res) => {
			this.setState({ standards: res.data });
		});
	};

	componentDidMount() {
		this.getData();
	}

	render() {
		const t = i18n.t;
		return (
			<>
				<PageTitleAlt2 heading={t("replaceStandard")} icon="bi bi-ui-radios icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Card>
						<CardBody>
							<Row>
								<Col xs="6">
									<Label>{t("replacedStandard")}</Label>
									<Selector
										id="pk"
										size="small"
										isObjectOption
										optionAccessor="name"
										onChange={this.getValue}
										options={this.state.standards}
									/>
								</Col>
								<Col xs="6">
									<Label>{t("replacingStandard")}</Label>
									<Selector
										required
										size="small"
										isObjectOption
										optionAccessor="name"
										id="replacingStandard"
										onChange={this.getValue}
										options={this.state.standards}
									/>
								</Col>
							</Row>
							<Row>
								<Col className="mt-2 d-flex justify-content-end" xs="12">
									<Button color="info" onClick={this.onSubmit}>
										{t("replace")}
									</Button>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</LoadingOverlay>
			</>
		);
	}
}

export default ReplaceStandard;
