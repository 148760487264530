import i18n from "i18n";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "react-loaders";
import { Col, Row } from "reactstrap";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import DeletePopup from "ops/common/delete_popup";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import TestDriveRequestCard from "./test_drive_request_card";
import { AlertNotification } from "ops/common/alert_notification";

class ViewTestDriveRequest extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			testDriveRequest: {},
		};
	}

	changeField = (field) => {
		let testDriveRequest = { ...this.state.testDriveRequest };
		if (field === "asset1" && testDriveRequest.changeAsset2) {
			AlertNotification(i18n.t("pleaseFinishUpdateOtherAssetFirst"), "warning");
			return;
		} else if (field === "asset2" && this.state.testDriveRequest.changeAsset1) {
			AlertNotification(i18n.t("pleaseFinishUpdateOtherAssetFirst"), "warning");
			return;
		} else {
			testDriveRequest[`change${field.charAt(0).toUpperCase()}${field.slice(1, field.length)}`] = true;
			testDriveRequest[`old${field.charAt(0).toUpperCase()}${field.slice(1, field.length)}`] = testDriveRequest[field];
			testDriveRequest[`invalid${field.charAt(0).toUpperCase()}${field.slice(1, field.length)}`] = false;
		}
		this.setState({ testDriveRequest: testDriveRequest });
	};

	cancelChange = (field) => {
		let testDriveRequest = { ...this.state.testDriveRequest };
		testDriveRequest[`change${field.charAt(0).toUpperCase()}${field.slice(1, field.length)}`] = false;
		testDriveRequest[field] = testDriveRequest[`old${field.charAt(0).toUpperCase()}${field.slice(1, field.length)}`];
		this.setState({ testDriveRequest: testDriveRequest });
	};

	getFieldValue = (e) => {
		let target = e.target.id;
		let value = e.target.value;
		let checked = e.target.checked;
		let testDriveRequest = { ...this.state.testDriveRequest };
		if (target === "asset1" || target === "asset2") {
			value = e.target.getAttribute("assetid");
			if (checked) {
				testDriveRequest[target] = value;
			} else {
				testDriveRequest[target] = null;
			}
			testDriveRequest["invalidAsset1"] = false;
			testDriveRequest["invalidAsset2"] = false;
		} else {
			testDriveRequest[target] = value;
			testDriveRequest[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
		}
		this.setState({ testDriveRequest: testDriveRequest });
	};

	updateField = (field) => {
		let testDriveRequest = { ...this.state.testDriveRequest };
		if (field === "asset1" || field === "asset2") {
			if (!testDriveRequest.asset1 && !testDriveRequest.asset2) {
				testDriveRequest["invalidAsset1"] = true;
				testDriveRequest["invalidAsset2"] = true;
				this.setState({ testDriveRequest: testDriveRequest });
				AlertNotification(i18n.t("testDriveRequestMustHaveAtLeastOneAsset"), "error");
				return;
			}
		} else {
			if (!testDriveRequest[field]) {
				testDriveRequest[`invalid${field.charAt(0).toUpperCase()}${field.slice(1, field.length)}`] = true;
				this.setState({ testDriveRequest: testDriveRequest });
				AlertNotification(i18n.t("noValue"), "error");
				return;
			}
		}
		this.setState({ loading: true });
		axios
			.patch(`testdriverequest/${testDriveRequest.id}/?serializer=update`, { [field]: testDriveRequest[field] })
			.then((res) => {
				this.setState({ loading: false });
				this.getTestDriveRequest();
				AlertNotification(i18n.t("updated"), "success");
			})
			.catch((err) => {
				this.setState({ loading: false });
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	confirmDeleteTestDriveRequest = (request) => {
		return DeletePopup({
			title: "Are you sure ?",
			cancelbtntxt: "No",
			confirmbtntxt: "Yes",
			text: i18n.t("deleteTestDriveRequestConfirmText") + request.code,
			confirmedresult: () => this.deleteTestDriveRequest(request.id),
		});
	};

	deleteTestDriveRequest = (id) => {
		this.setState({ loading: true });
		axios
			.delete(`testdriverequest/${id}`)
			.then((res) => {
				if (res) {
					AlertNotification(i18n.t("deleted"), "success");
					this.setState({ loading: false });
					Swal.fire("Deleted!", i18n.t("testDriveRequestdeleted"), "success");
					this.props.history.push({
						pathname: "/list-test-drive-request",
					});
				}
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	getTestDriveRequest = () => {
		let id = new URLSearchParams(this.props.location.search).get("id");
		this.setState({ loading: true });
		axios
			.get(`testdriverequest/${id}/?serializer=get`)
			.then((res) => {
				this.setState({ testDriveRequest: res.data }, () => {
					this.setState({ loading: false });
				});
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	createSWO = () => {
		this.props.history.push({
			pathname: "/newworkorder",
			asset1: this.state.testDriveRequest.asset1 ? this.state.testDriveRequest.asset1.id : null,
			asset2: this.state.testDriveRequest.asset2 ? this.state.testDriveRequest.asset2.id : null,
			tdrID: this.state.testDriveRequest.id,
		});
	};

	componentDidMount() {
		this.getTestDriveRequest();
	}

	render() {
		const t = i18n.t;
		return (
			<>
				<PageTitleAlt2 heading={t("viewTestDriveRequest")} icon="bi bi-card-checklist icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row>
						<Col xs="12" md="12" lg="12">
							<TestDriveRequestCard
								createSWO={this.createSWO}
								changeField={this.changeField}
								updateField={this.updateField}
								cancelChange={this.cancelChange}
								getFieldValue={this.getFieldValue}
								data={this.state.testDriveRequest}
								confirmDeleteTestDriveRequest={this.confirmDeleteTestDriveRequest}
							/>
						</Col>
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}
export default withRouter(ViewTestDriveRequest);
