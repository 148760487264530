import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const AddIcon = (props) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);
	return (
		<>
			<i
				color="info"
				id="addIcon"
				index={props.index}
				hidden={props.hidden}
				onClick={props.onadd}
				cardindex={props.cardindex}
				techindex={props.techindex}
				style={{ fontSize: props.iconsize || "1rem", cursor: "pointer" }}
				className={`bi bi-plus-square-fill  info-hover ${props.cssclass}`}></i>
			<Tooltip
				isOpen={tooltipOpen}
				placement="right"
				target="addIcon"
				toggle={() => {
					setTooltipOpen(!tooltipOpen);
				}}>
				{props.tooltipText}
			</Tooltip>
		</>
	);
};

export default AddIcon;
