import i18n from "i18n";
import axios from "axios";
import moment from "moment";
import Loader from "react-loaders";
import React, { Component } from "react";
import ViewIcon from "ops/common/view_icon";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import { Card, CardBody, Col, Row } from "reactstrap";
import GetPermission from "ops/common/get_permission";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";

class ListDriver extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			drivers: [],
		};
	}

	getDrivers = () => {
		this.setState({ loading: true });
		axios
			.get(
				`driver/?serializer=listdrivers&profile__departments__name__in=${
					this.props.assisstant ? ["Driver Assistants"] : ["Drivers"]
				}`
			)
			.then((res) => {
				this.setState({ loading: false, drivers: res.data });
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
			});
	};

	componentDidMount() {
		this.getDrivers();
	}
	render() {
		const t = i18n.t;
		const driversColumns = [
			{
				sort: true,
				align: "center",
				dataField: "first_name",
				headerAlign: "center",
				text: t("name"),
			},
			{
				sort: true,
				align: "center",
				dataField: "username",
				headerAlign: "center",
				text: t("username"),
			},
			{
				sort: true,
				align: "center",
				dataField: "profile.phoneNumber",
				headerAlign: "center",
				text: t("phoneNumber"),
			},
			{
				sort: true,
				align: "center",
				dataField: "profile.hrCode",
				headerAlign: "center",
				text: t("hrCode"),
			},
			{
				sort: true,
				align: "center",
				dataField: "profile.hiringDate",
				headerAlign: "center",
				text: t("hiringDate"),
			},
			{
				sort: true,
				align: "center",
				dataField: "profile.license.number",
				headerAlign: "center",
				text: t("license/idNumber"),
			},
			{
				sort: true,
				align: "center",
				dataField: "profile.license.expiryDate",
				headerAlign: "center",
				text: t("license/idExpiryDate"),
				formatter: (cell) => {
					return moment(cell).format("YYYY-MM-DD");
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "profile.license.licenseClassification.name",
				headerAlign: "center",
				text: t("licenseClassification"),
			},
			{
				sort: true,
				align: "center",
				dataField: "profile.license.driverStatus",
				headerAlign: "center",
				text: t("status"),
				formatter: (cell, row) => {
					if (row.is_active) {
						try {
							return row.profile.license.driverStatus;
						} catch (e) {
							return t("n/a");
						}
					}
					return t("terminated");
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "action",
				headerAlign: "center",
				text: t("action"),
				formatter: (cell, row) => {
					return (
						<GetPermission code="users.view_ops_drivers">
							<ViewIcon
								code="perm"
								target="_blank"
								to={`/${this.props.assisstant ? "view-assisstant" : "viewdriver"}/?id=${row.id}`}
							/>
						</GetPermission>
					);
				},
			},
		];
		return (
			<>
				<PageTitleAlt2
					heading={this.props.assisstant ? t("driverAssisstants") : t("drivers")}
					icon="bi bi-people-fill icon-gradient bg-happy-fisher"
				/>
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row>
						<Col>
							<Card>
								<CardBody>
									<Row>
										<Col>
											<TableWithPaginationSearch
												scrollable
												exportable
												filename={this.props.assisstant ? "driver assisstants.csv" : "drivers.csv"}
												keyfield="hrCode"
												data={this.state.drivers}
												columns={driversColumns}
												search
											/>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}

export default withRouter(ListDriver);
