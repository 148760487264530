import React, { Component } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Button } from "reactstrap";

class Map extends Component {
	render() {
		const { center, onClick, onRightClick, zoom, getCurrentLocation } = this.props;
		return (
			<GoogleMap zoom={zoom} center={center} mapContainerClassName="map-continer" onClick={onClick} onRightClick={onRightClick}>
				<Marker position={center} draggable />
				<Button className="geolocation-button bg-transparent" onClick={getCurrentLocation}>
					<i className="bi bi-pin-map-fill text-dark"></i>
				</Button>
			</GoogleMap>
		);
	}
}

export default Map;
