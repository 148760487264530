import axios from "axios";
import i18n from "i18next";
import ViewIcon from "ops/common/view_icon";
import React, { Component, Fragment } from "react";
import GetPermission from "ops/common/get_permission";
import { Row, Col, Card, CardBody } from "reactstrap";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";

class ViewCostingList extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			allCostings: [],
			columnToggle: false,
		};
	}

	getCostings = (filterByType) => {
		if (!filterByType) filterByType = ["Lump Sum", "Rental", "CPT"];
		this.setState({ loading: true });
		axios
			.get(`costing/?serializer=list&type__in=${filterByType}`)
			.then((res) => {
				if (res) {
					this.setState({
						allCostings: res.data,
					});
				}
			})
			.catch((err) => {
				if (err) {
					console.error(err.response);
				}
			});
	};

	componentDidMount() {
		this.getCostings();
	}

	render() {
		const t = i18n.t;
		let columns = [
			{
				sort: true,
				align: "center",
				dataField: "name",
				text: i18n.t("name"),
				headerAlign: "center",
			},
			{
				sort: true,
				align: "center",
				dataField: "description",
				text: i18n.t("description"),
				headerAlign: "center",
			},
			{
				sort: true,
				hidden: false,
				align: "center",
				dataField: "type",
				headerAlign: "center",
				text: i18n.t("type"),
			},
			{
				dataField: "",
				align: "center",
				headerAlign: "center",
				text: i18n.t("actions"),
				formatter: (cell, costing) => {
					return (
						<div className="d-flex justify-content-center align-content-center p-0">
							<GetPermission perm="ops.view_costing">
								<div code="perm" className="p-0">
									<ViewIcon target="_blank" to={`/viewcosting/?id=${costing.id}`} />
								</div>
							</GetPermission>
						</div>
					);
				},
			},
		];
		return (
			<Fragment>
				<PageTitleAlt2 heading={t("costing")} icon="bi bi-currency-dollar icon-gradient bg-happy-fisher" />
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<Card className="main-card mb-3" key="0">
							<CardBody>
								<TableWithPaginationSearch
									scrollable
									exportable
									search
									columns={columns}
									data={this.state.allCostings}
									filename="costing.csv"
									loading={this.state.loading}
									columnToggle={this.state.columnToggle}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}
export default ViewCostingList;
