import { withTranslation } from "react-i18next";
import React, { Component, Fragment } from "react";
import PageTitleAlt2 from "../../common/PageTitleAlt2";

class Home extends Component {
	render() {
		const { t } = this.props;
		return (
			<Fragment>
				<PageTitleAlt2 heading={t("home")} icon="pe-7s-home icon-gradient bg-happy-fisher" />
			</Fragment>
		);
	}
}

export default withTranslation()(Home);
