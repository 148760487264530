import i18n from "i18n";
import React, { Fragment } from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { InputGroup, InputGroupAddon, FormGroup, Label, Form, Col, Row } from "reactstrap";

class DateRangePicker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: null,
			endDate: null,
		};
	}

	handleChange = ({ startDate, endDate }) => {
		startDate = startDate || this.state.startDate;
		endDate = endDate || this.state.endDate;
		if (startDate > endDate && endDate !== null) {
			endDate = startDate;
		}
		if (startDate) {
			var sDate = startDate.toJSON();
			sDate = sDate.slice(0, 10) + "T00:00:00";
		}
		if (endDate) {
			var eDate = endDate.toJSON();
			eDate = eDate.slice(0, 10) + "T23:59:59";
		}
		this.setState({ startDate, endDate });
		if (startDate && endDate) this.props.getdata(sDate, eDate);
	};

	handleChangeStart = (startDate) => this.handleChange({ startDate });

	handleChangeEnd = (endDate) => this.handleChange({ endDate });

	componentDidUpdate(prevProps) {
		if (this.props.clearDates) {
			if (prevProps.clear !== this.props.clear) this.setState({ startDate: null, endDate: null });
		}
	}

	render() {
		return (
			<Fragment>
				<Form>
					<Row form>
						<Col md={6}>
							<FormGroup>
								<Label className="mr-sm-2">{this.props.startDate ? this.props.startDate : i18n.t("startDate")}</Label>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<div className="input-group-text">
											<FontAwesomeIcon icon={faCalendarAlt} />
										</div>
									</InputGroupAddon>
									<DatePicker
										selectsStart
										dateFormat="dd-MM-yyyy"
										className="form-control"
										endDate={this.state.endDate}
										selected={this.state.startDate}
										startDate={this.state.startDate}
										onChange={this.handleChangeStart}
									/>
								</InputGroup>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label className="mr-sm-2">{this.props.endDate ? this.props.endDate : i18n.t("endDate")}</Label>
								<DatePicker
									selectsEnd
									dateFormat="dd-MM-yyyy"
									className="form-control"
									endDate={this.state.endDate}
									selected={this.state.endDate}
									onChange={this.handleChangeEnd}
									startDate={this.state.startDate}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</Fragment>
		);
	}
}

export default DateRangePicker;
