import React from "react";
import { FormGroup, Label, Input, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

class TripExpenseForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tripExpenses: [],
		};
	}

	expenses = [];

	getValue = (e) => {
		let tripExpenseIndex = e.target.getAttribute("index");
		let expenses = [...this.state.tripExpenses];
		expenses[tripExpenseIndex].amount = e.target.value;
		this.setState({ tripExpenses: expenses });
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.postExpenses !== this.props.postExpenses && this.props.postExpenses) {
			this.props.getValue(this.state.tripExpenses);
		}
	};

	componentDidMount = () => {
		this.setState({ tripExpenses: [...this.expenses] });
	};

	render() {
		const { trip, expensetypes, tripid, tripindex } = this.props;
		return (
			<Card className="main-card">
				<CardBody>
					<CardTitle className="d-flex justify-content-center">
						<div>
							<h1>{`${trip.number} ${trip.projectLeg}`}</h1>
						</div>
					</CardTitle>
					<Row>
						{expensetypes.map((type, index) => {
							this.expenses[index] = {
								vendorTrip: tripid,
								expenseType: type.expenseType_id,
								amount: 0,
							};
							return (
								<Col xs="12" md="3" key={index}>
									<FormGroup>
										<Label>{type.expenseType__name}</Label>
										<Input
											bsSize="sm"
											type="number"
											step={0.01}
											index={index}
											defaultValue={0}
											tripid={tripid}
											tripindex={tripindex}
											onChange={this.getValue}
											expensetype={type.expenseType_id}
										/>
									</FormGroup>
								</Col>
							);
						})}
					</Row>
				</CardBody>
			</Card>
		);
	}
}
export default TripExpenseForm;
