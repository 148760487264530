import i18n from "i18n";
import React from "react";
import moment from "moment";
import Selector from "ops/common/selector";
import DeleteIcon from "ops/common/delete_icon";
import { FormGroup, Label, Input, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

class WOForm extends React.Component {
	render() {
		const t = i18n.t;
		const { index, wo, routes, assets, attachedAssets, drivers, assisstants } = this.props;
		return (
			<Row>
				<Col xs="12">
					<Card className="main-card mb-3">
						<CardBody>
							<CardTitle className="d-flex justify-content-between">
								<div>{index + 1}</div>
								<div>
									<DeleteIcon index={index} ondelete={this.props.onDelete} />
								</div>
							</CardTitle>
							<Row>
								<Col xs="12">
									<FormGroup>
										<Label>{t("startDate")}</Label>
										<Input
											required
											bsSize="sm"
											index={index}
											id="startDate"
											type="datetime-local"
											value={wo["startDate"] || moment().format("YYYY-MM-DDTHH:mm")}
											onChange={this.props.getValue}
											invalid={wo["invalidStartDate"] ? wo["invalidStartDate"] : false}
										/>
									</FormGroup>
								</Col>
								<Col xs="12">
									<FormGroup>
										<Label>{t("route")}</Label>
										<Selector
											required
											id="route"
											size="small"
											isObjectOption
											options={routes}
											optionAccessor="name"
											name={index.toString()}
											error={wo["invalidRoute"]}
											value={wo.routeObj || { id: 0, name: "" }}
											onChange={(e, v) => {
												this.props.getSelectedValue(e, v, index);
											}}
										/>
									</FormGroup>
								</Col>
								<Col xs="12" md="6">
									<FormGroup>
										<Label>{t("asset")}</Label>
										<Selector
											required
											id="asset"
											size="small"
											isObjectOption
											options={assets}
											optionAccessor="code"
											name={index.toString()}
											error={wo["invalidAsset"]}
											value={wo.assetObj || { id: 0, code: "" }}
											onChange={(e, v) => {
												this.props.getSelectedValue(e, v, index);
											}}
										/>
									</FormGroup>
								</Col>
								<Col xs="12" md="6">
									<FormGroup>
										<Label>{t("attachedAsset")}</Label>
										<Selector
											size="small"
											isObjectOption
											options={attachedAssets}
											id="attachedAsset"
											optionAccessor="code"
											name={index.toString()}
											onChange={(e, v) => {
												this.props.getSelectedValue(e, v, index);
											}}
											value={wo.attachedAssetObj || { id: 0, code: "" }}
										/>
									</FormGroup>
								</Col>
								<Col xs="12" md="6">
									<FormGroup>
										<Label>{t("driver")}</Label>
										<Selector
											required
											id="driver"
											size="small"
											isObjectOption
											options={drivers}
											name={index.toString()}
											error={wo["invalidDriver"]}
											optionAccessor="driverName"
											onChange={(e, v) => {
												this.props.getSelectedValue(e, v, index);
											}}
											value={wo.driverObj || { id: 0, driverName: "" }}
										/>
									</FormGroup>
								</Col>
								<Col xs="12" md="6">
									<FormGroup>
										<Label>{t("assistant")}</Label>
										<Selector
											size="small"
											isObjectOption
											id="assisstant"
											options={assisstants}
											name={index.toString()}
											optionAccessor="driverName"
											onChange={(e, v) => {
												this.props.getSelectedValue(e, v, index);
											}}
											value={wo.assisstantObj || { id: 0, driverName: "" }}
										/>
									</FormGroup>
								</Col>
								<Col xs="12" md="6">
									<FormGroup>
										<Label>{t("cash")}</Label>
										<Input id="cash" type="number" index={index} value={wo["cash"] || 0} onChange={this.props.getValue} />
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label>{t("startKM")}</Label>
										<Input
											bsSize="sm"
											id="startKM"
											type="number"
											step={0.01}
											index={index}
											onChange={this.props.getValue}
											// Work order start KM validation commented until required
											// min={wo.assetObj ? wo.assetObj.lastGaugeReading : 0}
											value={wo.startKM ? wo.startKM : 0}
											invalid={wo["invalidStartKM"] ? wo["invalidStartKM"] : false}
										/>
									</FormGroup>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}
export default WOForm;
