const endPointUrl = () => {
	if (process.env.NODE_ENV === "production") return process.env.REACT_APP_OPS_BE_URL;
	return "http://localhost:8000/";
};

export const accountAPIUrl = () => {
	if (process.env.NODE_ENV === "production") return process.env.REACT_APP_ACC_BE_URL;
	return "http://localhost:8000/";
};

export const inventoryAPIUrl = () => {
	if (process.env.NODE_ENV === "production") return process.env.REACT_APP_INV_BE_URL;
	return "http://localhost:8000/";
};

export const maintenanceAPIUrl = () => {
	if (process.env.NODE_ENV === "production") return process.env.REACT_APP_MNTC_BE_URL;
	return "http://localhost:8000/";
};

export const purchaseAPIUrl = () => {
	if (process.env.NODE_ENV === "production") return process.env.REACT_APP_PURCH_BE_URL;
	return "http://localhost:8000/";
};

export const supportUrl = () => {
	return "https://wyz-solutions.atlassian.net/servicedesk/customer/portal/1";
};

export default endPointUrl();
