import React, { Component } from "react";
import NewNonPlannedWO from "../components/new_wo";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class NewNonPlannedWOScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<NewNonPlannedWO />
			</CSSTransitionGroup>
		);
	}
}

export default NewNonPlannedWOScene;
