import React, {Component} from "react";
import GetPermission from "./get_permission";
import UpdateDeleteIcon from "./update&delete_icons";
import {Card, CardBody, CardHeader, CardFooter, Button} from "reactstrap";

class CardTwoButton extends Component {
	render() {
		return (
			<Card className="main-card mb-3">
				<CardHeader className="text-capitalize card-header-tab" hidden={this.props.hideheader}>
					<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
						<i
							className={
								"header-icon " +
								(this.props.headerIcon ? this.props.headerIcon : "pe-7s-note2 icon-gradient bg-arielle-smile")
							}>
							{" "}
						</i>
						{this.props.title}
					</div>
					<div hidden={!this.props.hidefooter} className="card-header-title">
						<UpdateDeleteIcon
							onupdate={this.props.function1}
							ondelete={this.props.function2}
							updateperm={this.props.perm1 || "-"}
							deleteperm={this.props.perm2 || "-"}
						/>
					</div>
				</CardHeader>
				<CardBody>{this.props.children}</CardBody>
				<div hidden={this.props.hidefooter}>
					<CardFooter className="d-block text-right">
						<GetPermission perm={this.props.perm1 || "-"}>
							<Button
								size="lg"
								code="perm"
								className="mr-1"
								color={this.props.color1}
								onClick={this.props.function1}>
								{this.props.title1}
							</Button>
						</GetPermission>
						<GetPermission perm={this.props.perm2 || "-"}>
							<Button
								size="lg"
								id="create"
								code="perm"
								color={this.props.color2}
								onClick={this.props.function2}
								disabled={this.props.disablebtn2}>
								{this.props.title2}
							</Button>
						</GetPermission>
					</CardFooter>
				</div>
			</Card>
		);
	}
}
export default CardTwoButton;
