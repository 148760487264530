import i18n from "i18n";
import moment from "moment";
import React, { Component } from "react";
import GetPermission from "ops/common/get_permission";
import { Button, Card, CardBody, CardFooter, Col, ListGroup, ListGroupItem, Row } from "reactstrap";

class DriverCard extends Component {
	render() {
		const t = i18n.t;
		return (
			<Card className="rounded p-2">
				<CardBody>
					<Row>
						<Col xs="12" md="4" lg="4">
							<ListGroup flush>
								<ListGroupItem>
									<div className="widget-content p-0">
										<div className="widget-content-outer">
											<div className="widget-content-wrapper">
												<div className="widget-content-left d-flex">
													<div className="widget-heading mx-2">{t("name")}</div>
													<div className="widget-heading text-info">{this.props.driver.first_name}</div>
												</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
								<ListGroupItem></ListGroupItem>
							</ListGroup>
						</Col>
						<Col xs="12" md="4" lg="4">
							<ListGroup flush>
								<ListGroupItem>
									<div className="widget-content p-0">
										<div className="widget-content-outer">
											<div className="widget-content-wrapper">
												<div className="widget-content-left d-flex">
													<div className="widget-heading mx-2">{t("username")}</div>
													<div className="widget-heading text-info">{this.props.driver.username}</div>
												</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
								<ListGroupItem></ListGroupItem>
							</ListGroup>
						</Col>
						<Col xs="12" md="4" lg="4">
							<ListGroup flush>
								<ListGroupItem>
									<div className="widget-content p-0">
										<div className="widget-content-outer">
											<div className="widget-content-wrapper">
												<div className="widget-content-left d-flex">
													<div className="widget-heading mx-2">{t("hrCode")}</div>
													<div className="widget-heading text-info">{this.props.driver.profile.hrCode}</div>
												</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
								<ListGroupItem></ListGroupItem>
							</ListGroup>
						</Col>
						<Col xs="12" md="4" lg="4">
							<ListGroup flush>
								<ListGroupItem>
									<div className="widget-content p-0">
										<div className="widget-content-outer">
											<div className="widget-content-wrapper">
												<div className="widget-content-left d-flex">
													<div className="widget-heading mx-2">{t("phoneNumber")}</div>
													<div className="widget-heading text-info">{this.props.driver.profile.phoneNumber}</div>
												</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
								<ListGroupItem></ListGroupItem>
							</ListGroup>
						</Col>
						<Col xs="12" md="4" lg="4">
							<ListGroup flush>
								<ListGroupItem>
									<div className="widget-content p-0">
										<div className="widget-content-outer">
											<div className="widget-content-wrapper">
												<div className="widget-content-left d-flex">
													<div className="widget-heading mx-2">{t("license/idNumber")}</div>
													<div className="widget-heading text-info">{this.props.driver.profile.license.number}</div>
												</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
								<ListGroupItem></ListGroupItem>
							</ListGroup>
						</Col>
						<Col xs="12" md="4" lg="4">
							<ListGroup flush>
								<ListGroupItem>
									<div className="widget-content p-0">
										<div className="widget-content-outer">
											<div className="widget-content-wrapper">
												<div className="widget-content-left d-flex">
													<div className="widget-heading mx-2">{t("hiringDate")}</div>
													<div className="widget-heading text-info">
														{this.props.driver.profile.hiringDate
															? moment(this.props.driver.profile.hiringDate).format("YYYY-MM-DD")
															: "N/A"}
													</div>
												</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
								<ListGroupItem></ListGroupItem>
							</ListGroup>
						</Col>
						<Col xs="12" md="4" lg="4">
							<ListGroup flush>
								<ListGroupItem>
									<div className="widget-content p-0">
										<div className="widget-content-outer">
											<div className="widget-content-wrapper">
												<div className="widget-content-left d-flex">
													<div className="widget-heading mx-2">{t("license/idExpiryDate")}</div>
													<div className="widget-heading text-info">
														{moment(this.props.driver.profile.license.expiryDate).format("YYYY-MM-DD")}
													</div>
												</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
								<ListGroupItem></ListGroupItem>
							</ListGroup>
						</Col>
						<Col xs="12" md="4" lg="4">
							<ListGroup flush>
								<ListGroupItem>
									<div className="widget-content p-0">
										<div className="widget-content-outer">
											<div className="widget-content-wrapper">
												<div className="widget-content-left d-flex">
													<div className="widget-heading mx-2">{t("licenseClassification")}</div>
													<div className="widget-heading text-info">
														{this.props.driver.profile.license.licenseClassification.name}
													</div>
												</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
							</ListGroup>
						</Col>
						<Col xs="12" md="4" lg="4">
							<ListGroup flush>
								<ListGroupItem>
									<div className="widget-content p-0">
										<div className="widget-content-outer">
											<div className="widget-content-wrapper">
												<div className="widget-content-left d-flex">
													<div className="widget-heading mx-2">{t("status")}</div>
													<div className="widget-heading text-info">
														{this.props.driver.is_active ? this.props.driver.profile.license.driverStatus : t("terminated")}
													</div>
												</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
							</ListGroup>
						</Col>
						<Col xs="12" md="4" lg="4">
							<ListGroup flush>
								<ListGroupItem>
									<div className="widget-content p-0">
										<div className="widget-content-outer">
											<div className="widget-content-wrapper">
												<div className="widget-content-left d-flex">
													<div className="widget-heading mx-2">{t("note")}</div>
													<div className="widget-heading text-info">{this.props.driver.profile.license.note}</div>
												</div>
											</div>
										</div>
									</div>
								</ListGroupItem>
							</ListGroup>
						</Col>
					</Row>
				</CardBody>
				<CardFooter>
					<Col className="d-flex justify-content-end">
						<GetPermission perm="users.update_ops_driver">
							<Button code="perm" color="danger" onClick={this.props.editView}>
								{t("update")}
							</Button>
						</GetPermission>
					</Col>
				</CardFooter>
			</Card>
		);
	}
}

export default DriverCard;
