import reducers from "../reducers";
import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";

export default function configureStore() {
	return createStore(
		combineReducers({
			...reducers,
		}),
		composeWithDevTools()
	);
}
