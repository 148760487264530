import i18n from "i18n";
import React from "react";
import Selector from "ops/common/selector";
import DeleteIcon from "ops/common/delete_icon";
import { FormGroup, Label, Input, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

class LegForm extends React.Component {
	render() {
		const t = i18n.t;
		return (
			<Row>
				<Col xs="12">
					<Card className="main-card mb-3">
						<CardBody>
							<CardTitle className="d-flex justify-content-between">
								<div>{this.props.index + 1}</div>
								<div>
									<DeleteIcon index={this.props.index} ondelete={this.props.onDelete} />
								</div>
							</CardTitle>
							<Row>
								<Col xs="12">
									<FormGroup>
										<Label>{t("leg")}</Label>
										<Selector
											required
											id="leg"
											size="small"
											isObjectOption
											optionAccessor="name"
											options={this.props.legs}
											onChange={(e, v) => {
												this.props.getStandards(e, v, this.props.index);
												this.props.getSelectedValue(e, v, this.props.index);
											}}
											value={this.props.selectedLeg["legObject"] || { id: 0, name: "" }}
										/>
									</FormGroup>
								</Col>
								<Col xs="12">
									<FormGroup>
										<Label>{t("standard")}</Label>
										<Selector
											required
											id="standard"
											size="small"
											isObjectOption
											optionAccessor="name"
											options={this.props.standards}
											onChange={(e, v) => {
												this.props.getSelectedValue(e, v, this.props.index);
											}}
											value={this.props.selectedLeg["standardObject"] || { name: "" }}
										/>
									</FormGroup>
								</Col>
								{this.props.selectedLeg["standard"] && (
									<>
										<Col xs="12" md="4">
											<FormGroup>
												<Label>{t("tripQuantity")}</Label>
												<Input
													type="number"
													step={0.01}
													id="tripQuantity"
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["tripQuantity"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="4">
											<FormGroup>
												<Label>{t("uom")}</Label>

												<Selector
													id="uom"
													isObjectOption
													optionAccessor="fullName"
													options={this.props.uoms}
													onChange={(e, v) => {
														this.props.getSelectedValue(e, v, this.props.index);
													}}
													value={this.props.selectedLeg["standardObject"]["uom"] || { id: 0, fullName: "" }}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="4">
											<FormGroup>
												<Label>{t("commodity")}</Label>
												<Selector
													id="commodity"
													isObjectOption
													optionAccessor="name"
													options={this.props.commodities}
													onChange={(e, v) => {
														this.props.getSelectedValue(e, v, this.props.index);
													}}
													value={this.props.selectedLeg["standardObject"]["commodity"] || { id: 0, name: "" }}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="4">
											<FormGroup>
												<Label>{t("averageSpeed")}</Label>
												<Input
													type="number"
													step={0.01}
													id="averageSpeed"
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["averageSpeed"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="4">
											<FormGroup>
												<Label>{t("fuelRate")}</Label>
												<Input
													type="number"
													step={0.01}
													id="fuelRate"
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["fuelRate"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="4">
											<FormGroup>
												<Label>{t("tolls")}</Label>
												<Input
													id="tolls"
													type="number"
													step={0.01}
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["tolls"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="3">
											<FormGroup>
												<Label>{t("scales")}</Label>
												<Input
													id="scales"
													type="number"
													step={0.01}
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["scales"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="3">
											<FormGroup>
												<Label>{t("tips")}</Label>
												<Input
													id="tips"
													type="number"
													step={0.01}
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["tips"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="3">
											<FormGroup>
												<Label>{t("driverBonus")}</Label>
												<Input
													id="driverBonus"
													type="number"
													step={0.01}
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["driverBonus"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="3">
											<FormGroup>
												<Label>{t("driverAllowance")}</Label>
												<Input
													type="number"
													step={0.01}
													id="driverAllowance"
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["driverAllowance"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="3">
											<FormGroup>
												<Label>{t("driverAsstAllowance")}</Label>
												<Input
													type="number"
													step={0.01}
													id="driverAsstAllowance"
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["driverAsstAllowance"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="6">
											<FormGroup>
												<Label>{t("loadingTime") + " (minutes)"}</Label>
												<Input
													type="number"
													step={0.01}
													id="loadingTime"
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["loadingTime"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="6">
											<FormGroup>
												<Label>{t("dischargingTime") + " (minutes)"}</Label>
												<Input
													type="number"
													step={0.01}
													id="dischargingTime"
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["dischargingTime"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="6">
											<FormGroup>
												<Label>{t("stoppage") + " (minutes)"}</Label>
												<Input
													type="number"
													step={0.01}
													id="stoppage"
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["stoppage"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="6">
											<FormGroup>
												<Label>{t("drivingRestingTime") + " (minutes)"}</Label>
												<Input
													type="number"
													step={0.01}
													id="drivingRestingTime"
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["drivingRestingTime"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="6">
											<FormGroup>
												<Label>{t("tripTime") + " (minutes)"}</Label>
												<Input
													type="number"
													step={0.01}
													id="tripTime"
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["tripTime"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
										<Col xs="12" md="6">
											<FormGroup>
												<Label>{t("tripsPerDay") + " (minutes)"}</Label>
												<Input
													type="number"
													step={0.01}
													id="tripsPerDay"
													index={this.props.index}
													onChange={this.props.getValue}
													value={this.props.selectedLeg["standardObject"]["tripsPerDay"] || 0}
													disabled
												/>
											</FormGroup>
										</Col>
									</>
								)}
								<Col xs="4">
									<FormGroup>
										<input
											id="isDead"
											type="checkbox"
											index={this.props.index}
											onChange={this.props.getValue}
											checked={this.props.selectedLeg["isDead"] || false}
										/>
										<Label className="ml-2">{t("isDead")}</Label>
									</FormGroup>
								</Col>
								<Col xs="4">
									<FormGroup>
										<input
											id="isStart"
											type="checkbox"
											index={this.props.index}
											onChange={this.props.getValue}
											checked={this.props.selectedLeg["isStart"] || false}
										/>
										<Label className="ml-2">{t("isStart")}</Label>
									</FormGroup>
								</Col>
								<Col xs="4">
									<FormGroup>
										<input
											id="isEnd"
											type="checkbox"
											index={this.props.index}
											onChange={this.props.getValue}
											checked={this.props.selectedLeg["isEnd"] || false}
										/>
										<Label className="ml-2">{t("isEnd")}</Label>
									</FormGroup>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}
export default LegForm;
