import i18n from "i18n";
import React, { Component, Fragment } from "react";
import GetPermission from "ops/common/get_permission";
import { Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";

class ListNonPlannedWOTrips extends Component {
	render() {
		const { trips, tripColumns, onUpdate, onFill, logs, editable, loading } = this.props;
		const t = i18n.t;
		return (
			<Fragment>
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<div className="dropdown-menu-header m-0">
							<div className="dropdown-menu-header-inner m-0 rounded bg-primary">
								<div className="menu-header-content">
									<h5 className="menu-header-title">{t("trips")}</h5>
								</div>
							</div>
						</div>
						<Card className="main-card mb-3">
							<CardBody>
								<TableWithPaginationSearch
									scrollable
									exportable
									filename="trips.csv"
									loading={loading}
									keyfield="id"
									columns={tripColumns}
									data={trips}
								/>
							</CardBody>
							<CardFooter className="d-flex justify-content-end">
								<GetPermission perm="ops.change_trip">
									<div code="perm">
										<Button color="dark" onClick={onFill} className="ml-1" hidden={logs.length !== 0 && !editable}>
											{t("fill")}
										</Button>
										<Button color="info" onClick={onUpdate} className="ml-2" hidden={logs.length !== 0}>
											{t("update")}
										</Button>
									</div>
								</GetPermission>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default ListNonPlannedWOTrips;
