import {Loader} from "react-loaders";
import React, {Component} from "react";
import LoadingOverlay from "react-loading-overlay";

class LoadingIcon extends Component {
	render() {
		return (
			<LoadingOverlay
				tag="div"
				active={this.props.status}
				styles={{
					overlay: (base) => ({
						...base,
						background: "#fff",
						opacity: 0.5,
					}),
				}}
				spinner={<Loader color="#ffffff" active type={this.props.type} />}>
				{this.props.children}
			</LoadingOverlay>
		);
	}
}
export default LoadingIcon;
