import i18n from "i18n";
import moment from "moment";
import React, { Component } from "react";
import InfoIcon from "ops/common/info_icon";
import { Label, Input, Row, Col } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

class ExpenseForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hideExtraInfo: true,
			tripExpenses: [],
			total: 0,
		};
	}

	expenses = [];
	scroll = null;

	handleExtraInfo = () => {
		this.setState({ hideExtraInfo: !this.state.hideExtraInfo });
	};

	getScrolElement = (scrol) => {
		this.scroll = scrol;
	};

	moveScrol = (scrolPosition) => {
		this.scroll.scrollLeft = scrolPosition;
	};

	getValue = (e) => {
		let tripExpenseIndex = e.target.getAttribute("index");
		let expenses = [...this.state.tripExpenses];
		expenses[tripExpenseIndex].amount = e.target.value;
		this.setState({ tripExpenses: expenses }, () => {
			this.getTotal(expenses);
		});
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.postExpenses !== this.props.postExpenses && this.props.postExpenses) {
			this.props.getValue(this.state.tripExpenses);
		}
		if (prevProps.extraInfoActiveIndex !== this.props.extraInfoActiveIndex)
			if (this.props.extraInfoActiveIndex === this.props.tripIndex) this.setState({ hideExtraInfo: false });
			else this.setState({ hideExtraInfo: true });
		if (prevProps.scrolPosition !== this.props.scrolPosition) this.moveScrol(this.props.scrolPosition);
	};

	componentDidMount = () => {
		this.setState({ tripExpenses: [...this.expenses] }, () => {
			if (this.props.expenses) {
				this.getTotal(this.props.expenses);
			} else {
				this.getTotal(this.state.tripExpenses);
			}
		});
	};

	getTotal = (expenses) => {
		let sum = 0;
		expenses.forEach((expense) => {
			if (expense.expenseTypeName !== "Driver Bonus") sum += Number(expense.amount);
		});
		this.setState({ total: sum });
	};

	render() {
		const t = i18n.t;
		const {
			trip,
			tripIndex,
			expensesTypes,
			projectExpenseTypes,
			getLoadingReceiptAndCopounNos,
			tripsReceipts,
			target,
			tripData,
		} = this.props;
		return (
			<Row>
				<Col xs="12" lg="12" className="d-flex justify-content-center">
					<sub color="info" className="mr-3 mb-4 mt-2" hidden={this.state.hideExtraInfo}>{`Loaded Quantity: ${Number(
						trip.loadedQTY
					).toLocaleString()}`}</sub>
					<sub color="info" className="mr-3 mb-4 mt-2" hidden={this.state.hideExtraInfo}>{`Delivered Quantity: ${Number(
						tripData.deliveredQTY
					).toLocaleString()}`}</sub>
					<sub color="info" className="mr-3 mb-4 mt-2" hidden={this.state.hideExtraInfo}>{`Start KM: ${Number(
						tripData.startKM
					).toLocaleString()}`}</sub>
					<sub color="info" className="mr-3 mb-4 mt-2" hidden={this.state.hideExtraInfo}>{`End KM: ${Number(
						tripData.endKM
					).toLocaleString()}`}</sub>
					<sub color="info" className="mr-3 mb-4 mt-2" hidden={this.state.hideExtraInfo}>{`Leg: ${trip.legName ||
						tripData.projectLeg ||
						trip.leg ||
						tripData.leg}`}</sub>
					<sub
						color="info"
						className="mr-3 mb-4 mt-2"
						hidden={this.state.hideExtraInfo}>{`Owner: ${tripData.owner.fullName}`}</sub>
				</Col>
				<Col xs="1" lg="1" className="d-flex justify-content-center align-items-center">
					<div>
						<InfoIcon iconSize="1.5rem" cssclass="mt-1" onclick={this.handleExtraInfo} />
					</div>
					<div className="badge badge-pill badge-info ml-1">{tripIndex + 1}</div>
				</Col>
				<Col xs="11" lg="11">
					<PerfectScrollbar
						key={tripIndex}
						className="d-flex"
						onScrollLeft={this.props.setScrol}
						containerRef={this.getScrolElement}
						onScrollRight={this.props.setScrol}>
						<Col xs="2" lg="2" className="mb-3">
							<Label style={{ minWidth: "fit-content" }} className="text-dark">
								{t("loadingReceiptNo")}
							</Label>
							<Input
								required={trip.loadedQTY ? true : false}
								onChange={getLoadingReceiptAndCopounNos}
								onFocus={() => {
									this.props.onFocus(tripIndex);
								}}
								bsSize="sm"
								name="loadingReceiptNo"
								type="text"
								style={{ width: "50px", minWidth: "fit-content", maxWidth: "120px" }}
								tripid={trip.id}
								tripindex={tripIndex}
								invalid={
									tripsReceipts[tripIndex] &&
									tripsReceipts[tripIndex].invalidLoadingReceiptNo &&
									tripsReceipts[tripIndex].invalidLoadingReceiptNo
								}
								value={
									tripsReceipts[tripIndex] &&
									tripsReceipts[tripIndex].loadingReceiptNo &&
									tripsReceipts[tripIndex].loadingReceiptNo
								}
							/>
						</Col>
						<Col xs="2" lg="2" className="mb-3">
							<Label style={{ minWidth: "fit-content" }} className="text-dark">
								{t("couponNo")}
							</Label>
							<Input
								required={trip.loadedQTY ? true : false}
								onChange={getLoadingReceiptAndCopounNos}
								onFocus={() => {
									this.props.onFocus(tripIndex);
								}}
								bsSize="sm"
								type="text"
								name="couponNo"
								style={{ width: "50px", minWidth: "fit-content", maxWidth: "120px" }}
								tripid={trip.id}
								tripindex={tripIndex}
								invalid={
									tripsReceipts[tripIndex] && tripsReceipts[tripIndex].invalidCouponNo && tripsReceipts[tripIndex].invalidCouponNo
								}
								value={tripsReceipts[tripIndex] && tripsReceipts[tripIndex].couponNo && tripsReceipts[tripIndex].couponNo}
							/>
						</Col>
						<Col xs="2" lg="2" className="mb-3">
							<Label style={{ minWidth: "fit-content" }} className="text-dark">
								{t("loadingDate")}
							</Label>
							<Input
								disabled
								bsSize="sm"
								type="text"
								name="loadinDate"
								style={{ width: "50px", minWidth: "fit-content", maxWidth: "120px" }}
								value={moment(trip.loadingDate, "YYYY-MM-DDTHH:mm:ssZ").format("DD-MM-YYYY")}
							/>
						</Col>
						{projectExpenseTypes && projectExpenseTypes.length !== 0
							? projectExpenseTypes.map((type, index) => {
									let typename = type.expenseType__name
										.replaceAll(" ", "")
										.replaceAll(".", "")
										.toLowerCase();
									this.expenses[index] = {
										trip: trip.id,
										expenseType: type.expenseType_id,
										expenseTypeName: type.expenseType__name,
										amount: trip.projectLeg && trip.projectLeg[typename] ? trip.projectLeg[typename] : 0,
									};
									return (
										<Col xs="2" lg="2" key={index} className="mb-3">
											<Label style={{ minWidth: "fit-content" }}>{type.expenseType__name}</Label>
											<Input
												required
												target={target}
												onFocus={() => {
													this.props.onFocus(tripIndex);
												}}
												bsSize="sm"
												type="number"
												name={type.expenseType__name}
												style={{ width: "50px", minWidth: "fit-content", maxWidth: "120px" }}
												step={0.01}
												index={index}
												defaultValue={
													this.props.expenses
														? this.props.expenses[index].amount
														: trip.projectLeg && trip.projectLeg[typename]
														? trip.projectLeg[typename]
														: " "
												}
												invalid={trip[type.expenseType__name]}
												tripid={trip.id}
												onChange={this.getValue}
												expensetype={type.expenseType_id}
												tripindex={tripIndex}
												disabled={!type.expenseType__isMutable}
											/>
										</Col>
									);
							  })
							: expensesTypes.map((type, index) => {
									this.expenses[index] = {
										trip: trip.id,
										expenseType: type.id,
										expenseTypeName: type.name,
										amount: 0,
									};
									return (
										<Col xs="2" lg="2" key={index} className="mb-3">
											<Label style={{ minWidth: "fit-content" }}>{type.name}</Label>
											<Input
												required
												target={target}
												onFocus={() => {
													this.props.onFocus(tripIndex);
												}}
												bsSize="sm"
												style={{ width: "50px", minWidth: "fit-content", maxWidth: "120px" }}
												type="number"
												step={0.01}
												index={index}
												name={type.name}
												defaultValue={this.props.expenses ? this.props.expenses[index].amount : " "}
												tripid={trip.id}
												onChange={this.getValue}
												expensetype={type.id}
												tripindex={tripIndex}
												disabled={!type.isMutable}
												invalid={trip[type.name]}
											/>
										</Col>
									);
							  })}
						<Col xs="2" lg="2" className="mb-3">
							<Label style={{ minWidth: "fit-content" }}>{t("total")}</Label>
							<Input
								bsSize="sm"
								style={{ width: "50px", minWidth: "fit-content", maxWidth: "120px" }}
								type="number"
								step={0.001}
								value={this.state.total}
								disabled
							/>
						</Col>
					</PerfectScrollbar>
				</Col>
			</Row>
		);
	}
}

export default ExpenseForm;
