import i18n from "i18n";
import { Col, Row } from "reactstrap";
import React, { Component } from "react";
import ProgressCard from "ops/common/progress_card";

class WOStatsCard extends Component {
	render() {
		const t = i18n.t;
		const { openWO, closedWO, totalWO } = this.props;
		return (
			<>
				<Row>
					<Col xs="12" md="4">
						<ProgressCard
							title={t("openWorkorders")}
							count={openWO}
							percent={Number(totalWO) === 0 ? 0 : (Number(openWO) * 100) / Number(totalWO)}
							color="warning"
						/>
					</Col>
					<Col xs="12" md="4">
						<ProgressCard
							title={t("closeWorkorders")}
							count={closedWO}
							percent={Number(totalWO) === 0 ? 0 : (Number(closedWO) * 100) / Number(totalWO)}
							color="info"
						/>
					</Col>
					<Col xs="12" md="4">
						<ProgressCard title={t("totalWorkorders")} count={totalWO} percent={Number(totalWO) === 0 ? 0 : 100} color="dark" />
					</Col>
				</Row>
			</>
		);
	}
}
export default WOStatsCard;
