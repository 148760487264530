import React, { Component } from "react";
import ViewProjectList from "../components/list_projects";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class ListProjectScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<ViewProjectList />
			</CSSTransitionGroup>
		);
	}
}

export default ListProjectScene;
