import i18n from "i18n";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "react-loaders";
import { Col, Row } from "reactstrap";
import React, { Component } from "react";
import CommodityForm from "./commodity_form";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import { AlertNotification } from "ops/common/alert_notification";

class NewCommodity extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			commodity: { name: "", commodityTypeObject: { name: "" }, description: "" },
			commodityTypes: [],
		};
	}

	getValue = (e, v) => {
		let value;
		let target;
		let commodity = { ...this.state.commodity };
		if (v) {
			target = e.target.id.split("-")[0];
			value = v.id;
			commodity[`${target}Object`] = v;
		} else {
			target = e.target.name;
			value = e.target.value;
		}
		commodity[target] = value;
		commodity[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
		this.setState({ commodity: commodity });
	};

	validate = (e) => {
		let target = e.target.name;
		if (!target) target = e.target.id.split("-")[0];
		let commodity = { ...this.state.commodity };
		commodity[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		this.setState({ commodity: commodity });
	};

	onSubmit = (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		axios
			.post(`commodity/`, this.state.commodity)
			.then((res) => {
				if (res) {
					this.setState({ loading: false });
					AlertNotification(i18n.t("created"), "success");
					this.showAlert(res.data.name);
				}
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
				this.setState({ loading: false });
			});
	};

	getCommodityTypes = () => {
		axios.get(`commoditytype/`).then((res) => {
			this.setState({ commodityTypes: res.data });
		});
	};

	showAlert = (commodity) => {
		Swal.fire({
			icon: "success",
			buttonsStyling: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			showCancelButton: false,
			cancelButtonText: i18n.t("no"),
			title: `${i18n.t("commodity")}:\n ${commodity}`,
			confirmButtonText: i18n.t("ok"),
			customClass: {
				confirmButton: "btn btn-dark",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				this.setState({ commodity: { name: "", commodityTypeObject: { name: "" }, description: "" } });
			}
		});
	};

	componentDidMount() {
		this.getCommodityTypes();
	}

	render() {
		const t = i18n.t;
		return (
			<>
				<PageTitleAlt2 heading={t("newCommodity")} icon="bi bi-inboxes-fill icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row className="m-3 justify-content-center">
						<Col xs="12">
							<CommodityForm
								getValue={this.getValue}
								validate={this.validate}
								onSubmit={this.onSubmit}
								commodity={this.state.commodity}
								commodityTypes={this.state.commodityTypes}
							/>
						</Col>
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}
export default withRouter(NewCommodity);
