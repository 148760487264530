import React from "react";
import UpdateIcon from "./update_icon";
import DeleteIcon from "./delete_icon";
import GetPermission from "./get_permission";

const UpdateDeleteIcon = (props) => {
	return (
		<div style={{position: "absolute", right: "0"}}>
			<GetPermission perm={props.updateperm}>
				<UpdateIcon code="perm" onupdate={props.onupdate} cssclass="update-icon-card" />
			</GetPermission>
			<GetPermission perm={props.deleteperm}>
				<DeleteIcon code="perm" ondelete={props.ondelete} cssclass="delete-icon-card" />
			</GetPermission>
		</div>
	);
};
export default UpdateDeleteIcon;
