import i18n from "i18n";
import XLSX from "xlsx";
import axios from "axios";
import moment from "moment";
import Loader from "react-loaders";
import React, { Component } from "react";
import Selector from "ops/common/selector";
import DeleteIcon from "ops/common/delete_icon";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import { AlertNotification } from "ops/common/alert_notification";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Form, Input, Label, Row } from "reactstrap";

class BulkCreateDriver extends Component {
	constructor() {
		super();
		this.state = {
			faild: [],
			drivers: [],
			newDrivers: [],
			loading: false,
			showUploadBtn: false,
			showDownloadBtn: false,
			licenseClassifications: [],
		};
	}

	getLicenseClassifications = () => {
		axios
			.get(`licenseclassification`)
			.then((res) => {
				this.setState({ licenseClassifications: res.data });
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	showDownloadBtn = () => {
		this.setState({ showDownloadBtn: true, showUploadBtn: false });
	};

	downloadTemplate = () => {
		let columns = [
			{
				name: "",
				email: "",
				hrCode: "",
				username: "",
				password: "",
				hiringDate: "",
				phoneNumber: "",
				licenseNumber: "",
				licenseExpiryDate: "",
				licenseClassification: "",
			},
		];

		let wb = XLSX.utils.book_new();
		let ws = XLSX.utils.json_to_sheet(columns);
		XLSX.utils.book_append_sheet(wb, ws, "template");
		XLSX.writeFile(wb, this.props.assisstant ? i18n.t("assisstantsXLSX") : i18n.t("driversXLSX"));
		this.setState({ showDownloadBtn: false, showUploadBtn: true });
	};

	uploadTemplate = (evt) => {
		let file = new FileReader();
		file.onload = (e) => {
			let data = e.target.result;
			let workbook = XLSX.read(data, { type: "binary" });
			let drivers = XLSX.utils.sheet_to_json(workbook.Sheets["template"]);
			drivers.forEach((driver) => {
				let licenseClassfication = this.state.licenseClassifications.filter(
					(licenseClassfication) => licenseClassfication.name === driver.licenseClassification
				)[0].id;
				let expiryDate = driver.licenseExpiryDate;
				driver.licenseClassification = licenseClassfication;
				driver.licenseExpiryDate = moment(expiryDate + moment().format("THH:MM")).format("YYYY-MM-DDTHH:MM");
				driver.hiringDate ? (driver.hiringDate = moment(driver.hiringDate).format("YYYY-MM-DD")) : (driver.hiringDate = null);
				if (!driver.email) driver.email = "";
				if (!driver.phoneNumber) driver.phoneNumber = "";
				if (!driver.hrCode) driver.hrCode = "";
			});
			this.setState((prevState) => {
				return { drivers: [...prevState.drivers, ...drivers] };
			});
		};
		if (evt.target.files.length > 0) file.readAsBinaryString(evt.target.files[0]);
	};

	selectTemplate = (e) => {
		e.target.value = null;
		this.setState({ showDownloadBtn: false });
	};

	addDriver = () => {
		this.setState((prevState) => {
			return {
				newDrivers: [
					...prevState.newDrivers,
					{
						name: "",
						email: "",
						username: "",
						password: "",
						hrCode: "",
						phoneNumber: "",
						licenseNumber: "",
						licenseExpiryDate: moment().format("YYYY-MM-DD"),
						licenseClassification: "",
					},
				],
			};
		});
	};

	getNewDrivers = (e) => {
		let index = e.target.attributes.index.value;
		let newDrivers = [...this.state.newDrivers];
		if (e.target.name === "licenseExpiryDate") {
			newDrivers[index][e.target.name] = moment(e.target.value + moment().format("THH:MM")).format("YYYY-MM-DDTHH:MM");
		} else {
			newDrivers[index][e.target.name] = e.target.value;
		}
		newDrivers[index][`invalid${e.target.name.charAt(0).toUpperCase()}${e.target.name.slice(1, e.target.name.length)}`] = false;
		this.setState({ newDrivers: newDrivers });
	};

	getLicenseClassificationValue = (e, v, index) => {
		let newDrivers = [...this.state.newDrivers];
		newDrivers[index]["licenseClassification"] = v.id;
		newDrivers[index]["licenseClassificationObj"] = v;
		newDrivers[index]["invalidLicenseClassification"] = false;
		this.setState({ newDrivers: newDrivers });
	};

	isValid = (date) => {
		return moment(date, "YYYY-MM-DDTHH:MM", true).isValid();
	};

	addToDrivers = (e) => {
		e.preventDefault();
		let index = e.target.getAttribute("index"),
			pass = true,
			newDrivers = [...this.state.newDrivers],
			driver = newDrivers[index];
		if (driver.invalidPassword) {
			AlertNotification(i18n.t("invalidPassword"), "error");
			pass = false;
		}
		if (!this.isValid(driver.licenseExpiryDate)) {
			AlertNotification(i18n.t("invalidDate"), "error");
			pass = false;
		}
		if (!pass) return;
		newDrivers.splice(index, 1);
		this.setState((prevState) => {
			return {
				drivers: [...prevState.drivers, driver],
				newDrivers: newDrivers,
			};
		});
	};

	validateNewDrivers = (e) => {
		let newDrivers = [...this.state.newDrivers];
		let index;
		let target;
		if (e.target.id.split("-")[0] === "licenseClassification") {
			index = e.target.name;
			target = e.target.id.split("-")[0];
		} else {
			index = e.target.getAttribute("index");
			target = e.target.name;
		}
		newDrivers[index][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		this.setState({ newDrivers: newDrivers });
	};

	removeDriver = (e) => {
		let index = e.target.getAttribute("index");
		let newDrivers = [...this.state.newDrivers];
		newDrivers.splice(index, 1);
		this.setState({ newDrivers: newDrivers });
	};

	submit = () => {
		let data = {
			drivers: this.state.drivers,
			department: this.props.assisstant ? "Driver Assistants" : "Drivers",
		};
		this.setState({ loading: true });
		axios
			.post(`profile/bulkCreateDrivers/`, data)
			.then((res) => {
				if (Array.isArray(res.data)) {
					this.setState({ faild: res.data });
				}
				this.setState({ loading: false, drivers: [] });
				AlertNotification(i18n.t("submit"), "success");
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	componentDidMount() {
		this.getLicenseClassifications();
	}

	render() {
		const t = i18n.t;
		const userColumns = [
			{
				sort: true,
				align: "center",
				dataField: "name",
				headerAlign: "center",
				text: t("name"),
				style: (cell, row) => {
					if (row.password.length < 8) {
						return { backgroundColor: "red" };
					}
					if (cell.length < 3) {
						return { color: "red" };
					}
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "username",
				headerAlign: "center",
				text: t("username"),
				style: (cell, row) => {
					if (row.password.length < 8) {
						return { backgroundColor: "red" };
					}
					if (cell.length < 3) {
						return { color: "red" };
					}
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "email",
				headerAlign: "center",
				text: t("email"),
				style: (cell, row) => {
					if (row.password.length < 8) {
						return { backgroundColor: "red" };
					}
					if (cell && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(cell)) {
						return { color: "red" };
					}
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "phoneNumber",
				headerAlign: "center",
				text: t("phoneNumber"),
				style: (cell, row) => {
					if (row.password.length < 8) {
						return { backgroundColor: "red" };
					}
					if (cell && cell.length < 11) {
						return { color: "red" };
					}
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "hrCode",
				headerAlign: "center",
				text: t("hrCode"),
				style: (cell, row) => {
					if (row.password.length < 8) {
						return { backgroundColor: "red" };
					}
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "licenseNumber",
				headerAlign: "center",
				text: t("license/idNumber"),
				style: (cell, row) => {
					if (row.password.length < 8) {
						return { backgroundColor: "red" };
					}
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "licenseExpiryDate",
				headerAlign: "center",
				text: t("license/idExpiryDate"),
				formatter: (cell) => {
					return moment(cell).format("YYYY-MM-DD");
				},
				style: (cell, row) => {
					if (row.password.length < 8) {
						return { backgroundColor: "red" };
					}
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "hiringDate",
				headerAlign: "center",
				text: t("hiringDate"),
				formatter: (cell) => {
					return moment(cell).format("YYYY-MM-DD");
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "licenseClassification",
				headerAlign: "center",
				text: t("licenseClassification"),
				formatter: (cell, row, index) => {
					if (!isNaN(cell)) {
						let licenseClassification = this.state.licenseClassifications.filter(
							(classification) => parseInt(classification.id) === parseInt(cell)
						)[0];
						return licenseClassification.name;
					} else {
						return cell;
					}
				},
				style: (cell, row) => {
					if (row.password.length < 8) {
						return { backgroundColor: "red" };
					}
				},
			},
		];

		const faildColumns = [
			{
				sort: true,
				align: "center",
				dataField: "driver",
				headerAlign: "center",
				text: t("username"),
			},
			{
				sort: true,
				align: "center",
				dataField: "error",
				headerAlign: "center",
				text: t("error"),
			},
		];

		return (
			<>
				<PageTitleAlt2
					heading={this.props.assisstant ? t("createAssisstants") : t("createDrivers")}
					icon="bi bi-people-fill icon-gradient bg-happy-fisher"
				/>
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row>
						<Col>
							<Row>
								<Col className="d-flex justify-content-evenly my-2">
									{!this.state.showDownloadBtn && !this.state.showUploadBtn && (
										<GetPermission perm="users.add_ops_driver">
											<Button code="perm" color="info" onClick={this.showDownloadBtn}>
												{t("createByTemplate")}
											</Button>
										</GetPermission>
									)}
									{this.state.showDownloadBtn && (
										<Button color="danger" onClick={this.downloadTemplate}>
											{t("downloadTemplate")}
										</Button>
									)}
									{this.state.showUploadBtn && (
										<Col xs="6" md="6">
											<Input
												bsSize="sm"
												type="file"
												color="dark"
												onClick={this.selectTemplate}
												placeholder={t("uploadDrivers")}
												onChange={this.uploadTemplate}
											/>
										</Col>
									)}
									<GetPermission perm="users.add_ops_driver">
										<Button code="perm" color="dark" className="mx-2" onClick={this.addDriver}>
											{this.props.assisstant ? t("newAssisstant") : t("newDriver")}
										</Button>
									</GetPermission>
								</Col>
								<Col className="d-flex justify-content-end my-2">
									<GetPermission perm="users.add_ops_driver">
										<Button code="perm" color="info" onClick={this.submit} disabled={this.state.drivers.length === 0}>
											{t("submit")}
										</Button>
									</GetPermission>
								</Col>
							</Row>
							{(this.state.showDownloadBtn || this.state.showUploadBtn) && (
								<Row>
									<Col xs="12" className="mb-2">
										<div className="card mb-3 widget-content bg-dark">
											<div className="widget-content-wrapper text-white">
												<div className="widget-content-left">
													<div className="widget-heading text-danger">{t("attention")}</div>
													<div>{t("attentionDateformat")}</div>
												</div>
											</div>
										</div>
									</Col>
								</Row>
							)}
							{this.state.newDrivers.length !== 0 && (
								<Row className="my-4">
									<Col>
										<Card>
											<CardBody>
												{this.state.newDrivers.map((newDriver, index) => (
													<Row className="my-3">
														<Col>
															<Form onSubmit={this.addToDrivers} onInvalid={this.validateNewDrivers} index={index}>
																<Card className="main-card mb-3">
																	<CardTitle className="d-flex px-3 justify-content-between">
																		<div>{index + 1}</div>
																		<div>
																			<DeleteIcon index={index} ondelete={this.removeDriver} />
																		</div>
																	</CardTitle>
																	<CardBody>
																		<Row className="my-1">
																			<Col xs="6" md="3" lg="3" className="my-1 d-flex">
																				<Label className="mx-1">{t("name")}</Label>
																				<Input
																					index={index}
																					required
																					bsSize="sm"
																					type="text"
																					onChange={this.getNewDrivers}
																					minLength={3}
																					name="name"
																					value={this.state.newDrivers[index]["name"] || ""}
																					invalid={this.state.newDrivers[index]["invalidName"]}
																				/>
																			</Col>
																			<Col xs="6" md="3" lg="3" className="my-1 d-flex">
																				<Label className="mx-1">{t("username")}</Label>
																				<Input
																					index={index}
																					required
																					bsSize="sm"
																					type="text"
																					onChange={this.getNewDrivers}
																					name="username"
																					minLength={3}
																					value={this.state.newDrivers[index]["username"] || ""}
																					invalid={this.state.newDrivers[index]["invalidUsername"]}
																				/>
																			</Col>
																			<Col xs="6" md="3" lg="3" className="my-1 d-flex">
																				<Label className="mx-1">{t("phoneNumber")}</Label>
																				<Input
																					index={index}
																					bsSize="sm"
																					type="text"
																					onChange={this.getNewDrivers}
																					name="phoneNumber"
																					minLength={11}
																					value={this.state.newDrivers[index]["phoneNumber"] || ""}
																					invalid={this.state.newDrivers[index]["invalidPhoneNumber"]}
																				/>
																			</Col>
																			<Col xs="6" md="2" lg="2" className="my-1 d-flex">
																				<Label className="mx-1">{t("hrCode")}</Label>
																				<Input
																					index={index}
																					bsSize="sm"
																					type="text"
																					required
																					onChange={this.getNewDrivers}
																					name="hrCode"
																					value={this.state.newDrivers[index]["hrCode"] || ""}
																					invalid={this.state.newDrivers[index]["invalidHrCode"]}
																				/>
																			</Col>
																			<Col xs="6" md="3" lg="3" className="my-1 d-flex">
																				<Label className="mx-1">{t("email")}</Label>
																				<Input
																					index={index}
																					bsSize="sm"
																					type="email"
																					onChange={this.getNewDrivers}
																					name="email"
																					value={this.state.newDrivers[index]["email"] || ""}
																					invalid={this.state.newDrivers[index]["invalidEmail"]}
																				/>
																			</Col>
																			<Col xs="6" md="3" lg="3" className="my-1 d-flex">
																				<Label className="mx-1">{t("password")}</Label>
																				<Input
																					index={index}
																					required
																					bsSize="sm"
																					type="password"
																					onChange={this.getNewDrivers}
																					name="password"
																					minLength={8}
																					value={this.state.newDrivers[index]["password"] || ""}
																					invalid={this.state.newDrivers[index]["invalidPassword"]}
																				/>
																			</Col>
																			<Col xs="6" md="3" lg="3" className="my-1 d-flex">
																				<Label className="mx-1">{t("license/idNumber")}</Label>
																				<Input
																					index={index}
																					required
																					bsSize="sm"
																					type="text"
																					onChange={this.getNewDrivers}
																					name="licenseNumber"
																					value={this.state.newDrivers[index]["licenseNumber"] || ""}
																					invalid={this.state.newDrivers[index]["invalidLicenseNumber"]}
																				/>
																			</Col>
																			<Col xs="6" md="3" lg="3" className="my-1 d-flex">
																				<Label className="mx-1">{t("license/idExpiryDate")}</Label>
																				<Input
																					index={index}
																					required
																					bsSize="sm"
																					type="date"
																					onChange={this.getNewDrivers}
																					name="licenseExpiryDate"
																					value={
																						moment(this.state.newDrivers[index]["licenseExpiryDate"]).format("YYYY-MM-DD") ||
																						moment().format("YYYY-MM-DD")
																					}
																					invalid={this.state.newDrivers[index]["invalidLicenseExpiryDate"]}
																				/>
																			</Col>
																			<Col xs="6" md="3" lg="3" className="my-1 d-flex">
																				<Label className="mx-1">{t("hiringDate")}</Label>
																				<Input
																					bsSize="sm"
																					type="date"
																					index={index}
																					name="hiringDate"
																					onChange={this.getNewDrivers}
																					value={
																						moment(this.state.newDrivers[index]["hiringDate"]).format("YYYY-MM-DD") ||
																						moment().format("YYYY-MM-DD")
																					}
																				/>
																			</Col>
																			<Col xs="6" md="3" lg="3" className="my-1 d-flex">
																				<Label className="mx-1">{t("licenseClassification")}</Label>
																				<Selector
																					name={index}
																					required
																					isObjectOption
																					options={this.state.licenseClassifications}
																					optionAccessor="name"
																					onChange={(e, v) => this.getLicenseClassificationValue(e, v, index)}
																					id="licenseClassification"
																					value={this.state.newDrivers[index]["licenseClassificationObj"] || { name: "" }}
																					error={this.state.newDrivers[index]["invalidLicenseClassification"]}
																				/>
																			</Col>
																		</Row>
																	</CardBody>
																	<CardFooter>
																		<Col className="d-flex justify-content-end my-1">
																			<Button color="info" type="submit">
																				{t("add")}
																			</Button>
																		</Col>
																	</CardFooter>
																</Card>
															</Form>
														</Col>
													</Row>
												))}
											</CardBody>
										</Card>
									</Col>
								</Row>
							)}
							{this.state.faild.length === 0 && (
								<Row>
									<Col>
										<Card>
											<CardBody>
												<Row>
													<Col>
														<TableWithPaginationSearch
															scrollable
															keyfield="hrCode"
															data={this.state.drivers}
															columns={userColumns}
														/>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
							)}
							{this.state.faild.length !== 0 && (
								<Row className="mt-4">
									<Col>
										<Card>
											<CardBody>
												<Row>
													<Col>
														<TableWithPaginationSearch
															scrollable
															exportable
															filename="failedDrivers.csv"
															keyfield="username"
															data={this.state.faild}
															columns={faildColumns}
														/>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
							)}
						</Col>
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}

export default BulkCreateDriver;
