import i18n from "i18n";
import React, { Component } from "react";
import ExpenseCard from "./expense_card";
import { CardHeader, Card, Collapse, CardBody, Button } from "reactstrap";

class BillingAccordion extends Component {
	constructor() {
		super();
		this.state = { accordion: false };
	}

	toggleAccordion = () => {
		this.setState({ accordion: !this.state.accordion });
	};

	render() {
		const t = i18n.t;
		return (
			<>
				<Card className={`pt-0 pb-0 mt-4 bg-transparent shadow-none`}>
					<CardHeader
						className={`mb-3 h-auto pl-0 pr-0   ${this.state.accordion ? "bg-transparent" : "bg-white"}  border-0 rounded`}
						id="headingOne">
						<Button
							block
							color="link"
							style={{ cursor: "pointer" }}
							className={`text-left m-0 p-0  text-decoration-none ${this.state.accordion ? "text-dark" : ""}`}
							onClick={() => this.toggleAccordion()}
							aria-expanded={this.state.accordion}>
							<div
								className={`card-header-title fsize-2 text-capitalize font-weight-normal ${this.state.accordion ? "" : "p-3"}`}>
								{t("billing")}
							</div>
						</Button>
					</CardHeader>
					<Collapse isOpen={this.state.accordion} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
						<CardBody>
							<ExpenseCard data={this.props.data} />
						</CardBody>
					</Collapse>
				</Card>
			</>
		);
	}
}

export default BillingAccordion;
