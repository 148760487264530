import axios from "axios";
import i18n from "i18n";
import Loader from "react-loaders";
import { Col, Row } from "reactstrap";
import React, { Component } from "react";
import SiteOwnerForm from "./site_owner_form";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import { AlertNotification } from "ops/common/alert_notification";

class NewSiteOwner extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			newOwner: {},
		};
	}

	validate = (e) => {
		let target = e.target.id;
		let newOwner = { ...this.state.newOwner };
		newOwner[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		this.setState({ newOwner: newOwner });
	};

	getValue = (e) => {
		let newOwner = { ...this.state.newOwner };
		let target = e.target.id;
		let value = e.target.value;
		if (e.target.type === "checkbox") {
			let checked = e.target.checked;
			newOwner[target] = checked;
		}
		newOwner[target] = value;
		newOwner[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
		this.setState({ newOwner: newOwner });
	};

	submit = (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		axios
			.post(`company/?serializer=create`, this.state.newOwner)
			.then((res) => {
				if (res) {
					this.setState({ loading: false, newOwner: {} });
					AlertNotification(i18n.t("created"), "success");
				}
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	render() {
		const t = i18n.t;
		return (
			<>
				<PageTitleAlt2 heading={t("newSiteOwner/Customer")} icon="bi bi-building icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}
				>
					<Row className="m-3 justify-content-center">
						<Col xs="12">
							<SiteOwnerForm validate={this.validate} onSubmit={this.submit} getValue={this.getValue} newOwner={this.state.newOwner} />
						</Col>
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}

export default NewSiteOwner;
