import axios from "axios";
import i18n from "i18n";
import moment from "moment";
import { AlertNotification } from "ops/common/alert_notification";
import GetPermission from "ops/common/get_permission";
import NonPlannedWOForm from "ops/non_planned_wo/components/wo_form";
import React, { Component } from "react";
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay";
import { withRouter } from "react-router-dom";
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Swal from "sweetalert2";

class NewWOModal extends Component {
	constructor() {
		super();
		this.state = {
			wos: [],
			assets: [],
			drivers: [],
			project: {},
			loading: false,
			assistants: [],
			serviceType: [],
			attachedAssets: [],
		};
	}

	getAssets = () => {
		let commodityTypes = [];
		commodityTypes =
			this.props.project.commodity.length !== 0 &&
			this.props.project.commodity.map((commodity) => {
				return commodity.commodityType;
			});
		if (commodityTypes.length === 0) {
			AlertNotification(i18n.t("noCommodityOnProject"), "warning");
			return;
		}
		axios.get(`asset/getAvailableAssets/?commodityTypes=${commodityTypes}`).then((res) => {
			this.setState({ assets: res.data["assets"], attachedAssets: res.data["attachedAssets"] }, () => {
				this.setState({
					wos: this.state.wos.concat({
						project: this.props.project.id,
						trips: [],
						startDate: moment().format("YYYY-MM-DDTHH:mm"),
					}),
				});
			});
		});
	};

	getNewWOInitialData = () => {
		axios.get(`profile/?serializer=driver&user__is_active=true&license__driverStatus=Available&departments__name__in=${["Drivers"]}`).then((res) => {
			this.setState({ drivers: res.data });
		});
		axios
			.get(`profile/?serializer=driver&user__is_active=true&license__driverStatus=Available&departments__name__in=${["Driver Assistants"]}`)
			.then((res) => {
				this.setState({ assisstants: res.data });
			});

		this.getAssets();

		axios.get(`servicetype`).then((res) => {
			this.setState({ serviceType: res.data });
		});
	};

	addWO = () => {
		if (this.state.wos.length === 0) {
			this.getNewWOInitialData();
		}
		this.setState({
			wos: this.state.wos.concat({
				project: this.props.project.id,
				trips: [],
				startDate: moment().format("YYYY-MM-DDTHH:mm"),
			}),
		});
	};

	removeWO = (e) => {
		let projectWOs = [...this.state.wos];
		projectWOs.splice(e.target.getAttribute("index"), 1);
		this.setState({ wos: projectWOs });
	};

	getWOValues = (e, v, woIndex) => {
		let value;
		let target;
		let wos = this.state.wos;
		let index;
		if (v) {
			index = woIndex;
			target = e.target.id.split("-")[0];
			value = v.id;
			if (target === "driver" || target === "assisstant") value = v.user;
			wos[index][target] = value;
			wos[index][`${target}Object`] = v;
			wos[index][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
			if (target === "asset") wos[index]["startKM"] = v.lastGaugeReading;
			this.setState({ wos: wos });
		} else {
			index = e.target.getAttribute("index");
			target = e.target.id;
			value = e.target.value;
			wos[index][target] = value;
			wos[index][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
			if (target === "endKM" && wos[index]["startKM"] && value < wos[index]["startKM"]) {
				wos[index][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
			}
			this.setState({ wos: wos });
		}
	};

	validate = (e) => {
		let targetID = e.target.id.split("-")[0];
		let index = e.target.getAttribute("name");
		let wos = this.state.wos;
		wos[index][`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = true;
		this.setState({ wos: wos });
	};

	showAlert = () => {
		Swal.fire({
			icon: "success",
			buttonsStyling: false,
			showCancelButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			cancelButtonText: i18n.t("no"),
			title: i18n.t("created"),
			text: i18n.t("doYouWantToMoveToProject"),
			confirmButtonText: i18n.t("ok"),
			customClass: {
				confirmButton: "btn btn-info",
				cancelButton: "btn btn-dark ml-3",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				this.props.history.push({
					pathname: "/viewproject",
					projectID: this.props.project.id,
				});
			}
		});
	};

	submitWOs = (e) => {
		e.preventDefault();
		let wos = [...this.state.wos];
		this.setState({ loading: true });
		axios
			.post(`workorder/`, wos)
			.then((res) => {
				this.setState({ loading: false });
				if (res) {
					this.setState({ wos: [] });
					AlertNotification(i18n.t("submit"), "success");
					this.props.toggle();
					this.showAlert();
				}
			})
			.catch((err) => {
				this.setState({ loading: false });
				if (err.response) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	componentDidMount() {
		if (this.props.project) {
			this.getNewWOInitialData();
		}
	}

	render() {
		const t = i18n.t;
		const { toggle, isOpen } = this.props;
		return (
			<LoadingOverlay
				tag="div"
				styles={{
					overlay: (base) => ({
						...base,
						background: "#fff",
						opacity: 0.5,
					}),
				}}
				active={this.state.loading}
				spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}
			>
				<Modal unmountOnClose scrollable isOpen={isOpen} toggle={toggle}>
					<ModalHeader className="bg-primary text-white  justify-content-center  ">
						<h4 className="text-center">{this.props.project.code}</h4>
						<h4 className="text-center">{t("workOrders")}</h4>
					</ModalHeader>
					<ModalBody>
						<Form id="WOForm" onInvalid={this.validate} onSubmit={this.submitWOs}>
							<Row hidden={this.state.wos.length === 0}>
								{this.state.wos.map((wo, index) => {
									return (
										<Col xs="12" key={index}>
											<NonPlannedWOForm
												index={index}
												selectedWO={wo}
												onDelete={this.removeWO}
												getValue={this.getWOValues}
												drivers={this.state.drivers}
												assisstants={this.state.assistants}
												assets={this.state.assets}
												attachedAssets={this.state.attachedAssets}
												serviceType={this.state.serviceType}
											/>
										</Col>
									);
								})}
							</Row>
						</Form>
					</ModalBody>

					<ModalFooter>
						<Button color="dark" onClick={toggle}>
							{t("cancel")}
						</Button>
						<GetPermission perm="ops.add_workorder">
							<div code="perm" className="d-flex">
								<Button className="mr-2" color="info" onClick={this.addWO}>
									{t("addWO")}
								</Button>
								<Button color="info" form="WOForm" type="submit">
									{t("submit")}
								</Button>
							</div>
						</GetPermission>
					</ModalFooter>
				</Modal>
			</LoadingOverlay>
		);
	}
}

export default withRouter(NewWOModal);
