import React, { Component } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import BulkCreateDriver from "../components/bulk_create_driver";

class BulkCreateDriverScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<BulkCreateDriver />
			</CSSTransitionGroup>
		);
	}
}

export default BulkCreateDriverScene;
