import i18n from "i18n";
import React, { Component } from "react";
import GetPermission from "ops/common/get_permission";
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

class SiteOwnerForm extends Component {
	render() {
		const t = i18n.t;
		const { validate, onSubmit, getValue, newOwner } = this.props;
		return (
			<>
				<Row>
					<Col xs="12">
						<Card className="main-card mb-3">
							<CardBody>
								<CardTitle className="d-flex justify-content-between">
									<div>{t("newSiteOwner/Customer")}</div>
								</CardTitle>
								<Form onInvalid={validate} onSubmit={onSubmit}>
									<Row>
										<Col xs="12">
											<FormGroup>
												<Label>{t("fullName")}</Label>
												<Input
													required
													type="text"
													step={0.01}
													id="fullName"
													onChange={getValue}
													value={newOwner["fullName"] ? newOwner["fullName"] : ""}
													invalid={newOwner["invalidFullName"]}
												/>
											</FormGroup>
										</Col>
										<Col xs="12">
											<FormGroup check>
												<Label check className="mx-1">
													<Input
														type="checkbox"
														id="isSiteOwner"
														onChange={getValue}
														defaultChecked={newOwner["isSiteOwner"] !== undefined ? newOwner["isSiteOwner"] : false}
														invalid={newOwner["invalidIsSiteOwner"]}
													/>
													{t("isSiteOwner")}
												</Label>
											</FormGroup>
										</Col>
										<Col xs="12" className="d-flex justify-content-end">
											<GetPermission perm="users.add_company">
												<Button code="perm" type="submit" color="info">
													{t("submit")}
												</Button>
											</GetPermission>
										</Col>
									</Row>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</>
		);
	}
}
export default SiteOwnerForm;
