import i18n from "i18n";
import React, { Component } from "react";
import Selector from "ops/common/selector";
import GetPermission from "ops/common/get_permission";
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

class StandardForm extends Component {
	render() {
		const t = i18n.t;
		const { getValue, validate, uoms, commodities, getCommodities, onSubmit, commodityTypes, legs, newStandard } = this.props;
		return (
			<Row>
				<Col xs="12">
					<Card className="main-card mb-3">
						<CardBody>
							<CardTitle className="d-flex justify-content-between">
								<div>{t("newStandard")}</div>
							</CardTitle>
							<Form onInvalid={validate} onSubmit={onSubmit}>
								<Row>
									<Col xs="12">
										<FormGroup>
											<Label>{t("name")}</Label>
											<Input
												required
												type="text"
												step={0.01}
												id="name"
												onChange={getValue}
												invalid={newStandard["invalidName"]}
											/>
										</FormGroup>
									</Col>
									<Col xs="12">
										<FormGroup>
											<Label>{t("leg")}</Label>
											<Selector
												required
												id="leg"
												size="small"
												isObjectOption
												optionAccessor="name"
												options={legs}
												onChange={getValue}
												error={newStandard["invalidLeg"]}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" md="4">
										<FormGroup>
											<Label>{t("tripQuantity")}</Label>
											<Input
												type="number"
												step={0.01}
												id="tripQuantity"
												onChange={getValue}
												invalid={newStandard["invalidTripQuantity"]}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" md="4">
										<FormGroup>
											<Label>{t("uom")}</Label>
											<Selector
												id="uom"
												isObjectOption
												optionAccessor="fullName"
												options={uoms}
												onChange={getValue}
												required
												error={newStandard["invalidUom"]}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" md="4">
										<FormGroup>
											<Label>{t("commodityType")}</Label>
											<Selector
												required
												id="commodityType"
												isObjectOption
												optionAccessor="name"
												options={commodityTypes}
												onChange={(e, v) => getCommodities(e, v)}
												error={newStandard["invalidCommodityType"]}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" md="4">
										<FormGroup>
											<Label>{t("commodity")}</Label>
											<Selector
												required
												id="commodity"
												isObjectOption
												optionAccessor="name"
												options={commodities}
												onChange={getValue}
												error={newStandard["invalidCommodity"]}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" md="4">
										<FormGroup>
											<Label>{t("averageSpeed")}</Label>
											<Input type="number" step={0.01} id="averageSpeed" onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" md="4">
										<FormGroup>
											<Label>{t("fuelRate")}</Label>
											<Input type="number" step={0.01} id="fuelRate" onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" md="4">
										<FormGroup>
											<Label>{t("tolls")}</Label>
											<Input id="tolls" type="number" step={0.01} onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" md="3">
										<FormGroup>
											<Label>{t("scales")}</Label>
											<Input id="scales" type="number" step={0.01} onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" md="3">
										<FormGroup>
											<Label>{t("tips")}</Label>
											<Input id="tips" type="number" step={0.01} onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" md="3">
										<FormGroup>
											<Label>{t("driverBonus")}</Label>
											<Input id="driverBonus" type="number" step={0.01} onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" md="3">
										<FormGroup>
											<Label>{t("driverAllowance")}</Label>
											<Input type="number" step={0.01} id="driverAllowance" onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" md="3">
										<FormGroup>
											<Label>{t("driverAsstAllowance")}</Label>
											<Input type="number" step={0.01} id="driverAsstAllowance" onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" md="6">
										<FormGroup>
											<Label>{t("loadingTime") + " (minutes)"}</Label>
											<Input type="number" step={0.01} id="loadingTime" onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" md="6">
										<FormGroup>
											<Label>{t("dischargingTime") + " (minutes)"}</Label>
											<Input type="number" step={0.01} id="dischargingTime" onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" md="6">
										<FormGroup>
											<Label>{t("stoppage") + " (minutes)"}</Label>
											<Input type="number" step={0.01} id="stoppage" onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" md="6">
										<FormGroup>
											<Label>{t("drivingRestingTime") + " (minutes)"}</Label>
											<Input type="number" step={0.01} id="drivingRestingTime" onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" md="6">
										<FormGroup>
											<Label>{t("tripTime") + " (minutes)"}</Label>
											<Input type="number" step={0.01} id="tripTime" onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" md="6">
										<FormGroup>
											<Label>{t("tripsPerDay")}</Label>
											<Input type="number" step={0.01} id="tripsPerDay" onChange={getValue} />
										</FormGroup>
									</Col>
									<Col xs="12" className="d-flex justify-content-end">
										<GetPermission perm="ops.add_standard">
											<Button code="perm" type="submit" color="info">
												{t("submit")}
											</Button>
										</GetPermission>
									</Col>
								</Row>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}
export default StandardForm;
