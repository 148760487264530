import React, { Component } from "react";
import NewSiteOwner from "../components/new_site_owner";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class NewSiteOwnerScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<NewSiteOwner />
			</CSSTransitionGroup>
		);
	}
}

export default NewSiteOwnerScene;
