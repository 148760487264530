import React, { Component } from "react";
import ListStandard from "../components/list_standard";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class ListStandardScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<ListStandard />
			</CSSTransitionGroup>
		);
	}
}

export default ListStandardScene;
