import React, { Component } from "react";
import { Card, CardBody, ListGroup, ListGroupItem } from "reactstrap";

class ContextMenu extends Component {
	render() {
		const { screenPositionX, screenPositionY, coordinates, addLoactionCoordinates } = this.props;
		return (
			<Card
				className="contxt-menu"
				style={{
					top: `${screenPositionY}px`,
					left: `${screenPositionX}px`,
				}}>
				<CardBody>
					<ListGroup>
						<ListGroupItem onClick={() => addLoactionCoordinates(coordinates)}>{coordinates}</ListGroupItem>
					</ListGroup>
				</CardBody>
			</Card>
		);
	}
}

export default ContextMenu;
