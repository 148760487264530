import React from "react";
import UploadGPSData from "../components/upload_gps_data";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class UploadGPSDataScene extends React.Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<UploadGPSData />
			</CSSTransitionGroup>
		);
	}
}
export default UploadGPSDataScene;
