import axios from "axios";
import i18n, { t } from "i18next";
import { withRouter } from "react-router-dom";
import React, { Component, Fragment } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import DateRangePicker from "../../common/date_range_picker";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";

class ViewTripList extends Component {
	constructor() {
		super();
		this.state = {
			data: [],
			loading: false,
		};
	}

	getTrips = (sDate, eDate) => {
		this.setState({ loading: true });
		axios.get(`trip/gettripsdata/?startDate=${sDate}&endDate=${eDate}`).then((res) => {
			this.setState({
				data: res.data,
				loading: false,
			});
		});
	};

	render() {
		const columns = [
			{
				sort: true,
				align: "center",
				dataField: "wo",
				text: i18n.t("wo"),
				headerAlign: "center",
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "numOfTrips",
				text: i18n.t("numOfTrips"),
			},
			{
				sort: true,
				align: "center",
				dataField: "tripID",
				headerAlign: "center",
				text: i18n.t("tripID"),
				formatter: (cell, row) => {
					return `${row.wo}-${row.tripNumber}`;
				},
				csvFormatter: (cell, row) => {
					return `${row.wo}-${row.tripNumber}`;
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "loadingDate",
				text: i18n.t("loadingDate"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "unLoadingDate",
				text: i18n.t("unLoadingDate"),
			},
			{
				sort: true,
				align: "center",
				dataField: "woStart",
				headerAlign: "center",
				text: i18n.t("woStart"),
			},
			{
				sort: true,
				align: "center",
				dataField: "woEnd",
				headerAlign: "center",
				text: i18n.t("woEnd"),
			},
			{
				sort: true,
				align: "center",
				dataField: "driver",
				headerAlign: "center",
				text: i18n.t("driver"),
			},
			{
				sort: true,
				align: "center",
				dataField: "asset",
				headerAlign: "center",
				text: i18n.t("asset"),
			},
			{
				sort: true,
				align: "center",
				dataField: "leg",
				text: i18n.t("leg"),
				headerAlign: "center",
			},
		];

		return (
			<Fragment>
				<PageTitleAlt2 heading={i18n.t("trips")} icon="bi bi-kanban icon-gradient bg-happy-fisher" />
				<Row className="mb-3">
					<Col xs="6" sm="6" md="6" lg="6" xl="6">
						<DateRangePicker startDate={t("loadingDateStart")} endDate={t("loadingDateEnd")} getdata={this.getTrips} />
					</Col>
				</Row>
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<Card className="main-card mb-3" key="0">
							<CardBody>
								<TableWithPaginationSearch
									scrollable
									search
									exportable
									keyfield="tripID"
									columns={columns}
									filename="trips.csv"
									data={this.state.data}
									loading={this.state.loading}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default withRouter(ViewTripList);
