import XLSX from "xlsx";
import axios from "axios";
import React from "react";
import moment from "moment";
import Loader from "react-loaders";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import { AlertNotification } from "ops/common/alert_notification";
import { Col, Row, Card, CardBody, Input, Button, CardHeader } from "reactstrap";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";

class UploadGPSData extends React.Component {
	constructor() {
		super();
		this.state = {
			trips: [],
			stoppages: [],
			inValidTrips: [],
			inValidStoppages: [],
			submitting: false,
		};
	}

	selectTemplate = (e) => {
		e.target.value = null;
		this.setState({ inValidTrips: [], trips: [], stoppages: [], inValidStoppages: [] });
	};

	uploadTemplate = (evt) => {
		let file = new FileReader();
		file.onload = (e) => {
			let data = e.target.result;
			let workbook = XLSX.read(data, { type: "binary" });
			let trips = XLSX.utils.sheet_to_json(workbook.Sheets["Trips"], { raw: false });
			let stoppages = XLSX.utils.sheet_to_json(workbook.Sheets["Stoppages"], { raw: false });
			this.prepareTrips(trips);
			this.prepareStoppages(stoppages);
		};
		if (evt.target.files.length > 0) file.readAsBinaryString(evt.target.files[0]);
	};

	prepareTrips = (data) => {
		this.setState({ submitting: true });
		let verifiedData = [];
		let inValidData = [];
		data.forEach((trip) => {
			let woEDate = moment(trip["WO-End time"], "DD-MM-YYYY hh:mm");
			let woSDate = moment(trip["WO-Start time"], "DD-MM-YYYY hh:mm");
			let loadingIn = moment(trip["Loading In"], "DD-MM-YYYY hh:mm");
			let loadingOut = moment(trip["Loading Out"], "DD-MM-YYYY hh:mm");
			let unLoadingIn = moment(trip["Discharging In"], "DD-MM-YYYY hh:mm");
			let unLoadingOut = moment(trip["Discharging Out"], "DD-MM-YYYY hh:mm");
			let loadingArrival = moment(trip["Arrival Loading"], "DD-MM-YYYY hh:mm");
			let unLoadingArrival = moment(trip["Discharging Arrival"], "DD-MM-YYYY hh:mm");
			if (!trip["Trip ID"]) inValidData.push({ trip: "", error: "No trip id" });
			if (
				woSDate.isValid() &&
				woEDate.isValid() &&
				loadingIn.isValid() &&
				loadingOut.isValid() &&
				unLoadingIn.isValid() &&
				unLoadingOut.isValid() &&
				loadingArrival.isValid() &&
				unLoadingArrival.isValid()
			) {
				let verifiedDates = true;
				let dates = [
					woSDate.format("YYYY-MM-DD HH:mm"),
					loadingArrival.format("YYYY-MM-DD HH:mm"),
					loadingIn.format("YYYY-MM-DD HH:mm"),
					loadingOut.format("YYYY-MM-DD HH:mm"),
					unLoadingArrival.format("YYYY-MM-DD HH:mm"),
					unLoadingIn.format("YYYY-MM-DD HH:mm"),
					unLoadingOut.format("YYYY-MM-DD HH:mm"),
					woEDate.format("YYYY-MM-DD HH:mm"),
				];
				for (let i = 0; i <= 6; i++) {
					if (dates[i] > dates[i + 1]) {
						verifiedDates = false;
						inValidData.push({ trip: trip["Trip ID"], error: `${dates[i]} greater than ${dates[i + 1]}` });
						break;
					}
				}
				if (verifiedDates) {
					verifiedData.push({
						trip: trip["Trip ID"],
						loadingIn: dates[2],
						loadingOut: dates[3],
						unLoadingIn: dates[5],
						unLoadingOut: dates[6],
						loadingArrival: dates[1],
						unLoadingArrival: dates[4],
					});
				}
			} else inValidData.push({ trip: trip["Trip ID"], error: "Invalid date format" });
		});

		this.setState({ trips: verifiedData, inValidTrips: inValidData, submitting: false });
	};

	prepareStoppages = (data) => {
		this.setState({ submitting: true });
		let verifiedData = [];
		let inValidData = [];

		data.forEach((trip) => {
			let to = moment(trip["To"], "DD-MM-YYYY hh:mm");
			let from = moment(trip["From"], "DD-MM-YYYY hh:mm");
			let woEDate = moment(trip["WO end time"], "DD-MM-YYYY hh:mm");
			let woSDate = moment(trip["WO start time"], "DD-MM-YYYY hh:mm");

			if (!trip["Trip ID"]) inValidData.push({ trip: "", error: "No trip id" });
			if (woSDate.isValid() && woEDate.isValid() && from.isValid() && to.isValid()) {
				let verifiedDates = true;
				let dates = [
					woSDate.format("YYYY-MM-DD HH:mm"),
					from.format("YYYY-MM-DD HH:mm"),
					to.format("YYYY-MM-DD HH:mm"),
					woEDate.format("YYYY-MM-DD HH:mm"),
				];
				for (let i = 0; i <= 2; i++) {
					if (dates[i] > dates[i + 1]) {
						verifiedDates = false;
						inValidData.push({ trip: trip["Trip ID"], error: `${dates[i]} greater than ${dates[i + 1]}` });
						break;
					}
				}
				if (verifiedDates) {
					verifiedData.push({
						toTime: dates[2],
						fromTime: dates[1],
						trip: trip["Trip ID"],
						stopType: trip["Segment"],
						stopReason: trip["Reason"],
					});
				}
			} else inValidData.push({ trip: trip["Trip ID"], error: "Invalid date format" });
		});

		this.setState({ inValidStoppages: inValidData, stoppages: verifiedData, submitting: false });
	};

	submit = (data, inValidData, url) => {
		if (this.state[data].length === 0) return;
		if (this.state[inValidData].length > 0) return;
		let t = this.props.t;
		this.setState({ submitting: true });
		axios
			.post(url, this.state[data])
			.then((res) => {
				if (res) AlertNotification(t(`addedSuccessfully`), "success");
				this.setState({ submitting: false });
			})
			.catch((err) => {
				if (err) {
					console.error(err.response.data);
					this.setState({ submitting: false });
					AlertNotification(t(`failedToSubmit${data}`), "error");
				}
			});
	};

	render() {
		const { t } = this.props;
		const headerFormatter = (column) => <span>{t(column.text)}</span>;
		const columns = [
			{
				text: "Trip ID",
				dataField: "trip",
				headerFormatter: headerFormatter,
			},
			{
				text: "Error",
				dataField: "error",
				headerFormatter: headerFormatter,
			},
		];
		return (
			<LoadingOverlay
				tag="div"
				active={this.state.submitting}
				styles={{
					overlay: (base) => ({
						...base,
						background: "#fff",
						opacity: 0.5,
					}),
				}}
				spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" xl="12" className="mb-2">
						<PageTitleAlt2 heading={t("uploadGPSData")} icon="header-icon pe-7s-note2 icon-gradient bg-happy-fisher" />
					</Col>
					<Col xs="12" sm="12" md="12" lg="12" xl="12" className="mb-2">
						<div className="card mb-3 widget-content bg-dark">
							<div className="widget-content-wrapper text-white">
								<div className="widget-content-left">
									<div className="widget-heading text-danger">{t("attention")}</div>
									<div>{t("uploadGPSSheetsNames")}</div>
									<div>{t("dateFormat")}</div>
									<div>{t("uploadGPSStoppagesCol")}</div>
									<div>{t("uploadGPSTripsCol")}</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xs="12" md="3" className="mb-2 ">
						<Input type="file" onClick={this.selectTemplate} onChange={this.uploadTemplate} />
					</Col>
					<Col xs="12" className="mb-2 d-flex">
						<Button
							color="info"
							onClick={() => {
								this.submit("trips", "inValidTrips", "tripgpsdata/");
							}}
							className="mt-1 mr-2">
							{t("submitTrips")}
						</Button>
						<Button
							color="info"
							onClick={() => {
								this.submit("stoppages", "inValidStoppages", "stoppages/");
							}}
							className="mt-1">
							{t("submitStoppages")}
						</Button>
					</Col>
					<Col xs="12" className="mb-2" hidden={this.state.inValidTrips.length === 0}>
						<Card>
							<CardHeader className="card-header-tab">
								<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
									<i className="header-icon pe-7s-note2 icon-gradient bg-malibu-beach"> </i>
									{t("inValidTrips")}
								</div>
							</CardHeader>
							<CardBody>
								<TableWithPaginationSearch scrollable keyfield="trip" data={this.state.inValidTrips} columns={columns} />
							</CardBody>
						</Card>
					</Col>
					<Col xs="12" sm="12" md="12" lg="12" xl="12" hidden={this.state.inValidStoppages.length === 0}>
						<Card>
							<CardHeader className="card-header-tab">
								<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
									<i className="header-icon pe-7s-note2 icon-gradient bg-malibu-beach"> </i>
									{t("inValidStoppages")}
								</div>
							</CardHeader>
							<CardBody>
								<TableWithPaginationSearch scrollable keyfield="trip" data={this.state.inValidStoppages} columns={columns} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</LoadingOverlay>
		);
	}
}
export default withTranslation()(UploadGPSData);
