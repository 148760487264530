import i18n from "i18n";
import axios from "axios";
import { Loader } from "react-loaders";
import Selector from "ops/common/selector";
import calculate from "ops/helper/costing";
import { withRouter } from "react-router-dom";
import DeleteIcon from "ops/common/delete_icon";
import React, { Component, Fragment } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Input as Input2 } from "@material-ui/core";
import GetPermission from "ops/common/get_permission";
import { AlertNotification } from "ops/common/alert_notification";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";
import { Form, Label, Row, Col, Card, CardBody, CardFooter, Input, Button } from "reactstrap";

class CptCosting extends Component {
	constructor(props) {
		super(props);
		const { name, description } = this.props;
		this.state = {
			formLoading: false,
			trips: [
				{
					number: "1",
					roundTripKM: "0",
					payload: "0",
					loadingDischargingTime: "0",
					fuelRate: "0",
					maintCostPerKM: "0",
					roadScalesPerTrip: "0",
					roadTollsPerTrip: "0",
					portScalesPerTrip: "0",
					portPermitsPerTrip: "0",
					VATInsideThePortPerTrip: "0",
					tipsPerTrip: "0",
					otherExp: "0",
					finalAgreedPrice: "0",
				},
			],
			costing: {
				name: name,
				description: description,
				type: "CPT",
				workingHoursPerDay: "",
				fixedSellingPricePermonth: "",
				VAT: "",
				workingDaysPerMonth: "",
				driverIncomePerDay: "",
				fuelPrice: "",
				profitMargin: "",
				cpttrips: [],
			},
			fixedCostPerMonthIncludingVAT: 0,
			fixedCostPerDay: 0,
			companies: [],
			selectedCustomer: { fullName: "" },
			legs: [],
			updateView: false,
			tableLegsIsViewed: false,
		};
	}

	getCosting = (costingID) => {
		if (costingID) {
			axios.get(`costing/${costingID}/?serializer=get`).then((res) => {
				this.setState(
					{
						costing: res.data,
					},
					() => {
						this.calculateFixedCostPerMonthIncludingVAT();
						this.setState({ selectedCustomer: this.state.costing.customerName, trips: this.state.costing.cpttrips }, () => {
							this.recalculateAllTrips(this.state.trips);
						});
					}
				);
			});
		}
	};

	getValue = (e) => {
		this.setState({ tableLegsIsViewed: false });
		let value = e.target.value;
		let updatedCosting = this.state.costing;
		updatedCosting[e.target.id] = value;
		updatedCosting[`invalid${e.target.id.charAt(0).toUpperCase()}${e.target.id.slice(1, e.target.id.length)}`] = false;
		this.setState({ costing: updatedCosting }, () => {
			this.calculateFixedCostPerMonthIncludingVAT();
		});
	};

	calculateFixedCostPerMonthIncludingVAT = () => {
		let calculatedFixedCostPerMonthIncludingVAT =
			this.state.costing.fixedSellingPricePermonth * (1 + this.state.costing.VAT / 100);
		this.setState({ fixedCostPerMonthIncludingVAT: calculatedFixedCostPerMonthIncludingVAT }, () => {
			this.calculateFixedCostPerDay();
		});
	};

	calculateFixedCostPerDay = () => {
		let calculatedFixedCostPerDay = this.state.fixedCostPerMonthIncludingVAT / this.state.costing.workingDaysPerMonth;
		this.setState({ fixedCostPerDay: calculatedFixedCostPerDay }, () => {
			this.recalculateAllTrips(this.state.trips);
		});
	};

	getLegs = () => {
		axios
			.get(`leg/?serializer=getid`)
			.then((res) => {
				if (res) {
					this.setState({ legs: res.data });
				}
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				}
			});
	};

	getCompanies = () => {
		axios
			.get("company/")
			.then((res) => {
				if (res) {
					this.setState({ companies: res.data });
				}
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				}
			});
	};

	getInitialData = async () => {
		await Promise.all([this.getCompanies(), this.getLegs()]);
		const costingID = new URLSearchParams(this.props.location.search).get("id");
		if (costingID) {
			this.setState({ tableLegsIsViewed: true, updateView: true });
		}
		this.getCosting(costingID);
	};

	getSelectedValue = (e, v) => {
		if (v) {
			let updatedCosting = { ...this.state.costing };
			let targetID = e.target.id.split("-")[0];
			updatedCosting[targetID] = v.id;
			updatedCosting[`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = false;
			this.setState({ costing: updatedCosting, selectedCustomer: v });
		}
	};

	getSelectedLeg = (e, v, index) => {
		if (v) {
			let updatedTrip = { ...this.state.trips[index] };
			let targetID = e.target.id.split("-")[0];
			updatedTrip[`${targetID}`] = v;
			let updatedArrayOfTrips = [...this.state.trips];
			updatedArrayOfTrips[index] = updatedTrip;
			this.setState({ trips: updatedArrayOfTrips });
		}
	};

	handleTrip = (e, index) => {
		let value = e.target.value;
		let targetID = e.target.id;
		let updatedTrip = { ...this.state.trips[index] };
		updatedTrip[targetID] = value;
		let updatedArrayOfTrips = [...this.state.trips];
		updatedArrayOfTrips[index] = updatedTrip;
		this.setState({ trips: updatedArrayOfTrips }, () => {
			let calculatedTrips = calculate("CPT", {
				index: index,
				trips: this.state.trips,
				workingHoursPerDay: parseFloat(this.state.costing.workingHoursPerDay),
				driverIncomePerDay: parseFloat(this.state.costing.driverIncomePerDay),
				fuelPrice: parseFloat(this.state.costing.fuelPrice),
				fixedCostPerDay: parseFloat(this.state.fixedCostPerDay),
				profitMargin: parseFloat(this.state.costing.profitMargin),
			});
			this.setState({ trips: calculatedTrips });
		});
	};

	recalculateAllTrips = async (allTrips) => {
		const { costing, fixedCostPerDay } = this.state;
		for (let i = 0; i < allTrips.length; i++) {
			let calculatedTrips = calculate("CPT", {
				index: i,
				trips: this.state.trips,
				workingHoursPerDay: parseFloat(costing.workingHoursPerDay),
				driverIncomePerDay: parseFloat(costing.driverIncomePerDay),
				fuelPrice: parseFloat(costing.fuelPrice),
				fixedCostPerDay: parseFloat(fixedCostPerDay),
				profitMargin: parseFloat(costing.profitMargin),
			});
			await new Promise((resolve) => {
				this.setState({ trips: calculatedTrips }, () => {
					resolve();
				});
			});
		}
	};

	validate = (e) => {
		e.preventDefault();
		let costing = this.state.costing;
		let targetID = e.target.id.split("-")[0];
		costing[`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = true;
		this.setState({ costing: costing });
	};

	handleBeforeUnload = (event) => {
		event.preventDefault();
		event.returnValue = "";
	};

	submit = (e) => {
		e.preventDefault();
		let newCosting = { ...this.state.costing };
		newCosting.cpttrips = this.state.trips;
		const newCPTTrips = newCosting.cpttrips.map((obj) => {
			return { ...obj, leg: obj.leg.id };
		});
		newCosting.cpttrips = newCPTTrips;
		window.addEventListener("beforeunload", this.handleBeforeUnload);
		this.setState({ costing: newCosting, formLoading: true }, () => {
			axios
				.post(`costing/`, this.state.costing)
				.then((res) => {
					if (res) {
						this.setState({ formLoading: false });
						AlertNotification(i18n.t("submitedSuccessfully"), "success");
						this.props.history.push({ pathname: "/listcostings/" });
					}
				})
				.catch((err) => {
					this.setState({ formLoading: false });
					if (err.response) {
						AlertNotification(null, "error", err.response.status);
						console.error(err.response);
					} else AlertNotification(null, "error");
				})
				.finally(() => {
					window.removeEventListener("beforeunload", this.handleBeforeUnload);
				});
		});
	};

	update = (e) => {
		e.preventDefault();
		let updatedCosting = { ...this.state.costing };
		if (updatedCosting.customerName.id) {
			updatedCosting.customerName = updatedCosting.customerName.id;
		}
		let newCPTTrips = [];
		let updatedCPTTrips = [];
		this.state.trips.forEach((cptTrip) => {
			cptTrip["leg_id"] = cptTrip.leg.id;
			delete cptTrip["leg"];
			delete cptTrip["calculated"];
			if (cptTrip.id) {
				updatedCPTTrips.push(cptTrip);
			} else {
				newCPTTrips.push(cptTrip);
			}
		});
		let data = { costing: updatedCosting, newCPTTrips: newCPTTrips, updatedCPTTrips: updatedCPTTrips };
		window.addEventListener("beforeunload", this.handleBeforeUnload);
		this.setState({ formLoading: true });
		axios
			.put(`costing/${this.state.costing.id}/update/`, data)
			.then((res) => {
				if (res) {
					this.setState({ formLoading: false });
					AlertNotification(i18n.t("updatedSuccessfully"), "success");
					this.props.history.push({ pathname: "/listcostings/" });
				}
			})
			.catch((err) => {
				this.setState({ formLoading: false });
				if (err.response) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			})
			.finally(() => {
				window.removeEventListener("beforeunload", this.handleBeforeUnload);
			});
	};

	showLegsTable = (e) => {
		e.preventDefault();
		this.setState({ tableLegsIsViewed: true });
	};

	addTrip = () => {
		const { trips } = this.state;
		let tripsLength = trips.length;
		let newTripNumber = parseInt(trips[tripsLength - 1].number) + 1;
		let costing = "";
		if (this.state.updateView) {
			costing = this.state.costing.id;
		}
		const alltrips = [
			...trips,
			{
				costing_id: costing,
				number: newTripNumber,
				roundTripKM: "0",
				payload: "0",
				loadingDischargingTime: "0",
				fuelRate: "0",
				maintCostPerKM: "0",
				roadScalesPerTrip: "0",
				roadTollsPerTrip: "0",
				portScalesPerTrip: "0",
				portPermitsPerTrip: "0",
				VATInsideThePortPerTrip: "0",
				tipsPerTrip: "0",
				otherExp: "0",
				finalAgreedPrice: "0",
			},
		];
		this.setState({ trips: alltrips });
	};

	deleteTrip = (e, index, id) => {
		const tripsCopy = [...this.state.trips];
		if (tripsCopy.length > 1) {
			if (tripsCopy[index].id) {
				axios
					.delete(`cpttrips/${id}/`)
					.then((res) => {
						if (res) {
							this.setState({ formLoading: false });
						}
					})
					.catch((err) => {
						this.setState({ formLoading: false });
						if (err.response) {
							AlertNotification(null, "error", err.response.status);
							console.error(err.response);
						} else AlertNotification(null, "error");
					});
			}
			tripsCopy.splice(index, 1);
			this.setState({ trips: tripsCopy });
		} else {
			AlertNotification(i18n.t("can'tDeleteLastOne"), "error");
		}
	};

	componentDidMount() {
		this.getInitialData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.name !== prevProps.name || this.props.description !== prevProps.description) {
			let updatedCosting = this.state.costing;
			updatedCosting.name = this.props.name;
			updatedCosting.description = this.props.description;
			this.setState({ costing: updatedCosting });
		}
	}

	render() {
		const t = i18n.t;
		const headerFormatter = (column) => <div style={{ width: "150px", minWidth: "fit-content", padding: 2 }}>{column.text}</div>;
		let legColumns = [
			{
				align: "center",
				headerAlign: "center",
				text: i18n.t("route"),
				dataField: "leg",
				headerFormatter: (column) => <div style={{ width: "350px", minWidth: "fit-content", padding: 2 }}>{column.text}</div>,
				formatter: (cell, leg, index) => {
					return (
						<Selector
							required
							id="leg"
							value={leg.leg}
							size="small"
							isObjectOption
							getOptionSelected={(option) => option.leg || ""}
							optionAccessor="name"
							onChange={(e, v) => this.getSelectedLeg(e, v, index)}
							name="leg"
							options={this.state.legs}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "roundTripKM",
				headerAlign: "center",
				text: i18n.t("roundTripKM"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							required
							type="number"
							min={0}
							fullWidth
							step={0.1}
							name="roundTripKM"
							id="roundTripKM"
							style={{
								color: leg.roundTripKM && !(leg.roundTripKM >= 0) && "red",
							}}
							defaultValue={cell}
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "payload",
				headerAlign: "center",
				text: i18n.t("payload"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							required
							type="number"
							min={0}
							fullWidth
							step={0.1}
							name="payload"
							id="payload"
							style={{
								color: leg.payload && !(leg.payload >= 0) && "red",
							}}
							defaultValue={cell}
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "averageSpeed",
				headerAlign: "center",
				text: i18n.t("averageSpeed"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							type="number"
							disabled
							value={leg.calculated ? (parseFloat(leg.calculated.roundTripKM) < 120 ? 35 : 50).toFixed(2) : "0.00"}
							style={{ color: "green" }}
							fullWidth
							step={0.1}
							name="averageSpeed"
							id="averageSpeed"
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "drivingTime",
				headerAlign: "center",
				text: i18n.t("drivingTime"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							value={leg.calculated ? leg.calculated.drivingTime.toFixed(2) : "0.00"}
							style={{ color: "green" }}
							type="number"
							step={0.1}
							name="drivingTime"
							id="drivingTime"
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "loadingDischargingTime",
				headerAlign: "center",
				text: i18n.t("loadingDischargingTime"),
				headerFormatter: (column) => <div style={{ width: "200px", minWidth: "fit-content", padding: 2 }}>{column.text}</div>,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							required
							fullWidth
							min={0}
							type="number"
							step={0.1}
							name="loadingDischargingTime"
							id="loadingDischargingTime"
							style={{
								color: leg.loadingDischargingTime && !(leg.loadingDischargingTime >= 0) && "red",
							}}
							defaultValue={cell}
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "totalTime",
				headerAlign: "center",
				text: i18n.t("totalTime"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							value={leg.calculated ? leg.calculated.totalTime.toFixed(2) : "0.00"}
							style={{ color: "green" }}
							type="number"
							step={0.1}
							name="totalTime"
							id="totalTime"
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "SDT",
				headerAlign: "center",
				text: i18n.t("SDT"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							type="number"
							style={{ color: "green" }}
							value={leg.calculated ? leg.calculated.SDT.toFixed(2) : "0.00"}
							step={0.1}
							name="SDT"
							id="SDT"
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "fuelRate",
				headerAlign: "center",
				text: i18n.t("fuelRate%"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							required
							fullWidth
							type="number"
							step={0.1}
							name="fuelRate"
							id="fuelRate"
							style={{
								color: leg.fuelRate && !(leg.fuelRate >= 0) && "red",
							}}
							defaultValue={cell}
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "maintCostPerKM",
				headerAlign: "center",
				text: i18n.t("maintCostPerKM"),
				headerFormatter: (column) => <div style={{ width: "200px", minWidth: "fit-content", padding: 2 }}>{column.text}</div>,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							required
							fullWidth
							type="number"
							step={0.1}
							name="maintCostPerKM"
							id="maintCostPerKM"
							style={{
								color: leg.maintCostPerKM && !(leg.maintCostPerKM >= 0) && "red",
							}}
							defaultValue={cell}
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "driverIncomePerTrip",
				headerAlign: "center",
				text: i18n.t("driverIncomePerTrip"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							type="number"
							style={{ color: "green" }}
							value={leg.calculated ? parseFloat(leg.calculated.driverIncomePerTrip).toFixed(2) : "0.00"}
							step={0.1}
							name="driverIncomePerTrip"
							id="driverIncomePerTrip"
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "fuelCostPerTrip",
				headerAlign: "center",
				text: i18n.t("fuelCostPerTrip"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							type="number"
							style={{ color: "green" }}
							value={leg.calculated ? parseFloat(leg.calculated.fuelCostPerTrip).toFixed(2) : "0.00"}
							step={0.1}
							name="fuelCostPerTrip"
							id="fuelCostPerTrip"
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "maintCostPerTrip",
				headerAlign: "center",
				text: i18n.t("maintCostPerTrip"),
				headerFormatter: (column) => <div style={{ width: "200px", minWidth: "fit-content", padding: 2 }}>{column.text}</div>,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							type="number"
							style={{ color: "green" }}
							value={leg.calculated ? parseFloat(leg.calculated.maintCostPerTrip).toFixed(2) : "0.00"}
							step={0.1}
							name="maintCostPerTrip"
							id="maintCostPerTrip"
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "roadScalesPerTrip",
				headerAlign: "center",
				text: i18n.t("roadScalesPerTrip"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							required
							fullWidth
							type="number"
							step={0.1}
							name="roadScalesPerTrip"
							id="roadScalesPerTrip"
							style={{
								color: leg.roadScalesPerTrip && !(leg.roadScalesPerTrip >= 0) && "red",
							}}
							defaultValue={cell}
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "roadTollsPerTrip",
				headerAlign: "center",
				text: i18n.t("roadTollsPerTrip"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							required
							fullWidth
							type="number"
							step={0.1}
							name="roadTollsPerTrip"
							id="roadTollsPerTrip"
							style={{
								color: leg.roadTollsPerTrip && !(leg.roadTollsPerTrip >= 0) && "red",
							}}
							defaultValue={cell}
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "portScalesPerTrip",
				headerAlign: "center",
				text: i18n.t("portScalesPerTrip"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							required
							fullWidth
							type="number"
							step={0.1}
							name="portScalesPerTrip"
							id="portScalesPerTrip"
							style={{
								color: leg.portScalesPerTrip && !(leg.portScalesPerTrip >= 0) && "red",
							}}
							defaultValue={cell}
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "portPermitsPerTrip",
				headerAlign: "center",
				text: i18n.t("portPermitsPerTrip"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							required
							fullWidth
							type="number"
							step={0.1}
							name="portPermitsPerTrip"
							id="portPermitsPerTrip"
							style={{
								color: leg.portPermitsPerTrip && !(leg.portPermitsPerTrip >= 0) && "red",
							}}
							defaultValue={cell}
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "VATInsideThePortPerTrip",
				headerAlign: "center",
				text: i18n.t("VATInsideThePortPerTrip"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							required
							fullWidth
							type="number"
							step={0.1}
							name="VATInsideThePortPerTrip"
							id="VATInsideThePortPerTrip"
							style={{
								color: leg.VATInsideThePortPerTrip && !(leg.VATInsideThePortPerTrip >= 0) && "red",
							}}
							defaultValue={cell}
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				headerAlign: "center",
				dataField: "tipsPerTrip",
				text: i18n.t("tipsPerTrip"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							required
							fullWidth
							type="number"
							step={0.1}
							name="tipsPerTrip"
							id="tipsPerTrip"
							style={{
								color: leg.tipsPerTrip && !(leg.tipsPerTrip >= 0) && "red",
							}}
							defaultValue={cell}
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "otherExp",
				headerAlign: "center",
				text: i18n.t("otherExp"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							required
							fullWidth
							type="number"
							step={0.1}
							name="otherExp"
							id="otherExp"
							style={{
								color: leg.otherExp && !(leg.otherExp >= 0) && "red",
							}}
							defaultValue={cell}
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				headerAlign: "center",
				dataField: "totalVariablePricePerTrip",
				text: i18n.t("totalVariablePricePerTrip"),
				headerFormatter: (column) => <div style={{ width: "200px", minWidth: "fit-content", padding: 2 }}>{column.text}</div>,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							width={250}
							type="number"
							style={{ color: "green" }}
							step={0.1}
							value={leg.calculated ? leg.calculated.totalVariablePricePerTrip.toFixed(2) : "0.00"}
							name="totalVariablePricePerTrip"
							id="totalVariablePricePerTrip"
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "totalFixedPricePerTrip",
				headerAlign: "center",
				text: i18n.t("totalFixedPricePerTrip"),
				headerFormatter: (column) => <div style={{ width: "200px", minWidth: "fit-content", padding: 2 }}>{column.text}</div>,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							type="number"
							style={{ color: "green" }}
							step={0.1}
							value={leg.calculated ? leg.calculated.totalFixedPricePerTrip.toFixed(2) : "0.00"}
							name="totalFixedPricePerTrip"
							id="totalFixedPricePerTrip"
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "totalPricePerTrip",
				headerAlign: "center",
				text: i18n.t("totalPricePerTrip"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							type="number"
							style={{ color: "green" }}
							step={0.1}
							value={leg.calculated ? leg.calculated.totalPricePerTrip.toFixed(2) : "0.00"}
							name="totalPricePerTrip"
							id="totalPricePerTrip"
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "FCPerKM",
				headerAlign: "center",
				text: i18n.t("FCPerKM"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							type="number"
							style={{ color: "green" }}
							step={0.1}
							value={leg.calculated ? leg.calculated.FCPerKM.toFixed(2) : "0.00"}
							name="FCPerKM"
							id="FCPerKM"
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "VCPerKM",
				headerAlign: "center",
				text: i18n.t("VCPerKM"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							type="number"
							style={{ color: "green" }}
							step={0.1}
							value={leg.calculated ? leg.calculated.VCPerKM.toFixed(2) : "0.00"}
							name="VCPerKM"
							id="VCPerKM"
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "FCPerTon",
				headerAlign: "center",
				text: i18n.t("FCPerTon"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							type="number"
							style={{ color: "green" }}
							step={0.1}
							value={leg.calculated ? leg.calculated.FCPerTon.toFixed(2) : "0.00"}
							name="FCPerTon"
							id="FCPerTon"
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "VCPerTon",
				headerAlign: "center",
				text: i18n.t("VCPerTon"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							type="number"
							style={{ color: "green" }}
							step={0.1}
							value={leg.calculated ? leg.calculated.VCPerTon.toFixed(2) : "0.00"}
							name="VCPerTon"
							id="VCPerTon"
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "TCPerTon",
				headerAlign: "center",
				text: i18n.t("TCPerTon"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							type="number"
							style={{ color: "green" }}
							step={0.1}
							value={leg.calculated ? leg.calculated.TCPerTon.toFixed(2) : "0.00"}
							name="TCPerTon"
							id="TCPerTon"
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				headerAlign: "center",
				dataField: "sellingPrice",
				text: i18n.t("sellingPrice"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							disabled
							fullWidth
							type="number"
							style={{ color: "green" }}
							step={0.1}
							value={leg.calculated ? leg.calculated.sellingPrice.toFixed(2) : "0.00"}
							name="sellingPrice"
							id="sellingPrice"
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "finalAgreedPrice",
				headerAlign: "center",
				text: i18n.t("finalAgreedPrice"),
				headerFormatter: headerFormatter,
				formatter: (cell, leg, index) => {
					return (
						<Input2
							required
							fullWidth
							type="number"
							step={0.1}
							name="finalAgreedPrice"
							id="finalAgreedPrice"
							style={{
								color: leg.finalAgreedPrice && !(leg.finalAgreedPrice >= 0) && "red",
							}}
							defaultValue={cell}
							onBlur={(e) => this.handleTrip(e, index)}
						/>
					);
				},
			},
			{
				align: "center",
				dataField: "actions",
				headerAlign: "center",
				text: i18n.t("actions"),
				formatter: (cell, leg, index) => {
					return (
						<div>
							<DeleteIcon ondelete={(e) => this.deleteTrip(e, index, leg.id)} />
						</div>
					);
				},
			},
		];
		return (
			<>
				<LoadingOverlay
					tag="div"
					active={this.state.formLoading}
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Form onInvalid={this.validate} onSubmit={this.showLegsTable}>
						<Row>
							<Col xs="12" sm="12" md="12" lg="12" xl="12">
								<div>
									<Card className="main-card mb-3" key="0">
										<CardBody>
											<Row>
												<div className="pl-1 d-flex justify-content-start">
													<h4>{t("costingDetails")}</h4>
												</div>
											</Row>
											<Row className=" my-1 mb-3 d-flex justify-content-start">
												<Col xs="12" sm="12" className="pl-1">
													<Label className="mb-0">{t("customerName")}</Label>
													<Selector
														required
														value={this.state.selectedCustomer}
														size="small"
														id="customerName"
														name="customerName"
														isObjectOption
														optionAccessor="fullName"
														options={this.state.companies}
														onChange={this.getSelectedValue}
														error={this.state.costing["invalidCustomerName"]}
													/>
												</Col>
											</Row>
											<Row className="d-flex justify-content-start">
												<Col xs="12" sm="12" md="6" className="pl-1 my-1">
													<Label className="mb-0">{t("workingHoursPerDay")}</Label>
													<Input
														required
														value={this.state.costing.workingHoursPerDay}
														min={0}
														max={24}
														type="number"
														step={0.01}
														bsSize="sm"
														id="workingHoursPerDay"
														onChange={this.getValue}
														invalid={this.state.costing["invalidWorkingHoursPerDay"]}
													/>
												</Col>
												<Col xs="12" sm="12" md="6" className="pl-1 my-1">
													<Label className="mb-0">{t("fixedSellingPricePermonth")}</Label>
													<Input
														required
														value={this.state.costing.fixedSellingPricePermonth}
														min="0"
														type="number"
														step={0.01}
														bsSize="sm"
														id="fixedSellingPricePermonth"
														onChange={this.getValue}
														invalid={this.state.costing["invalidFixedSellingPricePermonth"]}
													/>
												</Col>
												<Col xs="12" sm="12" md="6" className="pl-1 my-1">
													<Label className="mb-0">{t("VAT (%)")}</Label>
													<Input
														required
														value={this.state.costing.VAT}
														min="0"
														type="number"
														step={0.01}
														bsSize="sm"
														id="VAT"
														onChange={this.getValue}
														invalid={this.state.costing["invalidVAT"]}
													/>
												</Col>
												<Col xs="12" sm="12" md="6" className="pl-1 my-1">
													<Label className="mb-0">{t("fixedCostPerMonthIncludingVAT")}</Label>
													<Input
														disabled
														value={this.state.fixedCostPerMonthIncludingVAT.toFixed(2)}
														type="number"
														step={0.01}
														bsSize="sm"
													/>
												</Col>
											</Row>
											<Row>
												<Col xs="12" sm="12" md="6" className="pl-1 my-1">
													<Label className="mb-0">{t("workingDaysPerMonth")}</Label>
													<Input
														required
														value={this.state.costing.workingDaysPerMonth}
														min={0}
														max={31}
														type="number"
														step={0.01}
														bsSize="sm"
														id="workingDaysPerMonth"
														onChange={this.getValue}
														invalid={this.state.costing["invalidWorkingDaysPerMonth"]}
													/>
												</Col>
												<Col xs="12" sm="12" md="6" className="pl-1 my-1">
													<Label className="mb-0">{t("fixedCostPerDay")}</Label>
													<Input disabled value={this.state.fixedCostPerDay.toFixed(2)} type="number" step={0.01} bsSize="sm" />
												</Col>
											</Row>
											<Row>
												<Col xs="12" sm="12" md="4" className="pl-1 my-1">
													<Label className="mb-0">{t("driverIncomePerDay")}</Label>
													<Input
														required
														value={this.state.costing.driverIncomePerDay}
														min="0"
														type="number"
														step={0.01}
														bsSize="sm"
														id="driverIncomePerDay"
														onChange={this.getValue}
														invalid={this.state.costing["invalidDriverIncomePerDay"]}
													/>
												</Col>
												<Col xs="12" sm="12" md="4" className="pl-1 my-1">
													<Label className="mb-0">{t("fuelPrice")}</Label>
													<Input
														required
														value={this.state.costing.fuelPrice}
														min="0"
														type="number"
														step={0.01}
														bsSize="sm"
														id="fuelPrice"
														onChange={this.getValue}
														invalid={this.state.costing["invalidFuelPrice"]}
													/>
												</Col>
												<Col xs="12" sm="12" md="4" className="pl-1 my-1">
													<Label className="mb-0">{t("profitMargin")}</Label>
													<Input
														required
														value={this.state.costing.profitMargin}
														min={0}
														max={100}
														type="number"
														step={0.01}
														bsSize="sm"
														id="profitMargin"
														onChange={this.getValue}
														invalid={this.state.costing["invalidProfitMargin"]}
													/>
												</Col>
											</Row>
											<Row>
												<Col xs="12">
													<div className="d-flex justify-content-end mt-2">
														<Button color="info" type="submit">
															{t("continue")}
														</Button>
													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</div>
							</Col>
						</Row>
					</Form>

					<Fragment>
						<Row hidden={!this.state.tableLegsIsViewed}>
							<Col xs="12" sm="12" md="12" lg="12" xl="12">
								<div className="dropdown-menu-header m-0">
									<div className="dropdown-menu-header-inner m-0 rounded bg-primary">
										<div className="menu-header-content">
											<h5 className="menu-header-title">{t("routes")}</h5>
										</div>
									</div>
								</div>
								<Card className="main-card mb-3">
									{this.state.legs.length > 0 && (
										<Form onSubmit={this.state.updateView ? this.update : this.submit}>
											<CardBody>
												<TableWithPaginationSearch
													scrollable
													exportable
													filename="cpttrips.csv"
													loading={this.state.formLoading}
													keyfield={"number"}
													columns={legColumns}
													data={this.state.trips}
												/>
											</CardBody>
											<CardFooter className="d-flex justify-content-end">
												<Button color="info" onClick={this.addTrip} className="ml-1">
													{t("addRoute")}
												</Button>
												<GetPermission perm="ops.add_costing">
													<div code="perm">
														{this.state.updateView ? (
															<Button color="info" submit="true" className="ml-1">
																{t("update")}
															</Button>
														) : (
															<Button color="info" submit="true" className="ml-1">
																{t("submit")}
															</Button>
														)}
													</div>
												</GetPermission>
											</CardFooter>
										</Form>
									)}
								</Card>
							</Col>
						</Row>
					</Fragment>
				</LoadingOverlay>
			</>
		);
	}
}

export default withRouter(CptCosting);
