import i18n from "i18n";
import axios from "axios";
import Loader from "react-loaders";
import { withTranslation } from "react-i18next";
import React, { Component, Fragment } from "react";
import LoadingOverlay from "react-loading-overlay";
import GetPermission from "ops/common/get_permission";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import { AlertNotification } from "ops/common/alert_notification";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";

class ExpensesOrder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expensesTypes: [],
			formLoading: false,
			tripsReceipts: [],
			draggableList: [],
		};
	}

	getExpensesTypes() {
		this.setState({ formLoading: true });
		axios
			.get(`expensetype/`)
			.then((res) => {
				if (res) {
					this.setState(
						{
							expensesTypes: res.data,
							formLoading: false,
						},
						() => {
							const draggableList = this.state.expensesTypes.map((expense, index) => ({
								id: expense.id.toString(),
								content: expense.name,
							}));
							this.setState({ draggableList: draggableList });
						}
					);
				}
			})
			.catch((err) => {
				if (err) {
					this.setState({ formLoading: false });
					console.error(err.response);
					AlertNotification(null, "error", err.response.status);
				}
			});
	}

	componentDidMount() {
		this.getExpensesTypes();
	}

	handleDragEnd = (result) => {
		if (!result.destination) return;
		const newOrder = Array.from(this.state.draggableList);
		const [movedItem] = newOrder.splice(result.source.index, 1);
		newOrder.splice(result.destination.index, 0, movedItem);
		this.setState({ draggableList: newOrder });
	};

	doneArrangeing = () => {
		this.setState({ formLoading: true });
		const draggableListIds = this.state.draggableList.map((item) => item.id);
		const sortedExpensesTypes = draggableListIds.map((id, index) => {
			const expenseType = this.state.expensesTypes.find((expense) => expense.id.toString() === id);
			expenseType.order = index + 1;
			return expenseType;
		});
		axios
			.put("expensetype/bulk-update/", sortedExpensesTypes)
			.then((res) => {
				this.setState({ formLoading: false });
				AlertNotification(i18n.t("updatedSuccessfully"), "success");
			})
			.catch((err) => {
				this.setState({ formLoading: false });
				if (err.response) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	render() {
		const t = i18n.t;
		return (
			<Fragment>
				<PageTitleAlt2 heading={t("expensesreorder")} icon="bi bi-arrow-down-up icon-gradient bg-happy-fisher" />
				{this.state.expensesTypes.length > 0 && (
					<div className="d-flex justify-content-center">
						<Card className="w-50">
							<LoadingOverlay
								tag="div"
								styles={{
									overlay: (base) => ({
										...base,
										background: "#fff",
										opacity: 0.5,
									}),
								}}
								active={this.state.formLoading}
								spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
								<CardHeader className="dark" style={{ textTransform: "none", color: "black", fontWeight: "normal" }}>
									{t("dragAndDrop")}
								</CardHeader>
								<CardBody>
									<Row className="justify-content-center">
										<Col xs="1">
											{this.state.expensesTypes.map((expenseType, index) => (
												<div key={index} className="badge badge-pill badge-info m-2">
													{index + 1}
												</div>
											))}
										</Col>
										<Col xs="10">
											<DragDropContext onDragEnd={this.handleDragEnd}>
												<Droppable droppableId="draggableList">
													{(provided) => (
														<div ref={provided.innerRef} {...provided.droppableProps}>
															{this.state.draggableList.map((item, index) => (
																<Draggable key={item.id} draggableId={item.id} index={index}>
																	{(provided, snapshot) => (
																		<div
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}
																			style={{
																				...provided.draggableProps.style,
																				backgroundColor: snapshot.isDragging ? "lightblue" : "white",
																				border: "1px solid gray",
																				borderRadius: "5px",
																				textAlign: "center",
																				fontSize: "19px",
																				margin: "5px",
																			}}>
																			{item.content}
																		</div>
																	)}
																</Draggable>
															))}
															{provided.placeholder}
														</div>
													)}
												</Droppable>
											</DragDropContext>
										</Col>
									</Row>
								</CardBody>
								<CardFooter className="d-flex justify-content-end">
									<GetPermission perm="ops.view_expensetype">
										<div code="perm">
											<Button className="btn-right float-right" type="button" onClick={this.doneArrangeing} color="info">
												{t("update")}
											</Button>
										</div>
									</GetPermission>
								</CardFooter>
							</LoadingOverlay>
						</Card>
					</div>
				)}
			</Fragment>
		);
	}
}
export default withTranslation()(ExpensesOrder);
