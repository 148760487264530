import i18n from "i18n";
import moment from "moment";
import React, { Component } from "react";
import Selector from "ops/common/selector";
import DeleteIcon from "ops/common/delete_icon";
import { Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row } from "reactstrap";

class ServiceWOForm extends Component {
	render() {
		const t = i18n.t;
		return (
			<Row>
				<Col xs="12">
					<Card className="main-card mb-3">
						<CardBody>
							<CardTitle className="d-flex justify-content-between">
								<div>{this.props.index + 1}</div>
								<div>
									<DeleteIcon index={this.props.index} ondelete={this.props.onDelete} />
								</div>
							</CardTitle>
							<Row>
								<Col xs="12">
									<FormGroup>
										<Label>{t("project")}</Label>
										<Selector
											required
											id="project"
											size="small"
											isObjectOption
											optionAccessor="code"
											options={this.props.projects}
											name={this.props.index.toString()}
											onChange={(e, v) => {
												this.props.getSelectedValue(e, v, this.props.index);
											}}
											error={this.props.wo["invalidAsset"] ? true : false}
											value={this.props.wo["projectObject"] || { id: 0, code: "" }}
										/>
									</FormGroup>
								</Col>
								<Col xs="12">
									<FormGroup>
										<Label>{t("startDate")}</Label>
										<Input
											bsSize="sm"
											id="startDate"
											type="date"
											index={this.props.index}
											onChange={this.props.getValue}
											value={moment(this.props.wo["startDate"]).format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")}
											invalid={this.props.wo["invalidStartDate"] ? this.props.wo["invalidStartDate"] : false}
										/>
									</FormGroup>
								</Col>
								<Col xs="12" md="6">
									<FormGroup>
										<Label>{t("asset")}</Label>
										<Selector
											required
											id="asset"
											size="small"
											isObjectOption
											optionAccessor="code"
											options={this.props.assets}
											name={this.props.index.toString()}
											error={this.props.wo["invalidAsset"]}
											onChange={(e, v) => {
												this.props.getSelectedValue(e, v, this.props.index);
											}}
											value={this.props.wo["assetObject"] || { id: 0, code: "" }}
										/>
									</FormGroup>
								</Col>
								<Col xs="12" md="6">
									<FormGroup>
										<Label>{t("attachedAsset")}</Label>
										<Selector
											size="small"
											isObjectOption
											id="attachedAsset"
											optionAccessor="code"
											options={this.props.attachedAssets}
											name={this.props.index.toString()}
											onChange={(e, v) => {
												this.props.getSelectedValue(e, v, this.props.index);
											}}
											value={this.props.wo["attachedAssetObject"] || { id: 0, code: "" }}
										/>
									</FormGroup>
								</Col>
								<Col xs="12" md="6">
									<FormGroup>
										<Label>{t("driver")}</Label>
										<Selector
											required
											id="driver"
											size="small"
											isObjectOption
											optionAccessor="driverName"
											options={this.props.drivers}
											name={this.props.index.toString()}
											error={this.props.wo["invalidDriver"]}
											onChange={(e, v) => {
												this.props.getSelectedValue(e, v, this.props.index);
											}}
											value={this.props.wo["driverObject"] || { id: 0, driverName: "" }}
										/>
									</FormGroup>
								</Col>
								<Col xs="12" md="6">
									<FormGroup>
										<Label>{t("assistant")}</Label>
										<Selector
											size="small"
											isObjectOption
											id="assisstant"
											optionAccessor="driverName"
											options={this.props.assisstants}
											name={this.props.index.toString()}
											onChange={(e, v) => {
												this.props.getSelectedValue(e, v, this.props.index);
											}}
											value={this.props.wo["assisstantObject"] || { id: 0, driverName: "" }}
										/>
									</FormGroup>
								</Col>
								<Col xs="12" md="6">
									<FormGroup>
										<Label>{t("serviceType")}</Label>
										<Selector
											required
											size="small"
											isObjectOption
											id="serviceType"
											optionAccessor="name"
											options={this.props.serviceType}
											name={this.props.index.toString()}
											error={this.props.wo["invalidServiceType"]}
											onChange={(e, v) => {
												this.props.getSelectedValue(e, v, this.props.index);
											}}
											value={this.props.wo["serviceTypeObject"] || { id: 0, name: "" }}
										/>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label>{t("startKM")}</Label>
										<Input
											bsSize="sm"
											id="startKM"
											type="number"
											step={0.01}
											index={this.props.index}
											onChange={this.props.getValue}
											// Work order start KM validation commented until required
											// min={this.props.wo.assetObject ? this.props.wo.assetObject.lastGaugeReading : 0}
											value={this.props.wo.startKM ? this.props.wo.startKM : 0}
											invalid={this.props.wo["invalidStartKM"] ? this.props.wo["invalidStartKM"] : false}
										/>
									</FormGroup>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}

export default ServiceWOForm;
