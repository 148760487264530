import i18n from "i18n";
import axios from "axios";
import CptCosting from "./cpt_costing";
import { Loader } from "react-loaders";
import React, { Fragment } from "react";
import calculate from "ops/helper/costing";
import { withRouter } from "react-router-dom";
import Selector from "ops/common/selector";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import { AlertNotification } from "ops/common/alert_notification";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";
import { Form, Label, Row, Col, Card, CardBody, FormGroup, Input, Button } from "reactstrap";

class Costing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			costing: {
				name: "",
				description: "",
				type: "",
				fixedGAPerUnit: "",
				driverIncome: "",
				leasingPerMonthPerUnit: "",
				maintenanceRate: "",
				fuelRate: "",
				blowerFuel: "",
				scalesTolls: "",
				portExpenses: "",
				gratuity: "",
				fixedCostMarkup: "",
				variableCostMarkup: "",
			},
			Types: [
				{
					name: "Lump Sum",
				},
				{
					name: "Rental",
				},
				{
					name: "CPT",
				},
			],
			totalFixedCost: 0,
			totalVariableCost: 0,
			sellingPriceFixed: 0,
			sellingPriceVariable: 0,
			showResult: true,
			firstMount: true,
			values: [],
			selectedType: { name: "" },
			updateView: false,
			projects: [],
			formLoading: false,
		};
	}

	getCosting = (costingID) => {
		if (costingID) {
			axios
				.get(`costing/${costingID}/?serializer=get`)
				.then((res) => {
					if (res) {
						this.setState(
							{
								costing: res.data,
								selectedType: { name: res.data.type },
								updateView: true,
							},
							() => {
								this.calculateTotalFixed();
								this.calculateTotalVariable();
							}
						);
					}
				})
				.catch((err) => {
					if (err) {
						AlertNotification(null, "error", err.response.status);
						console.error(err.response);
					}
				});
		}
	};

	getProjects = () => {
		axios
			.get("project/?serializer=id")
			.then((res) => {
				if (res) {
					this.setState({ projects: res.data });
				}
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				}
			});
	};

	getSelectedValue = (e, v) => {
		if (v) {
			let updatedCosting = { ...this.state.costing };
			let targetID = e.target.id.split("-")[0];
			updatedCosting[targetID] = v.name;
			let selectedType = v;
			if (v.name === "Lump Sum") {
				updatedCosting["start"] = "";
				updatedCosting["end"] = "";
				updatedCosting["increment"] = "";
			}
			this.setState({
				values: [],
				costing: updatedCosting,
				selectedType: selectedType,
			});
		}
	};

	calculateTotalFixed = () => {
		let sum =
			parseFloat(this.state.costing.fixedGAPerUnit) +
			parseFloat(this.state.costing.driverIncome) +
			parseFloat(this.state.costing.leasingPerMonthPerUnit);
		this.setState({ totalFixedCost: sum }, () => {
			this.calculateSellingPriceFixed();
		});
	};

	calculateSellingPriceFixed = () => {
		let calculatedSellingPriceFixed = this.state.totalFixedCost * (1 + this.state.costing.fixedCostMarkup / 100);
		this.setState({ sellingPriceFixed: calculatedSellingPriceFixed });
	};

	calculateTotalVariable = () => {
		let sum =
			parseFloat(this.state.costing.maintenanceRate) +
			parseFloat(this.state.costing.fuelRate) +
			parseFloat(this.state.costing.scalesTolls) +
			parseFloat(this.state.costing.blowerFuel) +
			parseFloat(this.state.costing.portExpenses) +
			parseFloat(this.state.costing.gratuity);
		this.setState({ totalVariableCost: sum }, () => {
			this.calculateSellingPriceVariable();
		});
	};

	calculateSellingPriceVariable = () => {
		let calculatedSellingPriceVariable = this.state.totalVariableCost * (1 + this.state.costing.variableCostMarkup / 100);
		this.setState({ sellingPriceVariable: calculatedSellingPriceVariable });
	};

	getValue = (e) => {
		let value = e.target.value;
		let updatedCosting = { ...this.state.costing };
		updatedCosting[e.target.id] = value;
		updatedCosting[`invalid${e.target.id.charAt(0).toUpperCase()}${e.target.id.slice(1, e.target.id.length)}`] = false;
		this.setState({ costing: updatedCosting, showResult: false, values: [] }, () => {
			this.calculateTotalFixed();
			this.calculateTotalVariable();
		});
	};

	showResults = (e) => {
		if (e) {
			e.preventDefault();
		}
		this.setState({ firstMount: false });
		let arrayOfValues = [];
		if (this.state.costing.type === "Lump Sum") {
			arrayOfValues = calculate(this.state.costing.type, {
				start: this.state.costing.start,
				end: this.state.costing.end,
				increment: this.state.costing.increment,
				totalFixedCost: this.state.totalFixedCost,
				totalVariableCost: this.state.totalVariableCost,
				sellingPriceFixed: this.state.sellingPriceFixed,
				sellingPriceVariable: this.state.sellingPriceVariable,
			});
		}
		if (this.state.costing.type === "Rental") {
			arrayOfValues = calculate(this.state.costing.type, {
				totalFixedCost: this.state.totalFixedCost,
				totalVariableCost: this.state.totalVariableCost,
				sellingPriceFixed: this.state.sellingPriceFixed,
				sellingPriceVariable: this.state.sellingPriceVariable,
			});
		}
		this.setState({ values: arrayOfValues, showResult: true });
	};

	validate = (e) => {
		this.setState({ values: [] });
		let costing = { ...this.state.costing };
		let targetID = e.target.id.split("-")[0];
		costing[`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = true;
		this.setState({ costing: costing });
	};

	submit = () => {
		let costing = this.state.costing;
		this.setState({ formLoading: true });
		axios
			.post(`costing/`, costing)
			.then((res) => {
				if (res) {
					this.setState({ formLoading: false });
					AlertNotification(i18n.t("submitedSuccessfully"), "success");
					this.props.history.push({ pathname: "/listcostings/" });
				}
			})
			.catch((err) => {
				this.setState({ formLoading: false });
				if (err.response) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
		this.setState({
			costing: {
				name: "",
				description: "",
				type: null,
				fixedGAPerUnit: "",
				driverIncome: "",
				leasingPerMonthPerUnit: "",
				maintenanceRate: "",
				fuelRate: "",
				blowerFuel: "",
				scalesTolls: "",
				portExpenses: "",
				gratuity: "",
				fixedCostMarkup: "",
				variableCostMarkup: "",
			},
			values: [],
			selectedType: { name: "" },
		});
	};

	update = () => {
		let costing = this.state.costing;
		this.setState({ formLoading: true });
		axios
			.patch(`costing/${this.state.costing.id}/`, costing)
			.then((res) => {
				if (res) {
					this.setState({ formLoading: false });
					AlertNotification(i18n.t("updatedSuccessfully"), "success");
					this.props.history.push({ pathname: "/listcostings/" });
				}
			})
			.catch((err) => {
				this.setState({ formLoading: false });
				if (err.response) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.sellingPriceFixed !== this.state.sellingPriceFixed &&
			prevState.sellingPriceVariable !== this.state.sellingPriceVariable &&
			this.state.firstMount &&
			this.state.costing.type !== "CPT"
		) {
			this.showResults();
		}
	}

	componentDidMount = () => {
		let costingID = new URLSearchParams(this.props.location.search).get("id");
		if (costingID) {
			this.getProjects();
		}
		this.getCosting(costingID);
	};

	render() {
		const t = i18n.t;
		const columns = [
			{
				sort: true,
				text: t("kmTravelled"),
				align: "center",
				dataField: "kmTravelled",
				headerAlign: "center",
			},
			{
				sort: true,
				text: t("totalCost"),
				align: "center",
				dataField: "totalCost",
				headerAlign: "center",
			},
			{
				sort: true,
				text: t("totalRevenue"),
				align: "center",
				dataField: "totalRevenue",
				headerAlign: "center",
			},
			{
				sort: true,
				text: t("grossMargin"),
				align: "center",
				dataField: "grossMargin",
				headerAlign: "center",
			},
			{
				sort: true,
				text: t("netProfit"),
				align: "center",
				dataField: "netProfit",
				headerAlign: "center",
			},
		];
		return (
			<Fragment>
				<PageTitleAlt2
					heading={this.state.updateView ? t("viewCosting") : t("newCosting")}
					icon="bi bi-currency-dollar icon-gradient bg-happy-fisher"
				/>
				<LoadingOverlay
					tag="div"
					active={this.state.formLoading}
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Form onInvalid={this.validate} onSubmit={this.showResults}>
						<Row>
							<Col xs="12" sm="12" md="12" lg="12" xl="12">
								<div>
									<Card className="mb-3 main-card">
										<CardBody>
											<Row>
												<div className="pl-1 d-flex justify-content-start">
													<h4>{t("details")}</h4>
												</div>
											</Row>
											<Row>
												<Col xs="12" sm="12" md={this.state.updateView ? "6" : "3"} className="pl-1 my-1">
													<Label className="mb-0">{t("name")}</Label>
													<Input
														required
														value={this.state.costing.name}
														type="text"
														bsSize="sm"
														id="name"
														onChange={this.getValue}
														invalid={this.state.costing["invalidName"]}
													/>
												</Col>
												<Col xs="12" sm="12" md="6" className="pl-1 my-1">
													<Label className="mb-0">{t("description")}</Label>
													<Input
														value={this.state.costing.description ? this.state.costing.description : ""}
														type="text"
														bsSize="sm"
														id="description"
														onChange={this.getValue}
														invalid={this.state.costing["invalidDescription"]}
													/>
												</Col>
												<Col xs="12" sm="12" md={this.state.updateView ? "6" : "3"} className="pl-1 my-1">
													<FormGroup>
														<Label className="mb-0">{t("costingType")}</Label>
														<Selector
															disabled={!this.state.costing.name || this.state.updateView}
															value={this.state.selectedType}
															size="small"
															isObjectOption
															id="type"
															name="type"
															optionAccessor="name"
															onChange={this.getSelectedValue}
															options={this.state.Types}
														/>
													</FormGroup>
												</Col>
												{this.state.updateView ? (
													<Col xs="12" sm="12" md={this.state.updateView ? "6" : "3"} className="pl-1 my-1">
														<FormGroup>
															<Label className="mb-0">{t("relatedProject")}</Label>
															<Selector
																size="small"
																isObjectOption
																id="relatedProject"
																optionAccessor="code"
																options={this.state.projects}
															/>
														</FormGroup>
													</Col>
												) : null}
											</Row>
										</CardBody>
									</Card>
								</div>
								<div hidden={!this.state.costing.type}>
									{this.state.costing.type === "Lump Sum" || this.state.costing.type === "Rental" ? (
										<Card className="main-card mb-3" key="0">
											<CardBody>
												<Row>
													<div className="pl-1 d-flex justify-content-start">
														<h4>{t("costingDetails")}</h4>
													</div>
												</Row>
												<Row>
													<div className="mt-3 pl-1">
														<h5>{t("fixedCost/Month")}</h5>
													</div>
												</Row>
												<Row className="d-flex justify-content-start">
													<Col xs="12" sm="12" md="6" lg="3" className="pl-1 my-1">
														<Label className="mb-0">{t("fixedGAPerUnit")}</Label>
														<Input
															required
															value={this.state.costing.fixedGAPerUnit}
															min="0"
															type="number"
															step={0.01}
															bsSize="sm"
															id="fixedGAPerUnit"
															onChange={this.getValue}
															invalid={this.state.costing["invalidFixedGAPerUnit"]}
														/>
													</Col>
													<Col xs="12" sm="12" md="6" lg="3" className="pl-1 my-1">
														<Label className="mb-0">{t("driverIncome")}</Label>
														<Input
															required
															value={this.state.costing.driverIncome}
															min="0"
															type="number"
															step={0.01}
															bsSize="sm"
															id="driverIncome"
															onChange={this.getValue}
															invalid={this.state.costing["invalidDriverIncome"]}
														/>
													</Col>
													<Col xs="12" sm="12" md="6" lg="3" className="pl-1 my-1">
														<Label className="mb-0">{t("leasingPerMonthPerUnit")}</Label>
														<Input
															required
															value={this.state.costing.leasingPerMonthPerUnit}
															min="0"
															type="number"
															step={0.01}
															bsSize="sm"
															id="leasingPerMonthPerUnit"
															onChange={this.getValue}
															invalid={this.state.costing["invalidLeasingPerMonthPerUnit"]}
														/>
													</Col>
													<Col xs="12" sm="12" md="6" lg="3" className="pl-1 my-1">
														<Label className="mb-0">{t("totalFixedCost/Month")}</Label>
														<Input
															disabled
															value={this.state.totalFixedCost.toFixed(2)}
															type="number"
															step={0.01}
															bsSize="sm"
															id="total"
														/>
													</Col>
												</Row>
												<Row>
													<div className="mt-3">
														<h5>{t("variableCost/KM")}</h5>
													</div>
												</Row>
												<Row>
													<Col xs="12" sm="12" md="6" lg="3" className="pl-1 my-1">
														<Label className="mb-0">{t("maintenanceRate")}</Label>
														<Input
															required
															value={this.state.costing.maintenanceRate}
															min="0"
															type="number"
															step={0.01}
															bsSize="sm"
															id="maintenanceRate"
															onChange={this.getValue}
															invalid={this.state.costing["invalidMaintenanceRate"]}
														/>
													</Col>
													<Col xs="12" sm="12" md="6" lg="3" className="pl-1 my-1">
														<Label className="mb-0">{t("fuelRate")}</Label>
														<Input
															required
															value={this.state.costing.fuelRate}
															min="0"
															type="number"
															step={0.01}
															bsSize="sm"
															id="fuelRate"
															onChange={this.getValue}
															invalid={this.state.costing["invalidFuelRate"]}
														/>
													</Col>
													<Col xs="12" sm="12" md="6" lg="3" className="pl-1 my-1">
														<Label className="mb-0">{t("blowerFuel")}</Label>
														<Input
															required
															value={this.state.costing.blowerFuel}
															min="0"
															type="number"
															step={0.01}
															bsSize="sm"
															id="blowerFuel"
															onChange={this.getValue}
															invalid={this.state.costing["invalidBlowerFuel"]}
														/>
													</Col>
													<Col xs="12" sm="12" md="6" lg="3" className="pl-1 my-1">
														<Label className="mb-0">{t("scalesTolls")}</Label>
														<Input
															required
															value={this.state.costing.scalesTolls}
															min="0"
															type="number"
															step={0.01}
															bsSize="sm"
															id="scalesTolls"
															onChange={this.getValue}
															invalid={this.state.costing["invalidScalesTolls"]}
														/>
													</Col>
													<Col xs="12" sm="12" md="6" lg="3" className="pl-1 my-1">
														<Label className="mb-0">{t("portExpenses")}</Label>
														<Input
															required
															value={this.state.costing.portExpenses}
															min="0"
															type="number"
															step={0.01}
															bsSize="sm"
															id="portExpenses"
															onChange={this.getValue}
															invalid={this.state.costing["invalidPortExpenses"]}
														/>
													</Col>
													<Col xs="12" sm="12" md="6" lg="3" className="pl-1 my-1">
														<Label className="mb-0">{t("gratuity")}</Label>
														<Input
															required
															value={this.state.costing.gratuity}
															min="0"
															type="number"
															step={0.01}
															bsSize="sm"
															id="gratuity"
															onChange={this.getValue}
															invalid={this.state.costing["invalidGratuity"]}
														/>
													</Col>
													<Col xs="12" sm="12" md="12" lg="6" className="pl-1 my-1">
														<Label className="mb-0">{t("totalVariableCostPerKM")}</Label>
														<Input
															disabled
															value={this.state.totalVariableCost.toFixed(2)}
															type="number"
															step={0.01}
															bsSize="sm"
															id="totalVariableCost"
														/>
													</Col>
												</Row>
												<Row>
													<div className="mt-3 mb-0">
														<h5 className="mb-0">{t("markup")}</h5>
													</div>
												</Row>
												<Row>
													<Col xs="2" className="pl-1"></Col>
													<Col xs="5" className="pl-1">
														<div className=" d-flex justify-content-center">{t("fixed")}</div>
													</Col>
													<Col xs="5" className="pl-1">
														<div className=" d-flex justify-content-center">{t("variable")}</div>
													</Col>
												</Row>
												<Row>
													<Col xs="2" className="pl-1 mb-1 d-flex align-items-center">
														<div>{t("markup%")}</div>
													</Col>
													<Col xs="5" className="pl-1 mb-1">
														<Input
															required
															value={this.state.costing.fixedCostMarkup}
															min={0}
															max={100}
															className="m-1"
															type="number"
															step={0.01}
															bsSize="sm"
															id="fixedCostMarkup"
															onChange={this.getValue}
															invalid={this.state.costing["invalidFixedCostMarkup"]}
														/>
													</Col>
													<Col xs="5" className="pl-1 mb-1">
														<Input
															required
															value={this.state.costing.variableCostMarkup}
															min={0}
															max={100}
															className="m-1"
															type="number"
															step={0.01}
															bsSize="sm"
															id="variableCostMarkup"
															onChange={this.getValue}
															invalid={this.state.costing["invalidVariableCostMarkup"]}
														/>
													</Col>
												</Row>
												<Row>
													<Col xs="2" className="pl-1 d-flex align-items-center">
														<div>{t("sellingPrice")}</div>
													</Col>
													<Col xs="5" className="pl-1 my-1">
														<Input
															className="m-1"
															value={this.state.sellingPriceFixed.toFixed(2)}
															disabled
															type="number"
															step={0.01}
															bsSize="sm"
															id="sellingPriceFixed"
														/>
													</Col>
													<Col xs="5" className="pl-1 my-1">
														<Input
															className="m-1"
															value={this.state.sellingPriceVariable.toFixed(2)}
															disabled
															type="number"
															step={0.01}
															bsSize="sm"
															id="sellingPriceVariable"
														/>
													</Col>
												</Row>
												{this.state.costing.type === "Lump Sum" ? (
													<Row>
														<div className="mt-3">
															<h5>{t("incrementation")}</h5>
														</div>
													</Row>
												) : null}
												{this.state.costing.type === "Lump Sum" ? (
													<Row>
														<Col xs="12" sm="12" md="6" lg="4" className="pl-1 my-1">
															<Label className="mb-0">{t("start")}</Label>
															<Input
																required={this.state.costing.type === "Lump Sum"}
																value={this.state.costing.start}
																min={this.state.costing.start ? "0" : ""}
																type="number"
																step={0.01}
																bsSize="sm"
																id="start"
																onChange={this.getValue}
																invalid={this.state.costing["invalidStart"]}
															/>
														</Col>
														<Col xs="12" sm="12" md="6" lg="4" className="pl-1 my-1">
															<Label className="mb-0">{t("end")}</Label>
															<Input
																required={this.state.costing.type === "Lump Sum"}
																value={this.state.costing.end}
																min={this.state.costing.start ? parseInt(this.state.costing.start) + 1 : ""}
																type="number"
																step={0.01}
																bsSize="sm"
																id="end"
																onChange={this.getValue}
																invalid={this.state.costing["invalidEnd"]}
															/>
														</Col>
														<Col xs="12" sm="12" md="12" lg="4" className="pl-1 my-1">
															<Label className="mb-0">{t("increment")}</Label>
															<Input
																required={this.state.costing.type === "Lump Sum"}
																value={this.state.costing.increment}
																min={1}
																max={
																	this.state.costing.start && this.state.costing.end
																		? parseInt(this.state.costing.end) - parseInt(this.state.costing.start)
																		: ""
																}
																type="number"
																step={0.01}
																bsSize="sm"
																id="increment"
																onChange={this.getValue}
																invalid={this.state.costing["invalidIncrement"]}
															/>
														</Col>
													</Row>
												) : null}
												{this.state.costing.type === "Rental" ? (
													<div>
														<Row>
															<div className="mt-3">
																<h5>{t("expectedMonthlyKM")}</h5>
															</div>
														</Row>
														<Row>
															<Col xs="12" sm="12" md="12" lg="12" className="pl-1 my-1">
																<Input
																	disabled
																	defaultValue={8000}
																	min={1}
																	type="number"
																	step={1}
																	bsSize="sm"
																	id="expectedMonthlyKM"
																/>
															</Col>
														</Row>
													</div>
												) : null}
												<Row>
													<Col xs="12">
														<div className="d-flex justify-content-end mt-2">
															<Button color="info" submit="true">
																{t("calculate")}
															</Button>
														</div>
													</Col>
												</Row>
											</CardBody>
										</Card>
									) : null}
								</div>
							</Col>
						</Row>
					</Form>
					{this.state.costing.type === "CPT" ? (
						<CptCosting name={this.state.costing.name} description={this.state.costing.description}></CptCosting>
					) : null}
					{this.state.values.length !== 0 ||
					(this.state.updateView && this.state.costing.type !== "CPT" && this.state.showResult) ? (
						<div>
							<Card>
								<CardBody>
									<Row>
										<div className="pl-1 d-flex justify-content-start">
											<h4>{t("results")}</h4>
										</div>
									</Row>
									{this.state.costing.type === "Lump Sum" && this.state.values.length > 0 ? (
										<TableWithPaginationSearch
											scrollable
											exportable
											columns={columns}
											data={this.state.values}
											filename="Costing.csv"
											rowEvents={this.rowEvents}
											keyfield="kmTravelled"
											loading={this.state.loading}
										/>
									) : null}
									{this.state.costing.type === "Rental" && this.state.values.length > 0 ? (
										<div>
											<Row>
												<Col xs="3" className="pl-1 my-1 d-flex align-items-center">
													<div>{t("totalRevenue")}</div>
												</Col>
												<Col xs="6" className="pl-1 my-1">
													<Input
														className="m-1"
														value={this.state.values[0].totalRevenue}
														disabled
														type="number"
														step={0.01}
														bsSize="sm"
													/>
												</Col>
												<Col xs="3" className="pl-1 my-1">
													<div>{t("egpPerMonth")}</div>
												</Col>
											</Row>
											<Row>
												<Col xs="3" className="pl-1 my-1 d-flex align-items-center">
													<div>{t("totalCost")}</div>
												</Col>
												<Col xs="6" className="pl-1 my-1">
													<Input
														className="m-1"
														value={this.state.values[0].totalCost}
														disabled
														type="number"
														step={0.01}
														bsSize="sm"
													/>
												</Col>
												<Col xs="3" className="pl-1 my-1">
													<div>{t("egpPerMonth")}</div>
												</Col>
											</Row>
											<Row>
												<Col xs="3" className="pl-1 my-1 d-flex align-items-center">
													<div>{t("netProfit")}</div>
												</Col>
												<Col xs="6" className="pl-1 my-1">
													<Input
														className="m-1"
														value={this.state.values[0].netProfit}
														disabled
														type="number"
														step={0.01}
														bsSize="sm"
													/>
												</Col>
												<Col xs="3" className="pl-1 my-1">
													<div>{t("egpPerMonth")}</div>
												</Col>
											</Row>
											<Row>
												<Col xs="3" className="pl-1 my-1 d-flex align-items-center">
													<div>{t("grossMargin")}</div>
												</Col>
												<Col xs="6" className="pl-1 my-1">
													<Input
														className="m-1"
														value={this.state.values[0].grossMargin}
														disabled
														type="number"
														step={0.01}
														bsSize="sm"
													/>
												</Col>
												<Col xs="3" className="pl-1 my-1">
													<div>{t("egpPerMonth")}</div>
												</Col>
											</Row>
										</div>
									) : null}
									{!this.state.updateView ? (
										<GetPermission perm="ops.add_costing">
											<div code="perm" className="d-flex justify-content-end mt-2">
												<Button color="info" onClick={this.submit}>
													{t("submit")}
												</Button>
											</div>
										</GetPermission>
									) : (
										<GetPermission perm="ops.add_costing">
											<div code="perm" className="d-flex justify-content-end mt-2">
												<Button color="info" onClick={this.update}>
													{t("update")}
												</Button>
											</div>
										</GetPermission>
									)}
								</CardBody>
							</Card>
						</div>
					) : null}
				</LoadingOverlay>
			</Fragment>
		);
	}
}
export default withRouter(Costing);
