import i18n from "i18n";
import axios from "axios";
import Swal from "sweetalert2";
import LegForm from "./leg_form";
import Loader from "react-loaders";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import { Card, CardBody, Col, Row } from "reactstrap";

class NewLeg extends Component {
	constructor() {
		super();
		this.state = {
			newLeg: {},
			locations: [],
			loading: false,
		};
	}

	getLocations = () => {
		this.setState({ loading: true });
		axios
			.get(`location/`)
			.then((res) => {
				this.setState({ loading: false, locations: res.data });
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
			});
	};

	getValue = (e, v) => {
		let value;
		let target;
		let newLeg = { ...this.state.newLeg };
		if (v) {
			target = e.target.id.split("-")[0];
			value = v.id;
			newLeg[`${target}Object`] = v;
		} else {
			target = e.target.name;
			value = e.target.value;
		}
		newLeg[target] = value;
		newLeg[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
		this.setState({ newLeg: newLeg });
	};

	validate = (e) => {
		let target = e.target.name;
		if (!target) target = e.target.id.split("-")[0];
		let newLeg = { ...this.state.newLeg };
		newLeg[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		this.setState({ newLeg: newLeg });
	};

	onSubmit = (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		axios
			.post(`leg/?serializer=create`, this.state.newLeg)
			.then((res) => {
				this.setState({ loading: false });
				this.showAlert(res.data["name"]);
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
			});
	};

	showAlert = (legName) => {
		Swal.fire({
			icon: "success",
			buttonsStyling: false,
			showCancelButton: false,
			cancelButtonText: i18n.t("no"),
			title: `${i18n.t("legName")}:\n ${legName}`,
			confirmButtonText: i18n.t("ok"),
			customClass: {
				confirmButton: "btn btn-dark",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				this.props.history.push({ pathname: "/listlegs" });
			}
		});
	};

	componentDidMount() {
		this.getLocations();
	}

	render() {
		const t = i18n.t;
		return (
			<>
				<PageTitleAlt2 heading={t("newLeg")} icon="bi bi-signpost-2-fill icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row className="m-3 justify-content-center">
						<Col xs="12">
							<Card>
								<CardBody>
									<LegForm
										locations={this.state.locations}
										getValue={this.getValue}
										validate={this.validate}
										onSubmit={this.onSubmit}
										leg={this.state.newLeg}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}

export default withRouter(NewLeg);
