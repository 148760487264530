import i18n from "i18n";
import React, { Component, Fragment } from "react";
import { Row, Col, CardHeader, Card } from "reactstrap";

class ProjectCard extends Component {
	render() {
		const t = i18n.t;
		return (
			<Fragment>
				<CardHeader className="mb-3 h-auto pl-0 pr-0 bg-transparent no-border">
					<div className="card-header-title fsize-2 text-capitalize text-dark font-weight-normal">{t("projects")}</div>
				</CardHeader>
				<Row>
					{this.props.data.map((project, index) => {
						return (
							<Col xs="12" md="3" key={index}>
								<Card className="card-shadow-primary mb-3 widget-chart widget-chart2 text-left">
									<div className="widget-content p-0 ">
										<div className="widget-content-outer">
											<div className="widget-content-wrapper">
												<div className="widget-content-left  fsize-1">
													<div className={`widget-numbers mt-0 mb-0 fsize-1 text-info`}>{project}</div>
												</div>
											</div>
										</div>
									</div>
								</Card>
							</Col>
						);
					})}
				</Row>
			</Fragment>
		);
	}
}

export default ProjectCard;
