import i18n from "i18n";
import React, { Component } from "react";
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

class WOCloseModal extends Component {
	render() {
		const t = i18n.t;
		const { wo, toggle, isOpen, onSubmit, validateCloseWO, getValues, closeWOData, editWO } = this.props;
		return (
			<Modal isOpen={isOpen} toggle={toggle}>
				<ModalHeader style={{ backgroundColor: "#d92550", color: "white" }}>{editWO ? t("editWO") : t("closeWO")}</ModalHeader>
				<ModalBody>
					<Form id="closeWOForm" onInvalid={validateCloseWO} onSubmit={onSubmit}>
						<Row>
							<Col xs="12" md="6" className="my-1">
								<Label className="mr-1">{t("startDate")}</Label>
								<Input
									required
									name="startDate"
									onChange={getValues}
									type="datetime-local"
									defaultValue={closeWOData.startDate}
									invalid={closeWOData["invalidStartDate"]}
								/>
							</Col>
							<Col xs="12" md="6" className="my-1">
								<Label className="mr-1">{t("endDate")}</Label>
								<Input
									required
									name="endDate"
									onChange={getValues}
									type="datetime-local"
									defaultValue={closeWOData.endDate}
									invalid={closeWOData["invalidEndDate"]}
								/>
							</Col>
							<Col xs="12" md="6" className="my-1">
								<Label className="mr-1">{t("startKM")}</Label>
								<Input
									required
									step={0.01}
									type="number"
									name="startKM"
									onChange={getValues}
									defaultValue={wo.startKM}
									invalid={closeWOData["invalidStartKM"]}
								/>
							</Col>
							<Col xs="12" md="6" className="my-1">
								<Label className="mr-1">{t("endKM")}</Label>
								<Input
									required
									step={0.01}
									name="endKM"
									type="number"
									onChange={getValues}
									min={closeWOData["startKM"]}
									defaultValue={closeWOData["endKM"]}
									invalid={closeWOData["invalidEndKM"]}
								/>
								<p hidden={!closeWOData["invalidEndKMExceedMin"]} className="text-danger">
									{closeWOData["invalidEndKMExceedMin"]}
								</p>
								<p hidden={!closeWOData["invalidEndKMDistance"]} className="text-danger">
									{closeWOData["invalidEndKMDistance"]}
								</p>
							</Col>
						</Row>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button color="dark" onClick={toggle}>
						{t("cancel")}
					</Button>
					<Button color="danger" form="closeWOForm" type="submit">
						{editWO ? t("edit") : t("close")}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default WOCloseModal;
