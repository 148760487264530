import i18n from "i18n";
import axios from "axios";
import moment from "moment";
import WOForm from "./wo_form";
import RouteForm from "./route_form";
import RouteCard from "./route_card";
import { Loader } from "react-loaders";
import React, { Fragment } from "react";
import ProjectCard from "./project_card";
import { withRouter } from "react-router";
import Selector from "ops/common/selector";
import ViewIcon from "ops/common/view_icon";
import ServiceWOForm from "./service_wo_form";
import DeleteIcon from "ops/common/delete_icon";
import DeletePopup from "ops/common/delete_popup";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertNotification } from "ops/common/alert_notification";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";
import { Form, Row, Col, Card, CardBody, Button, CardHeader, Label } from "reactstrap";

class ViewPlan extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			wos: [],
			routes: [],
			plan: null,
			assets: [],
			drivers: [],
			projects: [],
			serviceWOs: [],
			assisstants: [],
			planProjects: [],
			serviceTypes: [],
			projectsLegs: [],
			newServiceWOs: [],
			attachedAssets: [],
			formLoading: false,
			selectedProjects: [],
		};
	}

	getDrivers = () => {
		axios.get(`profile/?serializer=driver&user__is_active=true&license__driverStatus=Available&departments__name__in=${["Drivers"]}`).then((res) => {
			this.setState({ drivers: res.data });
		});
	};

	getDriverAssisstants = () => {
		axios
			.get(`profile/?serializer=driver&user__is_active=true&license__driverStatus=Available&departments__name__in=${["Driver Assistants"]}`)
			.then((res) => {
				this.setState({ assisstants: res.data });
			});
	};

	getAssets = () => {
		axios.get(`asset/getAvailableAssets/`).then((res) => {
			this.setState({ assets: res.data["assets"], attachedAssets: res.data["attachedAssets"] });
		});
	};

	getDriversAndAssets = () => {
		if (this.state.drivers.length === 0) {
			this.getDrivers();
		}
		if (this.state.assisstants.length === 0) {
			this.getDriverAssisstants();
		}
		if (this.state.assets.length === 0) {
			this.getAssets();
		}
	};

	getServiceTypesAndProjects = () => {
		if (this.state.serviceTypes.length === 0) {
			axios.get(`servicetype`).then((res) => {
				this.setState({
					serviceTypes: res.data,
				});
			});
			axios.get(`project/?serializer=id&code__in=${this.state.plan.projects}`).then((res) => {
				this.setState({ planProjects: res.data });
			});
		}
	};

	addServiceWO = () => {
		this.getDriversAndAssets();
		this.getServiceTypesAndProjects();
		this.setState({
			newServiceWOs: this.state.newServiceWOs.concat({ startDate: moment().format("YYYY-MM-DDTHH:mm"), trips: [] }),
		});
	};

	removeServiceWO = (e) => {
		let newServiceWOs = [...this.state.newServiceWOs];
		newServiceWOs.splice(e.target.getAttribute("index"), 1);
		this.setState({ newServiceWOs: newServiceWOs });
	};

	getServiceWOValues = (e) => {
		let value = e.target.value;
		if (e.target.id.includes("Date")) {
			value = moment(value + moment().format("THH:mm")).format("YYYY-MM-DDTHH:mm");
		}
		let newServiceWOs = this.state.newServiceWOs;
		let index = e.target.getAttribute("index");
		newServiceWOs[index][e.target.id] = value;
		newServiceWOs[index][`invalid${e.target.id.charAt(0).toUpperCase()}${e.target.id.slice(1, e.target.id.length)}`] = false;
		this.setState({ newServiceWOs: newServiceWOs });
	};

	getServiceWOSelectedValues = (e, v, index) => {
		if (v) {
			let targetID = e.target.id.split("-")[0];
			let newServiceWOs = this.state.newServiceWOs;
			let value = v.id;
			if (targetID === "driver" || targetID === "assisstant") value = v.user;
			newServiceWOs[index][targetID] = value;
			newServiceWOs[index][`${targetID}Object`] = v;
			newServiceWOs[index][`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = false;
			if (targetID === "asset") {
				newServiceWOs[index]["startKM"] = v.lastGaugeReading;
			}
			this.setState({ newServiceWOs: newServiceWOs });
		}
	};

	submitServiceWO = (e) => {
		e.preventDefault();
		let newServiceWOs = [...this.state.newServiceWOs];
		this.setState({ formLoading: true });
		axios
			.post(`workorder/`, newServiceWOs)
			.then((res) => {
				this.setState({ formLoading: false });
				if (res) {
					this.getServiceWOs(this.state.plan.projects);
					AlertNotification(i18n.t("submit"), "success");
				}
			})
			.catch((err) => {
				this.setState({ formLoading: false });
				if (err.response) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	getServiceWOs = (projects) => {
		this.setState({ formLoading: true });
		axios.get(`workorder/?serializer=list&project__code__in=${projects.toString()}`).then((res) => {
			this.setState({
				serviceWOs: res.data,
				drivers: [],
				assets: [],
				newServiceWOs: [],
				formLoading: false,
			});
		});
	};

	getUnplannedProjects = () => {
		axios
			.get(`project/?serializer=id&plan__isnull=${true}&workOrders__isnull=${true}`)
			.then((res) => {
				if (res.data.length === 0) AlertNotification(i18n.t("thereAreNoProjectsToAdd"), "success");
				this.setState({ projects: res.data });
			})
			.catch((err) => {
				console.error(err.response);
			});
	};

	getSelectedProjects = (e, v) => {
		if (v) {
			this.setState({ selectedProjects: v });
		} else this.setState({ selectedProjects: [] });
	};

	getProjectsLegs = () => {
		axios
			.get(`projectleg/?serializer=newplan&standard__isArchive=false&project_id__code__in=${this.state.plan.projects}`)
			.then((res) => {
				this.setState({ projectsLegs: res.data });
				this.addRoute();
			})
			.catch((err) => {
				console.error(err.response);
			});
	};

	addRoute = () => {
		this.setState({ routes: this.state.routes.concat({ projectLegs: [], plan: this.state.plan.id }) });
	};

	removeRoute = (e) => {
		let routes = [...this.state.routes];
		routes.splice(e.target.getAttribute("index"), 1);
		this.setState({ routes: routes });
	};

	getRouteValues = (e) => {
		let value = e.target.value;
		let routes = this.state.routes;
		let index = e.target.getAttribute("index");
		routes[index][e.target.id] = value;
		routes[index][`invalid${e.target.id.charAt(0).toUpperCase()}${e.target.id.slice(1, e.target.id.length)}`] = false;
		this.setState({ routes: routes });
	};

	getRouteSelectedValues = (e, v, index) => {
		if (v) {
			let legsIDS = v.map((leg, indx) => {
				return { projectLeg: leg.id, order: indx + 1 };
			});
			let targetID = e.target.id.split("-")[0];
			let routes = [...this.state.routes];
			routes[index][targetID] = legsIDS;
			routes[index][targetID + "Obj"] = v;
			routes[index][`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = false;
			this.setState({ routes: routes });
		}
	};

	addWO = () => {
		this.getDriversAndAssets();
		this.setState({
			wos: this.state.wos.concat({ plan: this.state.plan.id, startDate: moment().format("YYYY-MM-DDTHH:mm") }),
		});
	};

	removeWO = (e) => {
		let wos = [...this.state.wos];
		wos.splice(e.target.getAttribute("index"), 1);
		this.setState({ wos: wos });
	};

	getWOValues = (e) => {
		let value = e.target.value;
		let wos = this.state.wos;
		let index = e.target.getAttribute("index");
		wos[index][e.target.id] = value;
		wos[index][`invalid${e.target.id.charAt(0).toUpperCase()}${e.target.id.slice(1, e.target.id.length)}`] = false;

		////////////////////////////////////////////////////////////////////////////////////
		// Work order start KM validation commented until required
		// if (e.target.id === "startKM" && wos[index]["assetObj"] && value < wos[index]["assetObj"].lastGaugeReading)
		// 	wos[index][`invalid${e.target.id.charAt(0).toUpperCase()}${e.target.id.slice(1, e.target.id.length)}`] = true;
		this.setState({ wos: wos });
	};

	getWOSelectedValues = (e, v, index) => {
		if (v) {
			let targetID = e.target.id.split("-")[0];
			let wos = this.state.wos;
			let value = v.id;
			if (targetID === "driver" || targetID === "assisstant") value = v.user;
			wos[index][targetID] = value;
			wos[index][targetID + "Obj"] = v;
			wos[index][`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = false;
			if (targetID === "route") {
				axios.get(`route/${v.id}/cost`).then((res) => {
					wos[index]["cash"] = res.data.cost;
					this.setState({ wos: wos });
				});
			} else if (targetID === "asset") {
				wos[index]["startKM"] = v.lastGaugeReading;
				this.setState({ wos: wos });
			} else this.setState({ wos: wos });
		}
	};

	validate = (e, dataKey) => {
		let data = [...this.state[dataKey]];
		let index = e.target.getAttribute("name") ? e.target.getAttribute("name") : e.target.getAttribute("index");
		let targetID = e.target.id.split("-")[0];
		data[index][`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = true;
		this.setState({ [dataKey]: data });
	};

	addProjectsToPlan = () => {
		this.setState({ formLoading: true });
		let projectsIDs = this.state.selectedProjects.map((p) => {
			return p.id;
		});
		axios
			.post(`project/updateplan/`, { planID: this.state.plan.id, projectsIDs: projectsIDs })
			.then((res) => {
				this.setState({ formLoading: false });
				if (res) {
					this.getPlan();
				}
			})
			.catch((err) => {
				this.setState({ formLoading: false });
				if (err.response) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	submitRoutes = (e) => {
		e.preventDefault();
		axios
			.post(`route/addroute/`, this.state.routes)
			.then((res) => {
				this.setState({ formLoading: false });
				if (res) {
					this.getPlan();
				}
			})
			.catch((err) => {
				this.setState({ formLoading: false });
				if (err.response) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	submitWO = (e) => {
		e.preventDefault();
		this.setState({ formLoading: true });
		axios
			.post(`workorder/`, this.state.wos)
			.then((res) => {
				this.setState({ formLoading: false });
				if (res) {
					this.getPlan();
					this.setState({ drivers: [], assets: [] });
				}
			})
			.catch((err) => {
				this.setState({ formLoading: false });
				if (err.response) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	getPlan = () => {
		axios.get(`plan/${this.props.history.location.planID}/?serializer=get`).then((res) => {
			this.getServiceWOs(res.data.projects);
			this.setState({ plan: res.data, wos: [], projects: [], selectedProjects: [], routes: [] });
		});
	};

	getInitialData = () => {
		this.getPlan();
	};

	confirmDeleteWorkOrder = (workorder, type) => {
		return DeletePopup({
			title: "Are you sure ?",
			cancelbtntxt: "No",
			confirmbtntxt: "Yes",
			text: `This will delete Work Order ${workorder.code}`,
			confirmedresult: () => this.deleteWorkOrder(workorder.id, type),
		});
	};

	deleteWorkOrder = (workorderID, type) => {
		this.setState({ loading: true });
		axios
			.delete(`workorder/${workorderID}`)
			.then((res) => {
				if (res) {
					AlertNotification(i18n.t("deleted"), "success");
					if (type === "plan") {
						let plan = { ...this.state.plan };
						plan["workOrders"] = plan.workOrders.filter((workorder) => workorder.id !== workorderID);
						this.setState({ plan: plan }, () => {
							this.setState({ loading: false });
						});
					} else if (type === "service") {
						let workorders = [...this.state.serviceWOs].filter((workorder) => workorder.id !== workorderID);
						this.setState({ serviceWOs: workorders }, () => {
							this.setState({ loading: false });
						});
					}
				}
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	componentDidMount() {
		this.getInitialData();
	}

	render() {
		const t = i18n.t;
		const headerFormatter = (column) => <div className="info-header">{column.text}</div>;

		const workOrderColumns = [
			{
				sort: true,
				align: "center",
				dataField: "code",
				headerAlign: "center",
				text: i18n.t("code"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("route"),
				dataField: "route.name",
			},
			{
				sort: true,
				align: "center",
				dataField: "status",
				headerAlign: "center",
				text: t("status"),
			},
			{
				sort: true,
				align: "center",
				dataField: "startDate",
				headerAlign: "center",
				text: i18n.t("startDate"),
			},
			{
				sort: true,
				align: "center",
				dataField: "endDate",
				headerAlign: "center",
				text: i18n.t("endDate"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "startKM",
				text: t("startKM"),
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "endKM",
				text: t("endKM"),
				formatter: (cell) => {
					if (cell) return Number(cell).toLocaleString();
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "asset.code",
				headerAlign: "center",
				text: i18n.t("asset"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "attachedAsset.code",
				text: i18n.t("attachedAsset"),
			},
			{
				sort: true,
				align: "center",
				dataField: "driver",
				headerAlign: "center",
				text: i18n.t("driver"),
			},
			{
				sort: true,
				hidden: true,
				align: "center",
				headerAlign: "center",
				dataField: "assisstant",
				text: i18n.t("assistant"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "creationUser",
				text: i18n.t("creationUser"),
			},
			{
				sort: true,
				align: "center",
				dataField: "tripsCount",
				headerAlign: "center",
				text: t("tripsCount"),
				formatter: (cell, row) => {
					return row.trips.length;
				},
			},
			{
				dataField: "actions",
				align: "center",
				headerAlign: "center",
				text: i18n.t("actions"),
				formatter: (cell, wo) => {
					return (
						<>
							<GetPermission perm="ops.view_workorder">
								<ViewIcon code="perm" target="_blank" to={`/viewplannedwo/?id=${wo.id}`} />
							</GetPermission>
							<GetPermission perm="ops.delete_workorder">
								<div code="perm">
									<DeleteIcon ondelete={() => this.confirmDeleteWorkOrder(wo, "plan")} hidden={!wo.deletable} />
								</div>
							</GetPermission>
						</>
					);
				},
			},
		];
		const tripsColumns = [
			{
				sort: true,
				align: "center",
				dataField: "number",
				headerAlign: "center",
				text: i18n.t("number"),
				headerFormatter: headerFormatter,
			},
			{
				sort: true,
				align: "center",
				dataField: "startKM",
				headerAlign: "center",
				text: i18n.t("startKM"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				dataField: "endKM",
				headerAlign: "center",
				text: i18n.t("endKM"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "distance",
				text: i18n.t("distance"),
				formatter: (cell, trip, index) => {
					let distance = trip["endKM"] - trip["startKM"];
					return Number(distance).toLocaleString();
				},
				style: (cell, trip) => {
					let distance = trip["endKM"] - trip["startKM"];
					if (!(distance - trip["tripDistance"] >= -10 && distance - trip["tripDistance"] <= 10)) {
						return { color: "red" };
					}
				},
				headerFormatter: headerFormatter,
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "loadedQTY",
				text: i18n.t("loadedQTY"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "deliveredQTY",
				text: i18n.t("deliveredQTY"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "difference",
				text: i18n.t("difference"),
				formatter: (cell, trip, index) => {
					let difference = trip["loadedQTY"] - trip["deliveredQTY"];
					if (isNaN(difference)) {
						return "-";
					}
					return Number(difference).toLocaleString();
				},
				style: (cell, trip) => {
					let difference = trip["loadedQTY"] - trip["deliveredQTY"];
					if (Math.sign(difference) === -1) {
						return { color: "red" };
					}
				},
				headerFormatter: headerFormatter,
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "loadingDate",
				text: i18n.t("loadingDate"),
				headerFormatter: headerFormatter,
				formatter: (cell) => {
					if (cell) {
						return moment(cell, "YYYY-MM-DDTHH:mm").format("DD-MM-YYYY hh:mm A");
					} else return cell;
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "unLoadingDate",
				text: i18n.t("unLoadingDate"),
				headerFormatter: headerFormatter,
				formatter: (cell) => {
					if (cell) {
						return moment(cell, "YYYY-MM-DDTHH:mm").format("DD-MM-YYYY hh:mm A");
					} else return cell;
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "projectLeg",
				text: i18n.t("projectLeg"),
				headerFormatter: headerFormatter,
			},
			{
				align: "center",
				dataField: "owner.fullName",
				headerAlign: "center",
				text: i18n.t("owner"),
				headerFormatter: headerFormatter,
			},
		];
		const expandRow = {
			renderer: (row) => <BootstrapTable keyField="id" condensed={true} data={row.trips} columns={tripsColumns} />,
		};
		const serviceWOColumns = [
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "code",
				text: t("code"),
			},
			{
				sort: true,
				align: "center",
				dataField: "status",
				headerAlign: "center",
				text: t("status"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("projectCode"),
				dataField: "project",
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("projectDescription"),
				dataField: "project__description",
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "startDate",
				text: t("startDate"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "endDate",
				text: t("endDate"),
			},
			{
				sort: true,
				align: "center",
				dataField: "serviceType",
				headerAlign: "center",
				text: t("serviceType"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "startKM",
				text: t("startKM"),
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "endKM",
				text: t("endKM"),
				formatter: (cell) => {
					if (cell) return Number(cell).toLocaleString();
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "driver",
				text: t("driver"),
			},
			{
				sort: true,
				align: "center",
				hidden: true,
				headerAlign: "center",
				dataField: "assisstant",
				text: t("assisstant"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "asset.code",
				text: t("asset"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "attachedAsset.code",
				text: t("attachedAsset"),
			},
			{
				sort: true,
				align: "center",
				dataField: "tripsCount",
				headerAlign: "center",
				text: t("tripsCount"),
				formatter: (cell, row) => {
					return row.trips.length;
				},
			},
			{
				dataField: "actions",
				align: "center",
				headerAlign: "center",
				text: t("actions"),
				formatter: (cell, wo) => {
					return (
						<>
							<GetPermission perm="ops.view_workorder">
								<ViewIcon code="perm" target="_blank" to={`/viewworkorder/?id=${wo.id}`} />
							</GetPermission>
							<GetPermission perm="ops.delete_workorder">
								<div code="perm">
									<DeleteIcon ondelete={() => this.confirmDeleteWorkOrder(wo, "service")} hidden={!wo.deletable} />
								</div>
							</GetPermission>
						</>
					);
				},
			},
		];
		const serviceWOTripsColumns = [
			{
				sort: true,
				editable: false,
				align: "center",
				dataField: "number",
				headerAlign: "center",
				text: i18n.t("number"),
				headerFormatter: headerFormatter,
			},
			{
				sort: true,
				align: "center",
				dataField: "startKM",
				headerAlign: "center",
				text: i18n.t("startKM"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				dataField: "endKM",
				headerAlign: "center",
				text: i18n.t("endKM"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "distance",
				text: i18n.t("distance"),
				formatter: (cell, trip, index) => {
					let distance = trip["endKM"] - trip["startKM"];
					return Number(distance).toLocaleString();
				},
				style: (cell, trip) => {
					let distance = trip["endKM"] - trip["startKM"];
					if (!(distance - trip["tripDistance"] >= -10 && distance - trip["tripDistance"] <= 10)) {
						return { color: "red" };
					}
				},
				headerFormatter: headerFormatter,
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "loadedQTY",
				text: i18n.t("loadedQTY"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "deliveredQTY",
				text: i18n.t("deliveredQTY"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "difference",
				text: i18n.t("difference"),
				formatter: (cell, trip, index) => {
					let difference = trip["loadedQTY"] - trip["deliveredQTY"];
					if (isNaN(difference)) {
						return "-";
					}
					return Number(difference).toLocaleString();
				},
				style: (cell, trip) => {
					let difference = trip["loadedQTY"] - trip["deliveredQTY"];
					if (Math.sign(difference) === -1) {
						return { color: "red" };
					}
				},
				headerFormatter: headerFormatter,
			},
			{
				align: "center",
				editable: false,
				headerAlign: "center",
				dataField: "loadingDate",
				text: i18n.t("loadingDate"),
				formatter: (cell) => {
					if (cell) {
						return moment(cell, "YYYY-MM-DDTHH:mm").format("DD-MM-YYYY hh:mm A");
					} else return cell;
				},
				headerFormatter: headerFormatter,
			},
			{
				align: "center",
				editable: false,
				headerAlign: "center",
				dataField: "unLoadingDate",
				text: i18n.t("unLoadingDate"),
				formatter: (cell) => {
					if (cell) {
						return moment(cell, "YYYY-MM-DDTHH:mm").format("DD-MM-YYYY hh:mm A");
					} else return cell;
				},
				headerFormatter: headerFormatter,
			},
			{
				sort: true,
				editable: false,
				align: "center",
				dataField: "leg",
				headerAlign: "center",
				text: i18n.t("leg"),
				formatter: (cell, trip, index) => {
					if (trip.projectLeg) {
						return trip.projectLeg;
					} else {
						return trip.leg;
					}
				},
				headerFormatter: headerFormatter,
			},
			{
				align: "center",
				dataField: "owner.fullName",
				headerAlign: "center",
				text: i18n.t("owner"),
				headerFormatter: headerFormatter,
			},
		];
		const expandServiceWOTripsRow = {
			renderer: (row) => <BootstrapTable keyField="id" condensed={true} data={row.trips} columns={serviceWOTripsColumns} />,
		};
		return (
			this.state.plan && (
				<Fragment>
					<LoadingOverlay
						tag="div"
						styles={{
							overlay: (base) => ({
								...base,
								background: "#fff",
								opacity: 0.5,
							}),
						}}
						active={this.state.formLoading}
						spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}
					>
						<Row>
							<Col xs="6" className="d-flex justify-content-start">
								<PageTitleAlt2 heading={this.state.plan.code} icon="bi bi-signpost-2 icon-gradient bg-happy-fisher" />
							</Col>
							<Col xs="6" className="d-flex justify-content-end">
								<GetPermission perm="ops.change_project">
									<div code="perm">
										<Button size="sm" color="dark" onClick={this.getUnplannedProjects}>
											{t("addProject")}
										</Button>
									</div>
								</GetPermission>
								<GetPermission perm="ops.add_route">
									<div code="perm">
										<Button size="sm" color="dark" className="ml-2" onClick={this.getProjectsLegs}>
											{t("addRoute")}
										</Button>
									</div>
								</GetPermission>
								<GetPermission perm="ops.add_workorder">
									<div code="perm">
										<Button size="sm" color="dark" className="ml-2" onClick={this.addWO}>
											{t("addWO")}
										</Button>
									</div>
								</GetPermission>
								<GetPermission perm="ops.add_workorder">
									<div code="perm">
										<Button size="sm" color="dark" className="ml-2" onClick={this.addServiceWO}>
											{t("addServiceWO")}
										</Button>
									</div>
								</GetPermission>
							</Col>
						</Row>
						<Row hidden={this.state.routes.length === 0}>
							<Col xs="12">
								<Card className="main-card mb-3">
									<CardBody>
										<div>
											<div className="dropdown-menu-header m-0">
												<div className="dropdown-menu-header-inner m-0 rounded bg-primary">
													<div className="menu-header-content">
														<h5 className="menu-header-title">{t("routes")}</h5>
													</div>
												</div>
											</div>
											<Form
												onInvalid={(e) => {
													this.validate(e, "routes");
												}}
												onSubmit={this.submitRoutes}
											>
												<Row className="mt-3">
													{this.state.routes.map((route, index) => {
														return (
															<Col lg="6" key={index}>
																<RouteForm
																	index={index}
																	route={route}
																	onDelete={this.removeRoute}
																	legs={this.state.projectsLegs}
																	getValue={this.getRouteValues}
																	getSelectedValue={this.getRouteSelectedValues}
																/>
															</Col>
														);
													})}
													<Col xs="12" className="d-flex justify-content-end">
														<GetPermission perm="ops.add_route">
															<div code="perm">
																<Button color="dark" onClick={this.addRoute}>
																	{t("addRoute")}
																</Button>
																<Button color="info" className="ml-2" type="submit">
																	{t("submit")}
																</Button>
															</div>
														</GetPermission>
													</Col>
												</Row>
											</Form>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Row hidden={this.state.projects.length === 0}>
							<Col xs="12">
								<Card className="main-card mb-3" key="0">
									<CardBody>
										<Row className="d-flex justify-content-start">
											<Col xs="12">
												<Label>{t("projects")}</Label>
												<Selector
													multiple
													size="small"
													id="projects"
													isObjectOption
													optionAccessor="code"
													options={this.state.projects}
													onChange={this.getSelectedProjects}
												/>
											</Col>
											<Col xs="12" className="d-flex justify-content-end mt-2">
												<GetPermission perm="ops.change_plan">
													<div code="perm">
														<Button
															type="button"
															color="danger"
															onClick={() => {
																this.setState({ projects: [], selectedProjects: [] });
															}}
														>
															{t("cancel")}
														</Button>
														<Button color="info" type="button" className="ml-1" onClick={this.addProjectsToPlan}>
															{t("submit")}
														</Button>
													</div>
												</GetPermission>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Form
							onInvalid={(e) => {
								this.validate(e, "newServiceWOs");
							}}
							onSubmit={this.submitServiceWO}
						>
							<Row hidden={this.state.newServiceWOs.length === 0}>
								<Col xs="12">
									<Card className="main-card mb-3">
										<CardBody>
											<div>
												<div className="dropdown-menu-header m-0">
													<div className="dropdown-menu-header-inner m-0 rounded bg-primary">
														<div className="menu-header-content">
															<h5 className="menu-header-title">{t("newServiceWorkOrders")}</h5>
														</div>
													</div>
												</div>
												<Row className="mt-3">
													{this.state.newServiceWOs.map((wo, index) => {
														return (
															<Col lg="6" key={index}>
																<ServiceWOForm
																	wo={wo}
																	index={index}
																	assets={this.state.assets}
																	drivers={this.state.drivers}
																	onDelete={this.removeServiceWO}
																	getValue={this.getServiceWOValues}
																	projects={this.state.planProjects}
																	assisstants={this.state.assisstants}
																	serviceType={this.state.serviceTypes}
																	attachedAssets={this.state.attachedAssets}
																	getSelectedValue={this.getServiceWOSelectedValues}
																/>
															</Col>
														);
													})}
													<Col xs="12" className="d-flex justify-content-end">
														<GetPermission perm="ops.add_workorder">
															<div code="perm">
																<Button color="dark" onClick={this.addServiceWO}>
																	{t("addWO")}
																</Button>
																<Button color="info" className="ml-2" type="submit">
																	{t("submit")}
																</Button>
															</div>
														</GetPermission>
													</Col>
												</Row>
											</div>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Form>
						<Form
							onInvalid={(e) => {
								this.validate(e, "wos");
							}}
							onSubmit={this.submitWO}
						>
							<Row hidden={this.state.wos.length === 0}>
								<Col xs="12">
									<Card className="main-card mb-3">
										<CardBody>
											<div>
												<div className="dropdown-menu-header m-0">
													<div className="dropdown-menu-header-inner m-0 rounded bg-primary">
														<div className="menu-header-content">
															<h5 className="menu-header-title">{t("newWO")}</h5>
														</div>
													</div>
												</div>
												<Row className="mt-3">
													{this.state.wos.map((wo, index) => {
														return (
															<Col lg="6" key={index}>
																<WOForm
																	wo={wo}
																	index={index}
																	onDelete={this.removeWO}
																	assets={this.state.assets}
																	getValue={this.getWOValues}
																	drivers={this.state.drivers}
																	assisstants={this.state.assisstants}
																	routes={this.state.plan.routes.filter((route) => route.invalid === false)}
																	attachedAssets={this.state.attachedAssets}
																	getSelectedValue={this.getWOSelectedValues}
																/>
															</Col>
														);
													})}
													<Col xs="12" className="d-flex justify-content-end">
														<GetPermission perm="ops.add_workorder">
															<div code="perm">
																<Button color="dark" onClick={this.addWO}>
																	{t("addWO")}
																</Button>
																<Button color="info" className="ml-2" type="submit">
																	{t("submit")}
																</Button>
															</div>
														</GetPermission>
													</Col>
												</Row>
											</div>
										</CardBody>
									</Card>
								</Col>
							</Row>
							<Row>
								<Col xs="12" sm="12" md="12" lg="12" xl="12">
									<ProjectCard data={this.state.plan.projects} />
								</Col>
								<Col xs="12" sm="12" md="12" lg="12" xl="12">
									<RouteCard data={this.state.plan.routes} />
								</Col>
								<Col xs="12" sm="12" md="12" lg="12" xl="12">
									<CardHeader className="mb-3 h-auto pl-0 pr-0 bg-transparent no-border">
										<div className="card-header-title fsize-2 text-capitalize text-dark font-weight-normal">{t("workOrders")}</div>
									</CardHeader>
									<Card className="main-card mb-3" key="0">
										<CardBody>
											<TableWithPaginationSearch
												scrollable
												exportable
												expandRow={expandRow}
												columns={workOrderColumns}
												loading={this.state.loading}
												data={this.state.plan.workOrders}
												filename={`${this.state.plan.code} Work Orders.csv`}
											/>
										</CardBody>
									</Card>
								</Col>
								<Col xs="12" sm="12" md="12" lg="12" xl="12">
									<CardHeader className="mb-3 h-auto pl-0 pr-0 bg-transparent no-border">
										<div className="card-header-title fsize-2 text-capitalize text-dark font-weight-normal">{t("serviceWorkOrders")}</div>
									</CardHeader>
									<Card className="main-card mb-3" key="0">
										<CardBody>
											<TableWithPaginationSearch
												scrollable
												exportable
												loading={this.state.loading}
												data={this.state.serviceWOs}
												filename={`${this.state.plan.code} Service Work Orders.csv`}
												columns={serviceWOColumns}
												expandRow={expandServiceWOTripsRow}
											/>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Form>
					</LoadingOverlay>
				</Fragment>
			)
		);
	}
}

export default withRouter(ViewPlan);
