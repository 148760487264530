import XLSX from "xlsx";
import axios from "axios";
import React from "react";
import Loader from "react-loaders";
import Selector from "ops/common/selector";
import { withTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";
import { Col, Row, Card, CardBody, Input, Button, CardHeader, Label } from "reactstrap";

class UploadTrip extends React.Component {
	constructor() {
		super();
		this.state = {
			wos: [],
			legs: [],
			trips: [],
			owners: [],
			projects: [],
			checked: [0, 0],
			validData: false,
			submitting: false,
			hideFileBtn: true,
			selectedProject: {},
			selectedProjects: {},
			templateDownloaded: false,
		};
	}

	selectTemplate = (e) => {
		e.target.value = null;
	};

	uploadTemplate = (evt) => {
		let file = new FileReader();
		file.onload = (e) => {
			let data = e.target.result;
			let workbook = XLSX.read(data, { type: "binary" });
			let trips = XLSX.utils.sheet_to_json(workbook.Sheets["template"], { raw: false });
			this.getUploadedData(trips);
		};
		if (evt.target.files.length > 0) file.readAsBinaryString(evt.target.files[0]);
	};

	getUploadedData = (trips) => {
		const t = this.props.t;
		let uploadedTrips = trips.map((trip, index) => {
			let newTrip = {
				ind: index,
				endKM: trip[t("endKM")],
				owner: trip[t("owner")],
				startKM: trip[t("startKM")],
				couponNo: trip[t("couponNo")],
				workOrder: trip[t("workOrder")],
				loadedQTY: trip[t("loadedQTY")],
				loadingDate: trip[t("loadingDate")],
				deliveredQTY: trip[t("deliveredQTY")],
				unLoadingDate: trip[t("unLoadingDate")],
				loadingReceiptNo: trip[t("loadingReceiptNo")],
			};
			if (this.state.checked[0]) newTrip["leg"] = trip[t("leg")];
			else if (this.state.checked[1]) newTrip["projectLeg"] = trip[t("leg")];
			this.setState({ validData: true });
			newTrip = this.validateTrip(trip, newTrip);
			return newTrip;
		});
		this.setState({ trips: uploadedTrips });
	};

	isValid = (key, value, arr) => {
		var elementID = 0;
		arr.forEach((element) => {
			if (element[key] === value) {
				elementID = element.id;
				return;
			}
		});
		if (elementID === 0) this.setState({ validData: false });
		return elementID;
	};

	validateTrip = (trip, newTrip) => {
		const t = this.props.t;
		let legs = this.state.legs;
		let wos;
		let projectWithLegs = this.state.checked[1] && this.state.selectedProjects[this.state.selectedProject.code].legs.length > 0;
		if (projectWithLegs) legs = this.state.selectedProjects[this.state.selectedProject.code].legs;
		if (this.state.checked[0]) wos = this.state.wos;
		else
			wos = this.state.selectedProjects[this.state.selectedProject.code].wos
				? this.state.selectedProjects[this.state.selectedProject.code].wos
				: [];
		let legID = this.isValid("name", trip[t("leg")], legs);
		let woID = this.isValid("code", trip[t("workOrder")], wos);
		let ownerID = this.isValid("fullName", trip[t("owner")], this.state.owners);
		if (legID === 0) newTrip["invalidLeg"] = true;
		else newTrip[projectWithLegs ? "projectLeg_id" : "leg_id"] = legID;
		if (ownerID === 0) newTrip["invalidOwner"] = true;
		else newTrip["owner_id"] = ownerID;
		if (woID === 0) newTrip["invalidWorkOrder"] = true;
		else newTrip["workOrder_id"] = woID;
		return newTrip;
	};

	downloadTemplate = (e) => {
		if (!this.state.templateDownloaded) {
			const t = this.props.t;
			let headers = [
				t("loadingDate"),
				t("unLoadingDate"),
				t("couponNo"),
				t("loadingReceiptNo"),
				t("leg"),
				t("startKM"),
				t("endKM"),
				t("loadedQTY"),
				t("deliveredQTY"),
				t("owner"),
				t("workOrder"),
			];
			let ws = XLSX.utils.json_to_sheet([], {
				header: headers,
			});
			let wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "template");
			XLSX.writeFile(wb, t("tripsTemplate.xlsx"));
		}
		this.setState({ templateDownloaded: true });
	};

	selectWOType = (e) => {
		this.getLegs();
		this.getCompanies();
		this.downloadTemplate();
		if (e.target.id === "wo") {
			this.getActiveWOs();
			this.setState({ checked: [1, 0], hideFileBtn: false, trips: [], validData: false });
		} else {
			this.getProjects();
			this.setState({ checked: [0, 1], trips: [], validData: false });
		}
	};

	getProjects = () => {
		if (this.state.projects.length === 0)
			axios.get(`project/?serializer=id`).then((res) => {
				this.setState({ projects: res.data });
			});
	};

	getProjectData = async (e, v) => {
		this.setState({ selectedProject: v, hideFileBtn: false, trips: [], validData: false });
		let selectedProjects = { ...this.state.selectedProjects };
		if (!selectedProjects[v.code]) {
			var legs, wos;
			await axios.get(`projectleg/?serializer=trip_uploader&project_id=${v.id}&standard__isArchive=${false}`).then((res) => {
				legs = res.data;
			});
			await axios.get(`workorder/?serializer=id&project=${v.id}&endDate__isnull=${true}`).then((resp) => {
				wos = resp.data;
			});
			selectedProjects[v.code] = { legs: legs, wos: wos };
			this.setState({ selectedProjects: selectedProjects });
		}
	};

	getActiveWOs = () => {
		if (this.state.wos.length === 0)
			axios
				.get(`workorder/?serializer=id&route__isnull=${true}&project__isnull=${true}&endDate__isnull=${true}`)
				.then((res) => {
					this.setState({ wos: res.data });
				})
				.catch((err) => {
					console.error(err);
				});
	};

	getCompanies = () => {
		if (this.state.owners.length === 0)
			axios
				.get(`company/?isSiteOwner=${true}`)
				.then((res) => {
					this.setState({ owners: res.data });
				})
				.catch((err) => {
					console.error(err);
				});
	};

	getLegs = () => {
		if (this.state.legs.length === 0)
			axios
				.get(`leg/?serializer=getid`)
				.then((res) => {
					this.setState({ legs: res.data });
				})
				.catch((err) => {
					console.error(err);
				});
	};

	submit = () => {
		let projectWithLegs = this.state.checked[1] && this.state.selectedProjects[this.state.selectedProject.code].legs.length > 0;
		let data = this.state.trips.map((trip) => {
			if (projectWithLegs) {
				delete trip.leg;
				delete trip.leg_id;
				trip.projectLeg = trip.projectLeg_id;
			} else {
				delete trip.projectLeg;
				delete trip.projectLeg_id;
				trip.leg = trip.leg_id;
			}
			trip.workOrder = trip.workOrder_id;
			trip.owner = trip.owner_id;
			return trip;
		});
		axios
			.post(`trip/`, data)
			.then((res) => {})
			.catch((err) => {
				if (err) console.error(err.response);
			});
	};

	render() {
		const { t } = this.props;
		const columns = [
			{
				text: "ind",
				hidden: true,
				dataField: "ind",
			},
			{
				text: t("loadingDate"),
				dataField: "loadingDate",
			},
			{
				text: t("unLoadingDate"),
				dataField: "unLoadingDate",
			},
			{
				text: t("couponNo"),
				dataField: "couponNo",
			},
			{
				text: t("loadingReceiptNo"),
				dataField: "loadingReceiptNo",
			},
			{
				text: t("leg"),
				dataField: "leg",
				formatter: (cell, row) => {
					if (row.invalidLeg) return <span className="text-danger">{row.leg || row.projectLeg}</span>;
					return row.leg || row.projectLeg;
				},
			},
			{
				text: t("startKM"),
				dataField: "startKM",
			},
			{
				text: t("endKM"),
				dataField: "endKM",
			},
			{
				text: t("loadedQTY"),
				dataField: "loadedQTY",
			},
			{
				text: t("deliveredQTY"),
				dataField: "deliveredQTY",
			},
			{
				text: t("owner"),
				dataField: "owner",
				formatter: (cell, row) => {
					if (row.invalidOwner) return <span className="text-danger">{row.owner}</span>;
					return row.owner;
				},
			},
			{
				text: t("workOrder"),
				dataField: "workOrder",
				formatter: (cell, row) => {
					if (row.invalidWorkOrder) return <span className="text-danger">{row.workOrder}</span>;
					return row.workOrder;
				},
			},
		];
		return (
			<LoadingOverlay
				tag="div"
				active={this.state.submitting}
				styles={{
					overlay: (base) => ({
						...base,
						background: "#fff",
						opacity: 0.5,
					}),
				}}
				spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" xl="12" className="mb-2">
						<PageTitleAlt2 heading={t("uploadTrips")} icon="header-icon pe-7s-note2 icon-gradient bg-happy-fisher" />
					</Col>
					<Col xs="12" className="mb-2 ">
						<div>
							<Label className="mr-4">{t("wo")}</Label>
							<Input
								id="wo"
								type="checkbox"
								name="wo"
								className="mt-1"
								onChange={this.selectWOType}
								checked={this.state.checked[0]}
							/>
							<Label className="mr-4 ml-1">{t("projectWO")}</Label>
							<Input
								id="projectWO"
								type="checkbox"
								name="projectWO"
								className="mt-1"
								onChange={this.selectWOType}
								checked={this.state.checked[1]}
							/>
						</div>
					</Col>
					<Col xs="12" hidden={!this.state.checked[1]}>
						<div style={{ maxWidth: "400px" }}>
							<Label>{t("project")}</Label>
							<Selector
								id="project"
								size="small"
								isObjectOption
								optionAccessor="code"
								options={this.state.projects}
								onChange={this.getProjectData}
							/>
						</div>
					</Col>
					<Col xs="12" md="3" className="mb-2 mt-2" hidden={this.state.hideFileBtn}>
						<Input type="file" onClick={this.selectTemplate} onChange={this.uploadTemplate} />
					</Col>
					<Col xs="12" className="mb-2 d-flex">
						<Button color="info" className="mt-1 mr-2" onClick={this.submit} disabled={!this.state.validData}>
							{t("submit")}
						</Button>
					</Col>
					<Col xs="12" sm="12" md="12" lg="12" xl="12" hidden={this.state.trips.length === 0}>
						<Card>
							<CardHeader className="card-header-tab">
								<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
									<i className="header-icon pe-7s-note2 icon-gradient bg-malibu-beach"> </i>
									{t("trips")}
								</div>
							</CardHeader>
							<CardBody>
								<TableWithPaginationSearch scrollable keyfield="ind" data={this.state.trips} columns={columns} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</LoadingOverlay>
		);
	}
}
export default withTranslation()(UploadTrip);
