import i18n from "i18n";
import axios from "axios";
import Loader from "react-loaders";
import { connect } from "react-redux";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import { Card, CardBody, Col, Input, Row } from "reactstrap";
import { AlertNotification } from "ops/common/alert_notification";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";

class ListSiteOwner extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			data: [],
		};
	}

	getData = () => {
		this.setState({ loading: true });
		axios
			.get(`company`)
			.then((res) => {
				this.setState({ data: res.data, loading: false });
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	updateIsSiteOwner = (e, company) => {
		this.setState({ loading: true });
		axios
			.patch(`company/${company.id}/`, { isSiteOwner: e.target.checked })
			.then((res) => {
				AlertNotification(i18n.t("updated"), "success");
				this.setState({ loading: false });
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	componentDidMount() {
		this.getData();
	}

	render() {
		const t = i18n.t;
		const columns = [
			{
				sort: true,
				align: "center",
				dataField: "fullName",
				headerAlign: "center",
				text: t("fullName"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "isSiteOwner",
				text: t("siteOwner"),
				formatter: (cell, company) => {
					return (
						<Input
							style={{ position: "relative" }}
							type="checkbox"
							id="isSiteOwner"
							disabled={this.props.allUserPermissions["users.change_company"] ? false : true}
							onChange={(e) => this.updateIsSiteOwner(e, company)}
							defaultChecked={company["isSiteOwner"]}
						/>
					);
				},
			},
		];
		return (
			<>
				<PageTitleAlt2 heading={t("listSiteOwner/Customer")} icon="bi bi-building icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}
				>
					<Row className="m-3 justify-content-center">
						<Col xs="12">
							<Card>
								<CardBody>
									<Row>
										<Col>
											<TableWithPaginationSearch
												scrollable
												search
												exportable
												filename="Site Owner / Customer.csv"
												data={this.state.data}
												keyfield="id"
												columns={columns}
												loading={this.state.loading}
											/>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}
export default connect(({ ThemeOptions }) => {
	return { allUserPermissions: ThemeOptions.permissions };
})(ListSiteOwner);
