export const sideMenu = (translation) => {
	return {
		Home: [
			{
				icon: "pe-7s-home",
				label: translation("home"),
				to: "#/home",
			},
		],
		Project: [
			{
				icon: "bi bi-kanban",
				label: translation("project"),
				content: [
					{
						label: translation("newProject"),
						to: "#/newproject",
					},
					{
						label: translation("projects"),
						to: "#/listprojects",
					},
				],
			},
		],

		ProjectCosting: [
			{
				icon: "bi bi-currency-dollar",
				label: translation("costing"),
				content: [
					{
						label: translation("newCosting"),
						to: "#/newcosting",
					},
					{
						label: translation("costings"),
						to: "#/listcostings",
					},
				],
			},
		],

		Plan: [
			{
				icon: "bi bi-signpost-2",
				label: translation("plan"),
				content: [
					{
						label: translation("newPlan"),
						to: "#/newplan",
					},
					{
						label: translation("plans"),
						to: "#/listplans",
					},
				],
			},
		],
		WO: [
			{
				icon: "bi bi-hdd-network",
				label: translation("workOrder"),
				content: [
					{
						label: translation("newWO"),
						to: "#/newworkorder",
					},
					{
						label: translation("workOrders"),
						to: "#/listworkorders",
					},
					{ label: translation("searchWO"), to: "#/searchwo" },
				],
			},
		],
		Trip: [
			{
				icon: "bi bi-hdd-network",
				label: translation("trip"),
				content: [
					{
						label: translation("trips"),
						to: "#/listtrips",
					},
					{
						label: translation("uploadTrips"),
						to: "#/upload-trips",
					},
					{
						label: translation("uploadGPSData"),
						to: "#/uploadgpsdata",
					},
				],
			},
		],
		RFR: [
			{
				icon: "lnr-construction",
				label: translation("rfr"),
				content: [
					{
						label: translation("rfrList"),
						externalLink: true,
						to:
							process.env.NODE_ENV === "production"
								? `${process.env.REACT_APP_MNTC_FE_URL}#/rfrlist`
								: "http://localhost:3002/#/rfrlist",
					},
				],
			},
		],
		Reports: [
			{
				icon: "pe-7s-news-paper",
				label: translation("reports"),
				content: [
					{
						label: translation("dataStudio"),
						to: "#/data-studio",
					},
					{
						label: translation("workOrdersRawData"),
						to: "#/wo-raw-data",
					},
				],
			},
		],
		Setup: [
			{
				icon: "bi bi-gear",
				label: translation("setup"),
				content: [
					{
						label: translation("expensesreorder"),
						to: "#/expensesorder",
					},
					{
						label: translation("location"),
						content: [
							{ label: translation("newLocation"), to: "#/newlocation" },
							{ label: translation("newLeg"), to: "#/newleg" },
							{ label: translation("legs"), to: "#/listlegs" },
						],
					},
					{
						label: translation("driver"),
						content: [
							{ label: translation("createDrivers"), to: "#/newdrivers" },
							{ label: translation("drivers"), to: "#/listdrivers" },
							{ label: translation("createAssisstants"), to: "#/new-assisstants" },
							{ label: translation("driverAssisstants"), to: "#/list-assisstants" },
						],
					},
					{
						label: translation("standard"),
						content: [
							{ label: translation("standards"), to: "#/liststandard" },
							{ label: translation("newStandard"), to: "#/newstandard" },
							{ label: translation("replaceStandard"), to: "#/replace-standard" },
						],
					},
					{
						label: translation("siteOwner/Customer"),
						content: [
							{ label: translation("newSiteOwner/Customer"), to: "#/new-siteowner-customer" },
							{ label: translation("listSiteOwner/Customer"), to: "#/list-siteowner-customer" },
						],
					},
					{
						label: translation("commodity"),
						content: [
							{ label: translation("newCommodity"), to: "#/new-commodity" },
							{ label: translation("newCommodityType"), to: "#/new-commodity-type" },
						],
					},
				],
			},
		],
		vendor: [
			{
				icon: "bi bi-exclude",
				label: translation("vendor"),
				content: [
					{ label: translation("uploadVendorTrip"), to: "#/uploadvendortrip" },
					{ label: translation("VendorTrips"), to: "#/listvendortrips" },
				],
			},
		],
		testDriveRequest: [
			{
				icon: "bi bi-card-checklist",
				label: translation("testDriveRequest"),
				content: [{ label: translation("testDriveRequests"), to: "#/list-test-drive-request" }],
			},
		],
	};
};
