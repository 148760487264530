import i18n from "i18n";
import React from "react";
import Selector from "ops/common/selector";
import DeleteIcon from "ops/common/delete_icon";
import { FormGroup, Label, Input, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

class RouteForm extends React.Component {
	render() {
		const t = i18n.t;
		const { index, route, legs } = this.props;
		return (
			<Row>
				<Col xs="12">
					<Card className="main-card mb-3">
						<CardBody>
							<CardTitle className="d-flex justify-content-between">
								<div>{index + 1}</div>
								<div>
									<DeleteIcon index={index} ondelete={this.props.onDelete} />
								</div>
							</CardTitle>
							<Row>
								<Col xs="12">
									<FormGroup>
										<Label>{t("legs")}</Label>
										<Selector
											multiple
											size="small"
											isObjectOption
											options={legs}
											id="projectLegs"
											name={index.toString()}
											optionAccessor="legName"
											value={route.projectLegsObj || []}
											error={route["invalidProjectLegs"]}
											required={route.projectLegs.length === 0}
											onChange={(e, v) => {
												this.props.getSelectedValue(e, v, index);
											}}
										/>
									</FormGroup>
								</Col>
								<Col xs="12" md="4">
									<FormGroup>
										<Label>{t("repeat")}</Label>
										<Input
											required
											type="number"
											index={index}
											id="numberOfRounds"
											onChange={this.props.getValue}
											value={route.numberOfRounds || ""}
											invalid={route["invalidNumberOfRounds"]}
										/>
									</FormGroup>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}
export default RouteForm;
