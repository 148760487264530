import axios from "axios";
import i18n from "i18next";
import moment from "moment";
import ViewIcon from "ops/common/view_icon";
import { withRouter } from "react-router-dom";
import React, { Component, Fragment } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import GetPermission from "ops/common/get_permission";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import DateRangePicker from "../../common/date_range_picker";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";

class ViewPlanList extends Component {
	constructor() {
		super();
		this.state = {
			data: [],
			loading: false,
		};
	}

	getPlans = (sDate, eDate) => {
		this.setState({ loading: true });
		axios.get(`plan/?serializer=list&creationDate__gte=${sDate}&creationDate__lte=${eDate}`).then((res) => {
			this.setState({
				data: res.data,
				loading: false,
			});
		});
	};

	getInitialPlans = () => {
		let endDate = moment()
			.add(1, "day")
			.format("YYYY-MM-DDT23:59:59");
		let startDate = moment()
			.subtract(30, "days")
			.format("YYYY-MM-DDT00:00:00");
		this.getPlans(startDate, endDate);
	};

	componentDidMount() {
		this.getInitialPlans();
	}

	render() {
		const columns = [
			{
				sort: true,
				align: "center",
				dataField: "code",
				headerAlign: "center",
				text: i18n.t("planCode"),
			},
			{
				dataField: "plan.projects",
				align: "center",
				headerAlign: "center",
				text: i18n.t("projects"),
				formatter: (cell, plan) => {
					return plan.projects.map((project) => {
						return (
							<div key={project} className="mb-2 mr-2 badge badge-dark">
								{project}
							</div>
						);
					});
				},
			},
			{
				dataField: "",
				align: "center",
				headerAlign: "center",
				text: i18n.t("actions"),
				formatter: (cell, plan) => {
					return (
						<GetPermission perm="ops.view_plan">
							<div code="perm">
								<ViewIcon onview={() => this.props.history.push({ pathname: "/viewplan/", planID: plan.id })} />
							</div>
						</GetPermission>
					);
				},
			},
		];

		return (
			<Fragment>
				<PageTitleAlt2 heading={i18n.t("plans")} icon="bi bi-kanban icon-gradient bg-happy-fisher" />
				<Row className="mb-3">
					<Col xs="6" sm="6" md="6" lg="6" xl="6">
						<DateRangePicker getdata={this.getPlans} />
					</Col>
				</Row>
				<Row>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<Card className="main-card mb-3" key="0">
							<CardBody>
								<TableWithPaginationSearch
									scrollable
									exportable
									search
									columns={columns}
									data={this.state.data}
									filename="projects.csv"
									loading={this.state.loading}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default withRouter(ViewPlanList);
