import i18n from "i18n";
import Map from "./map";
import axios from "axios";
import Loader from "react-loaders";
import React, { Component } from "react";
import ContextMenu from "./context_menu";
import Selector from "ops/common/selector";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { AlertNotification } from "ops/common/alert_notification";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

class NewLocation extends Component {
	constructor() {
		super();
		this.state = {
			zoom: 10,
			loading: true,
			newLocation: {},
			locationTypes: [],
			autocomplete: null,
			selectedPlaceName: "",
			showContextMenu: false,
			selectedCoordinates: "",
			contextMenuScreenPositionX: 0,
			contextMenuScreenPositionY: 0,
			mapCenter: { lat: 30.11197452943244, lng: 31.395503579540616 },
		};
	}

	onClick = (e) => {
		this.setState({ mapCenter: { lat: e.latLng.lat(), lng: e.latLng.lng() }, showContextMenu: false });
	};

	getPosition = (position) => {
		this.setState({ mapCenter: { lat: position.coords.latitude, lng: position.coords.longitude } });
	};

	getCurrentLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.getPosition, () => AlertNotification(i18n.t("pleaseAllowLocation"), "error"));
		}
	};

	getContextMenu = (e) => {
		this.setState({
			showContextMenu: true,
			contextMenuScreenPositionX: e.pixel.x,
			contextMenuScreenPositionY: e.pixel.y,
			selectedCoordinates: `${e.latLng.lat()},${e.latLng.lng()}`,
		});
	};

	addLoactionCoordinates = (coords) => {
		let newLocation = { ...this.state.newLocation };
		newLocation["name"] = this.state.selectedPlaceName;
		newLocation["coordinates"] = coords;
		this.setState({
			showContextMenu: false,
			selectedCoordinates: "",
			newLocation: newLocation,
		});
	};

	getValue = (e, v) => {
		let target = e.target.id.split("-")[0];
		let value;
		let newLocation = { ...this.state.newLocation };
		if (v) {
			value = v.id;
			newLocation[`${target}Object`] = v;
		} else {
			value = e.target.value;
		}
		newLocation[target] = value;
		newLocation[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
		this.setState({
			newLocation: newLocation,
		});
	};

	validate = (e) => {
		let target = e.target.id.split("-")[0];
		this.setState({
			newLocation: {
				...this.state.newLocation,
				[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: true,
			},
		});
	};

	addLoaction = (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		axios
			.post(`location/`, this.state.newLocation)
			.then((res) => {
				this.setState({ loading: false });
				if (res) {
					AlertNotification(i18n.t("submit"), "success");
					this.setState({ newLocation: {} });
				}
			})
			.catch((err) => {
				this.setState({ loading: false });
				if (err.response) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	getLocationTypes = () => {
		axios
			.get("locationtype/")
			.then((res) => {
				if (res) this.setState({ locationTypes: res.data });
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				}
			});
	};

	getCompanies = () => {
		axios
			.get("company/")
			.then((res) => {
				if (res) this.setState({ companies: res.data });
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				}
			});
	};

	getMap = () => {
		this.setState({ loading: false });
		this.getCurrentLocation();
		this.getLocationTypes();
		this.getCompanies();
	};

	onLoad = (autocomplete) => {
		this.setState({ autocomplete: autocomplete });
	};

	onPlaceChanged = () => {
		if (this.state.autocomplete !== null) {
			let place = this.state.autocomplete.getPlace();
			this.setState({
				mapCenter: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
				selectedPlaceName: place.name,
			});
		}
	};

	render() {
		const t = i18n.t;
		return (
			<>
				<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} onLoad={this.getMap} libraries={["places"]}>
					<PageTitleAlt2 heading={t("newLocation")} icon="bi bi-geo-alt-fill icon-gradient bg-happy-fisher" />
					<LoadingOverlay
						tag="div"
						styles={{
							overlay: (base) => ({
								...base,
								background: "#fff",
								opacity: 0.5,
							}),
						}}
						active={this.state.loading}
						spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
						<Row className="mb-3">
							<Col xs="12">
								<Card className="main-card">
									<CardBody>
										<Form onSubmit={this.addLoaction} onInvalid={this.validate}>
											<Row>
												<Col xs="12" md="6">
													<FormGroup className="d-flex">
														<Label className="m-1">{t("name")}</Label>
														<Input
															required
															id="name"
															bsSize="sm"
															type="text"
															value={this.state.newLocation.name || ""}
															onChange={this.getValue}
															invalid={this.state.newLocation["invalidName"]}
														/>
													</FormGroup>
												</Col>
												<Col xs="12" md="6">
													<FormGroup className="d-flex">
														<Label className="m-1">{t("description")}</Label>
														<Input
															required
															id="description"
															bsSize="sm"
															type="text"
															value={this.state.newLocation.description || ""}
															onChange={this.getValue}
															invalid={this.state.newLocation["invalidDescription"]}
														/>
													</FormGroup>
												</Col>
												<Col xs="12" md="6">
													<FormGroup className="d-flex">
														<Label className="m-1">{t("locationType")}</Label>
														<Selector
															required
															id="locationType"
															size="small"
															width="100%"
															isObjectOption
															optionAccessor="name"
															options={this.state.locationTypes}
															error={this.state.newLocation["invalidLocationType"]}
															onChange={this.getValue}
															getOptionSeleted
														/>
													</FormGroup>
												</Col>
												<Col xs="12" md="6">
													<FormGroup className="d-flex">
														<Label className="m-1">{t("owner")}</Label>
														<Selector
															id="company"
															size="small"
															width="100%"
															isObjectOption
															optionAccessor="fullName"
															options={this.state.companies}
															error={this.state.newLocation["invalidCompany"]}
															onChange={this.getValue}
															defaultValue={{ id: 0, fullName: "" }}
															getOptionSeleted
														/>
													</FormGroup>
												</Col>
												<Col xs="12" md="6">
													<FormGroup className="d-flex">
														<Label className="m-1">{t("coordinates")}</Label>
														<Input required bsSize="sm" type="text" value={this.state.newLocation.coordinates || ""} readOnly />
													</FormGroup>
												</Col>
												<Col className="d-flex justify-content-end">
													<GetPermission perm="ops.add_location">
														<div code="perm">
															<Button color="info" className="ml-2" type="submit">
																{t("submit")}
															</Button>
														</div>
													</GetPermission>
												</Col>
											</Row>
										</Form>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col xs="12">
								<div className="mb-3">
									<Autocomplete onLoad={this.onLoad} onPlaceChanged={this.onPlaceChanged}>
										<Input bsSize="sm" type="text" />
									</Autocomplete>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="12">
								{this.state.showContextMenu && (
									<ContextMenu
										screenPositionX={this.state.contextMenuScreenPositionX}
										screenPositionY={this.state.contextMenuScreenPositionY}
										coordinates={this.state.selectedCoordinates}
										addLoactionCoordinates={this.addLoactionCoordinates}
									/>
								)}
								<Map
									zoom={this.state.zoom}
									onClick={this.onClick}
									center={this.state.mapCenter}
									onRightClick={this.getContextMenu}
									getCurrentLocation={this.getCurrentLocation}
								/>
							</Col>
						</Row>
					</LoadingOverlay>
				</LoadScript>
			</>
		);
	}
}

export default NewLocation;
