import i18n from "i18n";
import React, { Component } from "react";
import Selector from "ops/common/selector";
import EditIcon from "ops/common/edit_icon";
import DeleteIcon from "ops/common/delete_icon";
import UpdateIcon from "ops/common/update_icon";
import { CancelIcon } from "ops/common/cancel_icon";
import GetPermission from "ops/common/get_permission";
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Input,
	Label,
	ListGroup,
	ListGroupItem,
	ListGroupItemHeading,
	Row,
} from "reactstrap";

class TestDriveRequestCard extends Component {
	render() {
		const t = i18n.t;
		const {
			code,
			status,
			asset1,
			asset2,
			creationDate,
			creationUser,
			notes,
			rfr,
			workOrder,
			mwoAssets,
			changeNotes,
		} = this.props.data;
		const { data, changeField, updateField, cancelChange, getFieldValue, confirmDeleteTestDriveRequest, createSWO } = this.props;
		return (
			<>
				<Card className="main-card mb-3">
					<CardHeader className="card-header-tab d-flex justify-content-between">
						<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
							<i className="header-icon pe-7s-note2 icon-gradient bg-malibu-beach"> </i>
							{t("testDriveRequestData")}
						</div>
						{!data.workOrder && (
							<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
								<GetPermission perm="mntc.delete_testdriverequest">
									<DeleteIcon iconsize="1.25rem" code="perm" ondelete={() => confirmDeleteTestDriveRequest(data)} />
								</GetPermission>
							</div>
						)}
					</CardHeader>
					<Row className="no-gutters">
						<Col xs="12" lg="4" className="pr-5">
							<CardBody className="pt-0 pb-0">
								<ListGroup flush>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("testDriveRequestCode")}</div>
														<div className="widget-numbers text-info">{code}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("status")}</div>
														<div className="widget-numbers text-info">{status}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("requestDate")}</div>
														<div className="widget-numbers text-info">{creationDate}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("requester")}</div>
														<div className="widget-numbers text-info">{creationUser}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
								</ListGroup>
							</CardBody>
						</Col>
						<Col xs="12" lg="4" className="pr-5">
							<CardBody className="pt-0 pb-0">
								<ListGroup flush>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("rfr")}</div>
														<div className="widget-numbers text-info">{rfr}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									{mwoAssets &&
										mwoAssets.map((asset, index) => {
											let tdrAsset = asset.assetType.name === "Trailer" ? "asset2" : "asset1";
											let disabled = asset.status !== "Available";
											return (
												<ListGroupItem key={index}>
													<Row>
														<Col xs="12" className="d-flex justify-content-between my-0 py-0">
															<div className="widget-content p-0">
																<div className="widget-content-outer">
																	<div className="widget-content-wrapper">
																		<div className="widget-content-left">
																			{data[`change${tdrAsset.charAt(0).toUpperCase()}${tdrAsset.slice(1, tdrAsset.length)}`] ? (
																				<div className="widget-heading">{t(tdrAsset)}</div>
																			) : data[`${tdrAsset}`] ? (
																				<div className="widget-heading">{data[`${tdrAsset}`].assetType}</div>
																			) : (
																				<div className="widget-heading">{t(tdrAsset)}</div>
																			)}
																		</div>
																	</div>
																</div>
															</div>
															<div className="widget-content p-0">
																<div className="widget-content-outer">
																	<div className="widget-content-wrapper">
																		<div className="widget-content-right">
																			{data[`change${tdrAsset.charAt(0).toUpperCase()}${tdrAsset.slice(1, tdrAsset.length)}`] ? (
																				<div className="icon-wrapper align-items-start">
																					<UpdateIcon onupdate={(e) => updateField(tdrAsset)} />
																					<CancelIcon
																						parentClassName="align-items-start"
																						onHoverColor="danger"
																						onCancel={(e) => cancelChange(tdrAsset)}
																					/>
																				</div>
																			) : (
																				<GetPermission perm="mntc.change_testdriverequest">
																					<EditIcon code="perm" onEdit={() => changeField(tdrAsset)} />
																				</GetPermission>
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</Col>

														<Col xs="12" className="my-0 py-0">
															{data[`change${tdrAsset.charAt(0).toUpperCase()}${tdrAsset.slice(1, tdrAsset.length)}`] ? (
																<Card
																	className="p-0"
																	style={{
																		boxShadow: disabled
																			? "inherit"
																			: data[`invalid${tdrAsset.charAt(0).toUpperCase()}${tdrAsset.slice(1, tdrAsset.length)}`]
																			? "0 1px 9px 0 red"
																			: "inherit",
																	}}>
																	<CardBody className="d-flex justify-content-between">
																		<Label className="mr-3">
																			<ListGroupItemHeading>{asset.assetType.name}</ListGroupItemHeading>
																		</Label>
																		<Selector
																			id={tdrAsset}
																			isObjectOption
																			optionAccessor="code"
																			options={[asset]}
																			disabled={disabled}
																			defaultValue={asset}
																		/>
																		<Input
																			disabled={disabled}
																			type="checkbox"
																			assetid={asset.id}
																			checked={data[`${tdrAsset}`] ? data[`${tdrAsset}`] : false}
																			id={tdrAsset}
																			name={tdrAsset}
																			onChange={getFieldValue}
																		/>
																	</CardBody>
																</Card>
															) : data[`${tdrAsset}`] ? (
																<div className="widget-content p-0">
																	<div className="widget-content-outer">
																		<div className="widget-content-wrapper">
																			<div className="widget-content-left">
																				<div className="widget-numbers text-info">{data[`${tdrAsset}`].code}</div>
																			</div>
																		</div>
																	</div>
																</div>
															) : (
																<div className="widget-content p-0">
																	<div className="widget-content-outer">
																		<div className="widget-content-wrapper">
																			<div className="widget-content-left">
																				<div className="widget-numbers text-info"></div>
																			</div>
																		</div>
																	</div>
																</div>
															)}
														</Col>
													</Row>
												</ListGroupItem>
											);
										})}
									{workOrder && (
										<ListGroupItem>
											<div className="widget-content p-0">
												<div className="widget-content-outer">
													<div className="widget-content-wrapper">
														<div className="widget-content-left">
															<div className="widget-heading">{t("swo")}</div>
															<div className="widget-numbers text-info">{workOrder.code}</div>
														</div>
													</div>
												</div>
											</div>
										</ListGroupItem>
									)}
								</ListGroup>
							</CardBody>
						</Col>
						<Col xs="12" lg="4" className="pr-5">
							<CardBody className="pt-0 pb-0">
								<ListGroup flush>
									<ListGroupItem className="d-flex justify-content-between">
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading mb-2 ">{t("notes")}</div>
														{changeNotes ? (
															<Input
																rows="8"
																type="textarea"
																name="notes"
																id="notes"
																onChange={getFieldValue}
																value={notes ? notes : ""}
															/>
														) : (
															<div style={{ whiteSpace: "pre-wrap" }} className="widget-heading text-info">
																{notes}
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-right">
														{changeNotes ? (
															<div className="icon-wrapper align-items-start">
																<UpdateIcon onupdate={(e) => updateField("notes")} />
																<CancelIcon
																	parentClassName="align-items-start"
																	onHoverColor="danger"
																	onCancel={(e) => cancelChange("notes")}
																/>
															</div>
														) : (
															<GetPermission perm="mntc.change_testdriverequest">
																<EditIcon code="perm" onEdit={() => changeField("notes")} />
															</GetPermission>
														)}
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
								</ListGroup>
							</CardBody>
						</Col>
					</Row>
					<CardFooter className="d-flex justify-content-end">
						{!workOrder && (
							<GetPermission perm="ops.add_workorder">
								<Button
									code="perm"
									color="info"
									onClick={createSWO}
									className="ml-1"
									disabled={!((asset1 && asset1.status === "Available") || (asset2 && asset2.status === "Available"))}>
									{t("addSWO")}
								</Button>
							</GetPermission>
						)}
					</CardFooter>
				</Card>
			</>
		);
	}
}

export default TestDriveRequestCard;
