import React from "react";
import cx from "classnames";
import {FormGroup, Label} from "reactstrap";

class ToggleSwitch extends React.Component {
	render() {
		return (
			<FormGroup row>
				<Label className="mr-1">{this.props.title}</Label>
				<div
					className="switch has-switch mb-2 mr-2"
					data-on-label="YES"
					data-off-label="NO"
					onClick={this.props.handleclick}>
					<div
						className={cx("switch-animate", {
							"switch-on": this.props.istoggleon,
							"switch-off": !this.props.istoggleon,
						})}>
						<input type="checkbox" />
						<span className="switch-left bg-info">Yes</span>
						<label>&nbsp;</label>
						<span className="switch-right bg-info">No</span>
					</div>
				</div>
			</FormGroup>
		);
	}
}

export default ToggleSwitch;
