import i18n from "i18n";
import React, { Component } from "react";
import Selector from "ops/common/selector";
import { Button, Card, CardBody, CardFooter, Col, Form, Input, Label, Row } from "reactstrap";
import GetPermission from "ops/common/get_permission";

class LegForm extends Component {
	// This form can be extented to be leg edit form by prop update=true
	render() {
		const t = i18n.t;
		const { locations, getValue, validate, onSubmit, leg } = this.props;
		return (
			<>
				<Card>
					<CardBody>
						<Row>
							<Col xs="12">
								<Form id="legForm" onInvalid={validate} onSubmit={onSubmit}>
									<Row>
										{this.props.update && (
											<Col xs="12" md="6" lg="3">
												<Label>{t("name")}</Label>
												<Input
													required
													name="name"
													type="text"
													onChange={getValue}
													invalid={leg["invalidName"]}
													value={leg["name"] && leg["name"]}
												/>
											</Col>
										)}
										<Col xs="12" md="6" lg={this.props.update ? 3 : 4}>
											<Label>{t("source")}</Label>
											<Selector
												required
												id="source"
												size="small"
												width="100%"
												isObjectOption
												optionAccessor="name"
												options={locations}
												onChange={getValue}
												error={leg["invalidSource"]}
												value={this.props.update && leg["source"] && leg["source"]}
											/>
										</Col>
										<Col xs="12" md="6" lg={this.props.update ? 3 : 4}>
											<Label>{t("destination")}</Label>
											<Selector
												required
												id="destination"
												size="small"
												width="100%"
												isObjectOption
												optionAccessor="name"
												options={locations}
												onChange={getValue}
												error={leg["invalidDestination"]}
												value={this.props.update && leg["destination"] && leg["destination"]}
											/>
										</Col>

										<Col xs="12" md="6" lg={this.props.update ? 3 : 4}>
											<Label>{t("distance")}</Label>
											<Input
												required
												name="distance"
												type="number"
												step={0.01}
												onChange={getValue}
												invalid={leg["invalidDistance"]}
												value={this.props.update && leg["distance"] && leg["distance"]}
											/>
										</Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</CardBody>
					<CardFooter className="d-flex justify-content-end">
						<GetPermission perm="ops.add_leg">
							<Button code="perm" color="info" form="legForm" type="submit">
								{t("submit")}
							</Button>
						</GetPermission>
					</CardFooter>
				</Card>
			</>
		);
	}
}
export default LegForm;
