import React, { Component } from "react";
import ViewPlanList from "../components/list_plans";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class ListPlanScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<ViewPlanList />
			</CSSTransitionGroup>
		);
	}
}

export default ListPlanScene;
