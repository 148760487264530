import axios from "axios";
import { getCookie } from "cookies";
import { redirectToAppLog } from "./redirect_to_applog";
import endPointUrl, { accountAPIUrl, inventoryAPIUrl } from "./config/endpointConfig";

// authAxios for account apis
export const authAxios = axios.create({ baseURL: accountAPIUrl() });

// inventoryAxios for inventory apis
export const inventoryAxios = axios.create({ baseURL: inventoryAPIUrl() });

export const axiosInterceptors = () => {
	// Add Config and base url to every axios request
	axios.interceptors.request.use((config) => {
		config["url"] = endPointUrl + config["url"];
		config["headers"].Authorization = "Token " + getCookie("toki");
		config["headers"].Accept = "application/json";
		return config;
	});

	// authAxios for account apis
	authAxios.interceptors.request.use((config) => {
		if (config["url"] !== "login") {
			config["headers"].Authorization = "Token " + getCookie("toki");
			config["headers"].Accept = "application/json";
		}
		return config;
	});

	inventoryAxios.interceptors.request.use((config) => {
		if (config["url"] !== "login") {
			config["headers"].Authorization = "Token " + getCookie("toki");
			config["headers"].Accept = "application/json";
		}
		return config;
	});

	/*Check if api response status is 401 unauthorized then redirect to applog page
	 *  else return response to called function */
	axios.interceptors.response.use(
		function(response) {
			return response;
		},
		function(error) {
			if (error.response && error.response.status === 401) {
				redirectToAppLog();
			}
			return Promise.reject(error);
		}
	);

	/*Check if api response status is 401 unauthorized then redirect to applog page
	 *  else return response to called function */
	inventoryAxios.interceptors.response.use(
		function(response) {
			return response;
		},
		function(error) {
			if (error.response && error.response.status === 401) {
				redirectToAppLog();
			}
			return Promise.reject(error);
		}
	);

	/*Check if api response status is 401 unauthorized then redirect to applog page
	 *  else return response to called function */
	authAxios.interceptors.response.use(
		function(response) {
			return response;
		},
		function(error) {
			if (error.response && error.response.status === 401) {
				redirectToAppLog();
			}
			return Promise.reject(error);
		}
	);
};
