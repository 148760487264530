import axios from "axios";
import i18n from "i18next";
import moment from "moment";
import Loader from "react-loaders";
import workerpool from "workerpool";
import Selector from "ops/common/selector";
import ViewIcon from "ops/common/view_icon";
import { withRouter } from "react-router-dom";
import React, { Component, Fragment } from "react";
import LoadingOverlay from "react-loading-overlay";
import GetPermission from "ops/common/get_permission";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import DateRangePicker from "../../common/date_range_picker";
import { AlertNotification } from "ops/common/alert_notification";
import { Row, Col, Card, CardBody, Label, Button, Input } from "reactstrap";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";

class WORawData extends Component {
	constructor() {
		super();
		this.state = {
			data: [],
			endDate: "",
			tripsData: [],
			startDate: "",
			loading: false,
			projectTypes: [],
			projectType: null,
			isServiceWO: false,
		};
	}

	getEventData = (trip, event, user = false) => {
		let date = "";
		let userName = "";
		trip.workOrder.logs.forEach((log) => {
			if (log.event === event) {
				userName = log.user;
				date = moment(log.creationDate).format("DD-MM-YYYY");
			}
		});
		if (user) return userName;
		return date;
	};

	setServiceWO = () => {
		this.setState({ isServiceWO: !this.state.isServiceWO });
	};

	getDates = (sdate, edate) => {
		this.setState({ startDate: sdate, endDate: edate });
	};

	processData = (wos) => {
		let trips = [];
		wos.forEach((wo) => {
			wo.trips.forEach((trip) => {
				let expensesDict = {};
				trip["workOrder"] = {
					code: wo.code,
					logs: wo.logs,
					asset: wo.asset,
					endKM: wo.endKM,
					driver: wo.driver,
					endDate: wo.endDate,
					startKM: wo.startKM,
					startDate: wo.startDate,
					assisstant: wo.assisstant,
					attachedAsset: wo.attachedAsset,
					attachedAsset_type: wo.attachedAsset_type,
					project: wo.project ? wo.project.code : "",
					company: wo.project ? wo.project.company : "",
				};
				let tripTotalExpenses = 0;
				trip.expenses.forEach((expense) => {
					if (
						expense.expenseType !== "Driver Bonus" &&
						expense.expenseType !== "Driver Allowance" &&
						expense.expenseType !== "Driver Asst. Allowance"
					)
						tripTotalExpenses += Number(expense.amount);
					expensesDict[expense.expenseType] = expense.amount;
				});
				expensesDict["total"] = tripTotalExpenses;
				trip["expensesObj"] = expensesDict;
				trips.push(trip);
			});
		});
		return trips;
	};

	getWOs = async () => {
		if (!this.state.startDate || !this.state.endDate) return AlertNotification(i18n.t("selectDate"), "warning");
		let t0 = moment();
		this.setState({ loading: true });
		await axios
			.get(
				`workorder/?serializer=raw&startDate__gte=${this.state.startDate}&startDate__lte=${
					this.state.endDate
				}&project__projectType__name=${this.state.projectType ? this.state.projectType : ""}&serviceType__isnull=${
					this.state.isServiceWO ? false : ""
				}`
			)
			.then((res) => {
				this.setState({ data: res.data, loading: false });
				// Off-load part of the processing to another thread to speed up the page.
				const pool = workerpool.pool({ maxWorkers: 2, minWorkers: 1 });
				pool
					.exec(this.processData, [res.data])
					.then((result) => {
						console.log(
							moment()
								.subtract(t0, "milliseconds")
								.format("ss:SS")
						);
						this.setState({
							tripsData: result,
						});
					})
					.catch((err) => {
						console.error(err);
					});
			});
		console.log(
			moment()
				.subtract(t0, "milliseconds")
				.format("ss:SS")
		);
	};

	getValue = (e, v) => {
		if (v) return this.setState({ projectType: v.name });
		return this.setState({ projectType: null });
	};

	getProjectTypes = () => {
		axios.get(`projecttype`).then((res) => {
			this.setState({
				projectTypes: res.data,
			});
		});
	};

	componentDidMount() {
		this.getProjectTypes();
	}

	render() {
		const columns = [
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("project"),
				dataField: "project.code",
			},
			{
				sort: true,
				align: "center",
				dataField: "code",
				headerAlign: "center",
				text: i18n.t("workOrder"),
			},
			{
				align: "center",
				headerAlign: "center",
				dataField: "commodity",
				text: i18n.t("commodity"),
				formatter: (cell, wo) => {
					if (wo.project)
						return wo.project.commodity.map((commodity) => {
							return (
								<div key={commodity.id} className="mb-2 mr-2 badge badge-dark">
									{commodity.name}
								</div>
							);
						});
					return " ";
				},
				csvFormatter: (cell, wo) => {
					let names = " ";
					if (wo.project)
						wo.project.commodity.forEach((commodity) => {
							names += `${commodity.name} `;
						});
					return names;
				},
			},
			{
				align: "center",
				dataField: "status",
				headerAlign: "center",
				text: i18n.t("status"),
				formatter: (cell, wo) => {
					let length = wo.logs.length;
					return length > 0 ? wo.logs[length - 1].event : wo.endDate ? "Closed" : "Active";
				},
				csvFormatter: (cell, wo) => {
					let length = wo.logs.length;
					if (length > 0) return wo.logs[length - 1].event;
					return wo.endDate ? "Closed" : "Active";
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "driver",
				headerAlign: "center",
				text: i18n.t("driver"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "assisstant",
				text: i18n.t("assistant"),
				csvFormatter: (cell, wo) => {
					return wo.assisstant || "";
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "asset",
				headerAlign: "center",
				text: i18n.t("asset"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "attachedAsset",
				text: i18n.t("attachedAsset"),
				csvFormatter: (cell, wo) => {
					return wo.attachedAsset || "";
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "startDate",
				text: i18n.t("startDate"),
			},
			{
				sort: true,
				align: "center",
				dataField: "endDate",
				headerAlign: "center",
				text: i18n.t("endDate"),
			},
			{
				align: "center",
				dataField: "duration",
				headerAlign: "center",
				text: i18n.t("duration"),
				formatter: (cell, wo) => {
					if (wo.endDate)
						return moment(wo.endDate, "DD-MM-YYYY hh:mm A").diff(moment(wo.startDate, "DD-MM-YYYY hh:mm A"), "days");
					return "";
				},
				csvFormatter: (cell, wo) => {
					if (wo.endDate)
						return moment(wo.endDate, "DD-MM-YYYY hh:mm A").diff(moment(wo.startDate, "DD-MM-YYYY hh:mm A"), "days");
					return "";
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "startKM",
				headerAlign: "center",
				text: i18n.t("startKM"),
			},
			{
				sort: true,
				align: "center",
				dataField: "endKM",
				headerAlign: "center",
				text: i18n.t("endKM"),
			},
			{
				align: "center",
				headerAlign: "center",
				dataField: "tripCount",
				text: i18n.t("no.OfTrips"),
				formatter: (cell, wo) => {
					return wo.trips.length || 0;
				},
				csvFormatter: (cell, wo) => {
					return wo.trips.length || 0;
				},
			},
			{
				align: "center",
				dataField: "rfr",
				text: i18n.t("rfr"),
				headerAlign: "center",
				formatter: (cell, wo) => {
					return wo.rfr.map((rfr) => {
						return (
							<div key={rfr} className="mb-2 mr-2 badge badge-dark">
								{rfr}
							</div>
						);
					});
				},
				csvFormatter: (cell, wo) => {
					let names = "";
					wo.rfr.forEach((rfr) => {
						names += `${rfr} `;
					});
					return names;
				},
			},
			{
				align: "center",
				headerAlign: "center",
				dataField: "expenseDate",
				text: i18n.t("expenseReviewDate"),
				formatter: (cell, wo) => {
					return this.getEventData({ workOrder: wo }, "Expense Review");
				},
				csvFormatter: (cell, wo) => {
					return this.getEventData({ workOrder: wo }, "Expense Review");
				},
			},
			{
				dataField: "",
				align: "center",
				headerAlign: "center",
				text: i18n.t("actions"),
				formatter: (cell, wo) => {
					return (
						<GetPermission perm="ops.view_plan">
							<div code="perm">
								<ViewIcon onview={() => this.props.history.push({ pathname: `viewworkorder/?id=${wo.id}` })} />
							</div>
						</GetPermission>
					);
				},
				csvExport: false,
			},
		];
		const tripsColumns = [
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("project"),
				dataField: "workOrder.project",
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("workOrder"),
				dataField: "workOrder.code",
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("tripID"),
				dataField: "workOrder.code",
				formatter: (cell, trip) => {
					return `${trip.workOrder.code}-${trip.number}`;
				},
				csvFormatter: (cell, trip) => {
					return `${trip.workOrder.code}-${trip.number}`;
				},
			},
			{
				align: "center",
				dataField: "status",
				headerAlign: "center",
				text: i18n.t("status"),
				formatter: (cell, trip) => {
					let length = trip.workOrder.logs.length;
					return length > 0
						? trip.workOrder.logs[length - 1].event
						: trip.workOrder.endDate
						? i18n.t("closed")
						: i18n.t("active");
				},
				csvFormatter: (cell, trip) => {
					let length = trip.workOrder.logs.length;
					return length > 0
						? trip.workOrder.logs[length - 1].event
						: trip.workOrder.endDate
						? i18n.t("closed")
						: i18n.t("active");
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("customer"),
				dataField: "workOrder.company",
			},
			{
				sort: true,
				align: "center",
				text: i18n.t("leg"),
				headerAlign: "center",
				dataField: "projectLeg.standard.leg",
				formatter: (cell, trip) => {
					return trip.leg ? trip.leg.name : trip.projectLeg.standard.leg;
				},
				csvFormatter: (cell, trip) => {
					return trip.leg ? trip.leg.name : trip.projectLeg.standard.leg;
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "owner",
				text: i18n.t("owner"),
				headerAlign: "center",
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "loadingReceiptNo",
				text: i18n.t("loadingReceiptNo"),
				csvFormatter: (cell, trip) => {
					return trip.loadingReceiptNo || "";
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("driver"),
				dataField: "workOrder.driver",
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("asset"),
				dataField: "workOrder.asset",
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("attachedAsset"),
				dataField: "workOrder.attachedAsset",
				csvFormatter: (cell, trip) => {
					return trip.workOrder.attachedAsset || "";
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("attachedAssetSubType"),
				dataField: "workOrder.attachedAsset_type",
				csvFormatter: (cell, trip) => {
					return trip.workOrder.attachedAsset_type || "";
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("commodity"),
				dataField: "projectLeg.standard.commodity.name",
				csvFormatter: (cell, trip) => {
					let name;
					try {
						name = trip.projectLeg.standard.commodity.name;
					} catch (e) {
						name = "";
					}
					return name;
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("distance"),
				dataField: "projectLeg.standard.distance",
				formatter: (cell, trip) => {
					return trip.leg ? trip.leg.distance : trip.projectLeg.standard.distance;
				},
				csvFormatter: (cell, trip) => {
					return trip.leg ? trip.leg.distance : trip.projectLeg.standard.distance;
				},
			},
			{
				align: "center",
				headerAlign: "center",
				dataField: "loadingDate",
				text: i18n.t("loadingDate"),
				formatter: (cell, trip) => {
					return moment(trip.loadingDate).format("DD-MM-YYYY");
				},
				csvFormatter: (cell, trip) => {
					return moment(trip.loadingDate).format("DD-MM-YYYY");
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "loadedQTY",
				text: i18n.t("loadedQTY"),
			},
			{
				align: "center",
				headerAlign: "center",
				dataField: "unLoadingDate",
				text: i18n.t("unLoadingDate"),
				formatter: (cell, trip) => {
					return moment(trip.unLoadingDate).format("DD-MM-YYYY");
				},
				csvFormatter: (cell, trip) => {
					return moment(trip.unLoadingDate).format("DD-MM-YYYY");
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "deliveredQTY",
				text: i18n.t("deliveredQTY"),
			},
			{
				align: "center",
				dataField: "diff",
				text: i18n.t("diff"),
				headerAlign: "center",
				formatter: (cell, trip) => {
					return trip.loadedQTY - trip.deliveredQTY;
				},
				csvFormatter: (cell, trip) => {
					return trip.loadedQTY - trip.deliveredQTY;
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Driver Allowance`,
				text: i18n.t("driverAllowance"),
				headerAlign: "center",
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["Driver Allowance"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Driver Bonus`,
				text: i18n.t("driverBonus"),
				headerAlign: "center",
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["Driver Bonus"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Driver Asst. Allowance`,
				text: i18n.t("driverAsst.Allowance"),
				headerAlign: "center",
				formatter: (cell, trip) => {
					return trip.expensesObj["Driver Asst. Allowance"];
				},
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["Driver Asst. Allowance"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Military Scale`,
				text: i18n.t("militaryScale"),
				headerAlign: "center",
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["Military Scale"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Govte. Scale`,
				text: i18n.t("govt.Scale"),
				headerAlign: "center",
				formatter: (cell, trip) => {
					return trip.expensesObj["Govte. Scale"];
				},
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["Govte. Scale"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Military Tolls`,
				text: i18n.t("militaryTolls"),
				headerAlign: "center",
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["Military Tolls"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Govt. Tolls`,
				text: i18n.t("govt.Tolls"),
				headerAlign: "center",
				formatter: (cell, trip) => {
					return trip.expensesObj["Govte. Tolls"];
				},
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["Govte. Tolls"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Maintenance Bills`,
				text: i18n.t("maintenanceBills"),
				headerAlign: "center",
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["Maintenance Bills"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Other Bills`,
				text: i18n.t("otherBills"),
				headerAlign: "center",
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["Other Bills"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Fuel`,
				text: i18n.t("fuel"),
				headerAlign: "center",
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["Fuel"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Gratuity`,
				text: i18n.t("gratuity"),
				headerAlign: "center",
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["Gratuity"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Port Permit`,
				text: i18n.t("portPermit"),
				headerAlign: "center",
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["Port Permit"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Port Scale`,
				text: i18n.t("portScale"),
				headerAlign: "center",
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["Port Scale"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.total`,
				text: i18n.t("total"),
				headerAlign: "center",
				csvFormatter: (cell, trip) => {
					return trip.expensesObj["total"] || "";
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Init. Settlement Date`,
				text: i18n.t("init.SettlementDate"),
				headerAlign: "center",
				formatter: (cell, trip) => {
					return this.getEventData(trip, "Initial Settelment");
				},
				csvFormatter: (cell, trip) => {
					return this.getEventData(trip, "Initial Settelment");
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Init. Settlement Date By`,
				text: i18n.t("init.SettlementBy"),
				headerAlign: "center",
				formatter: (cell, trip) => {
					return this.getEventData(trip, "Initial Settelment", true);
				},
				csvFormatter: (cell, trip) => {
					return this.getEventData(trip, "Initial Settelment", true);
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Init. Review Date`,
				text: i18n.t("init.ExpenseReviewDate"),
				headerAlign: "center",
				formatter: (cell, trip) => {
					return this.getEventData(trip, "Expense Review");
				},
				csvFormatter: (cell, trip) => {
					return this.getEventData(trip, "Expense Review");
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Init. Review Date By`,
				text: i18n.t("init.ExpenseReviewBy"),
				headerAlign: "center",
				formatter: (cell, trip) => {
					return this.getEventData(trip, "Expense Review", true);
				},
				csvFormatter: (cell, trip) => {
					return this.getEventData(trip, "Expense Review", true);
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Init. Final Date`,
				text: i18n.t("init.FinalReviewDate"),
				headerAlign: "center",
				formatter: (cell, trip) => {
					return this.getEventData(trip, "Final Settlement");
				},
				csvFormatter: (cell, trip) => {
					return this.getEventData(trip, "Final Settlement");
				},
			},
			{
				align: "center",
				dataField: `expensesObj.Init. Final Date By`,
				text: i18n.t("init.FinalReviewBy"),
				headerAlign: "center",
				formatter: (cell, trip) => {
					return this.getEventData(trip, "Final Settlement", true);
				},
				csvFormatter: (cell, trip) => {
					return this.getEventData(trip, "Final Settlement", true);
				},
			},
			{
				align: "center",
				dataField: `couponNo`,
				text: i18n.t("couponNo"),
				headerAlign: "center",
				csvFormatter: (cell, trip) => {
					return trip.couponNo || "";
				},
			},
			{
				align: "center",
				dataField: `startKM`,
				headerAlign: "center",
				text: i18n.t("startKM"),
			},
			{
				align: "center",
				dataField: `endKM`,
				text: i18n.t("endKM"),
				headerAlign: "center",
			},
		];

		return (
			<Fragment>
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<PageTitleAlt2 heading={i18n.t("workOrdersRawData")} icon="bi bi-kanban icon-gradient bg-happy-fisher" />
					<Row className="mb-3">
						<Col xs="12" md="4">
							<DateRangePicker getdata={this.getDates} />
						</Col>
						<Col xs="12" md="2">
							<Label>{i18n.t("projectType")}</Label>
							<Selector
								clearable
								size="small"
								isObjectOption
								id="projectType"
								optionAccessor="name"
								onChange={this.getValue}
								options={this.state.projectTypes}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col xs="12" md="6">
							<div>
								<Label className="mr-4">{i18n.t("getServiceWO")}</Label>
								<Input id="serviceWO" type="checkbox" name="serviceWO" className="mt-2" onChange={this.setServiceWO} />
								<Button onClick={this.getWOs} className="ml-2">
									{i18n.t("submit")}
								</Button>
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs="12" sm="12" md="12" lg="12" xl="12">
							<Card className="main-card mb-3" key="0">
								<CardBody>
									<TableWithPaginationSearch
										search
										scrollable
										exportable
										columns={columns}
										data={this.state.data}
										loading={this.state.loading}
										filename="Work Orders Raw Data.csv"
									/>
								</CardBody>
							</Card>
						</Col>
						<Col xs="12" sm="12" md="12" lg="12" xl="12">
							<Card className="main-card mb-3" key="0">
								<CardBody>
									<TableWithPaginationSearch
										search
										scrollable
										exportable
										columns={tripsColumns}
										data={this.state.tripsData}
										loading={this.state.loading}
										filename="Trips Raw Data.csv"
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default withRouter(WORawData);
