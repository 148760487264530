import i18n from "i18n";
import axios from "axios";
import Loader from "react-loaders";
import React, { Component } from "react";
import AddIcon from "ops/common/add_icon";
import ViewIcon from "ops/common/view_icon";
import { withRouter } from "react-router-dom";
import DeleteIcon from "ops/common/delete_icon";
import DeletePopup from "ops/common/delete_popup";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import { Card, CardBody, Col, Row } from "reactstrap";
import DateRangePicker from "ops/common/date_range_picker";
import { AlertNotification } from "ops/common/alert_notification";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";

class ListTestDriveRequest extends Component {
	constructor() {
		super();
		this.state = {
			data: [],
			loading: false,
		};
	}

	confirmDeleteTestDriveRequest = (request) => {
		return DeletePopup({
			title: "Are you sure ?",
			cancelbtntxt: "No",
			confirmbtntxt: "Yes",
			text: i18n.t("deleteTestDriveRequestConfirmText") + request.code,
			confirmedresult: () => this.deleteTestDriveRequest(request.id),
		});
	};

	deleteTestDriveRequest = (id) => {
		this.setState({ loading: true });
		axios
			.delete(`testdriverequest/${id}`)
			.then((res) => {
				if (res) {
					AlertNotification(i18n.t("deleted"), "success");
					let data = this.state.data.filter((request) => request.id !== id);
					this.setState({ data: data }, () => {
						this.setState({ loading: false });
					});
				}
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	getTestDriveRequests = (sDate, eDate) => {
		let dateRange = `creationDate__gte=${sDate}&creationDate__lte=${eDate}&`;
		if (!sDate) dateRange = `creationDate__lte=${eDate}&`;
		if (!eDate) dateRange = `creationDate__gte=${sDate}&`;
		if (!sDate && !eDate) dateRange = "";
		this.setState({ loading: true });
		axios
			.get(`testdriverequest/?serializer=list&${dateRange}`)
			.then((res) => {
				this.setState({ data: res.data }, () => {
					this.setState({ loading: false });
				});
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	createSWO = (testDriveRequest) => {
		this.props.history.push({
			pathname: "/newworkorder",
			asset1: testDriveRequest.asset1__id,
			asset2: testDriveRequest.asset2__id,
			tdrID: testDriveRequest.id,
		});
	};

	componentDidMount() {
		this.getTestDriveRequests();
	}

	render() {
		const t = i18n.t;
		const columns = [
			{
				sort: true,
				align: "center",
				dataField: "code",
				text: i18n.t("code"),
				headerAlign: "center",
			},
			{
				sort: true,
				align: "center",
				dataField: "status",
				text: i18n.t("status"),
				headerAlign: "center",
				classes: (cell) => {
					if (cell === "Pending") {
						return "text-danger";
					} else if (cell === "In Progress") {
						return "text-warning";
					} else if (cell === "Closed") {
						return "text-success";
					}
				},
			},
			{
				sort: true,
				align: "center",
				dataField: "creationDate",
				text: i18n.t("requestDate"),
				headerAlign: "center",
			},
			{
				sort: true,
				align: "center",
				dataField: "asset1",
				text: i18n.t("asset1"),
				headerAlign: "center",
			},
			{
				sort: true,
				align: "center",
				dataField: "asset2",
				text: i18n.t("asset2"),
				headerAlign: "center",
			},
			{
				sort: true,
				align: "center",
				dataField: "rfr",
				text: i18n.t("rfr"),
				headerAlign: "center",
			},
			{
				sort: true,
				align: "center",
				dataField: "workOrder",
				text: i18n.t("workOrder"),
				headerAlign: "center",
			},
			{
				dataField: "",
				align: "center",
				headerAlign: "center",
				text: i18n.t("actions"),
				formatter: (cell, row) => {
					return (
						<>
							<GetPermission perm="mntc.view_testdriverequest">
								<ViewIcon code="perm" target="_blank" to={`/view-test-drive-request/?id=${row.id}`} />
							</GetPermission>
							<GetPermission perm="mntc.delete_testdriverequest">
								<div code="perm">
									<DeleteIcon ondelete={() => this.confirmDeleteTestDriveRequest(row)} hidden={row.workOrder} />
								</div>
							</GetPermission>
							{!row.workOrder &&
								((row.asset1 && row.asset1__status === "Available") || (row.asset2 && row.asset2__status === "Available")) && (
									<GetPermission perm="ops.add_workorder">
										<AddIcon code="perm" tooltipText={t("addSWO")} onadd={() => this.createSWO(row)} />
									</GetPermission>
								)}
						</>
					);
				},
			},
		];

		return (
			<>
				<PageTitleAlt2 heading={t("testDriveRequests")} icon="bi bi-card-checklist icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row className="mb-3">
						<Col xs="6" sm="6" md="6" lg="6" xl="6">
							<DateRangePicker getdata={this.getTestDriveRequests} />
						</Col>
					</Row>

					<Row>
						<Col xs="12" sm="12" md="12" lg="12" xl="12">
							<Card className="main-card mb-3" key="0">
								<CardBody>
									<TableWithPaginationSearch
										scrollable
										exportable
										search
										columns={columns}
										data={this.state.data}
										filename="Test drive requests.csv"
										loading={this.state.loading}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}
export default withRouter(ListTestDriveRequest);
