import i18n from "i18n";
import XLSX from "xlsx";
import axios from "axios";
import moment from "moment";
import Loader from "react-loaders";
import React, { Component } from "react";
import Selector from "ops/common/selector";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import { AlertNotification } from "ops/common/alert_notification";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";
import { Button, Card, CardBody, CardFooter, Col, Form, Input, Label, Row } from "reactstrap";

class UploadVendorTrip extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vendors: [],
			projects: [],
			loading: false,
			invalidTrips: [],
			projectsLegs: [],
			uploadedTrips: [],
			selectedVendor: {},
			selectedProject: {},
			invalidVendor: false,
			invalidProject: false,
			selectedProjectLeg: [],
			invalidProjectLeg: false,
			uploadTemplateBtn: false,
			downloadTemplateBtn: true,
		};
	}

	getProjects = () => {
		this.setState({ loading: true });
		axios
			.get(`project/?serializer=}`)
			.then((res) => {
				this.setState({ projects: res.data, loading: false });
			})
			.catch((err) => {
				console.error(err.response);
			});
	};

	getProjectLegs = (id) => {
		this.setState({ loading: true });
		axios
			.get(`projectleg/?serializer=vendortrip&standard__isArchive=false&project_id__in=${id}`)
			.then((res) => {
				this.setState({ projectLegs: res.data, loading: false });
			})
			.catch((err) => {
				console.error(err.response);
			});
	};

	getVendors = () => {
		this.setState({ loading: true });
		axios
			.get(`supplier/`)
			.then((res) => {
				this.setState({ vendors: res.data, loading: false });
			})
			.catch((err) => {
				console.error(err.response);
			});
	};

	getInitialData = () => {
		this.getProjects();
		this.getVendors();
	};

	downloadTemplate = () => {
		let columns = [
			{
				number: "",
				date: moment().format("YYYY-MM-DD"),
				driverName: "",
				truckNumber: "",
				trailerNumber: "",
				//رقم المقطورة
				netWeight: "",
				//صافي الوزن
				waybill: "",
				// رقم بوليصة الشحن
				portScale: "",
				// ميزان المينا
				notes: "",
			},
		];

		let wb = XLSX.utils.book_new();
		let ws = XLSX.utils.json_to_sheet(columns);
		XLSX.utils.book_append_sheet(wb, ws, "template");
		XLSX.writeFile(wb, i18n.t("vendorTripXLSX"));
		this.setState({ downloadTemplateBtn: false, uploadTemplateBtn: true });
	};

	uploadTrips = (evt) => {
		let file = new FileReader();
		file.onload = (e) => {
			let data = e.target.result;
			let workbook = XLSX.read(data, { type: "binary" });
			let trips = XLSX.utils.sheet_to_json(workbook.Sheets["template"]);
			this.setState({ uploadedTrips: trips });
		};
		if (evt.target.files.length > 0) file.readAsBinaryString(evt.target.files[0]);
	};

	selectTemplate = (e) => {
		e.target.value = null;
		this.setState({ uploadedTrips: [], invalidTrips: [] });
	};

	getValue = (e, v) => {
		let target = e.target.id.split("-")[0];
		this.setState({
			[`selected${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: v,
			[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: false,
		});
		if (target === "project") {
			this.getProjectLegs(v.id);
		}
	};

	validate = (e) => {
		let target = e.target.id.split("-")[0];
		this.setState({ [`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: true });
	};

	submit = (e) => {
		e.preventDefault();
		if (
			this.state.selectedProject === {} ||
			this.state.selectedProjectLeg === {} ||
			this.state.selectedVendor === {} ||
			this.state.uploadedTrips.length === 0
		) {
			AlertNotification(i18n.t("allFieldsRequired"), "error");
			return;
		}
		let data = {
			project: this.state.selectedProject.id,
			vendor: this.state.selectedVendor.id,
			projectleg: this.state.selectedProjectLeg.id,
			trips: this.state.uploadedTrips,
		};
		this.setState({ loading: true });
		axios
			.post(`vendortrip/bulkcreate/`, data)
			.then((res) => {
				if (Array.isArray(res.data)) {
					this.setState({ loading: false, invalidTrips: res.data, uploadedTrips: [] });
				} else {
					AlertNotification(i18n.t("submit"), "success");
					this.setState({ loading: false, uploadedTrips: [] });
					this.props.history.push({
						pathname: "/listvendortrips",
						projectID: this.state.selectedProject.id,
						vendorID: this.state.selectedVendor.id,
					});
				}
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(null, "error", err.response.status);
				this.setState({ loading: false });
			});
	};

	componentDidMount() {
		this.getInitialData();
	}

	render() {
		const t = i18n.t;
		const tripsColumns = [
			{ sort: true, align: "center", dataField: "number", headerAlign: "center", text: t("number") },
			{ sort: true, align: "center", dataField: "date", headerAlign: "center", text: t("date") },
			{ sort: true, align: "center", dataField: "driverName", headerAlign: "center", text: t("driverName") },
			{ sort: true, align: "center", dataField: "truckNumber", headerAlign: "center", text: t("truckNumber") },
			{ sort: true, align: "center", dataField: "trailerNumber", headerAlign: "center", text: t("trailerNumber") },
			{ sort: true, align: "center", dataField: "netWeight", headerAlign: "center", text: t("netWeight") },
			{ sort: true, align: "center", dataField: "waybill", headerAlign: "center", text: t("waybill") },
			{ sort: true, align: "center", dataField: "portScale", headerAlign: "center", text: t("portScale") },
			{ sort: true, align: "center", dataField: "notes", headerAlign: "center", text: t("notes") },
		];

		const tripsErrorColumns = [
			{ sort: true, align: "center", dataField: "number", headerAlign: "center", text: t("number") },
			{ sort: true, align: "center", dataField: "error", headerAlign: "center", text: t("error") },
		];

		return (
			<>
				<PageTitleAlt2 heading={t("uploadVendorTrip")} icon="bi bi-truck icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.formLoading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row>
						{this.state.downloadTemplateBtn && (
							<Col xs="12" sm="12" md="3" lg="3" xl="3" className="m-2">
								<GetPermission perm="ops.add_vendortrip">
									<Button onClick={this.downloadTemplate} color="info" code="perm">
										{t("downloadTemplate")}
									</Button>
								</GetPermission>
							</Col>
						)}
						<Col xs="12" hidden={this.state.downloadTemplateBtn}>
							<Card className="main-card">
								<CardBody>
									<Form onSubmit={this.submit} onInvalid={this.validate}>
										<Row>
											<Col xs="12" md="6" className="mt-1">
												<Label>{t("project")}</Label>
												<Selector
													required
													size="small"
													id="project"
													isObjectOption
													optionAccessor="code"
													onChange={this.getValue}
													error={this.state.invalidProject}
													options={this.state.projects}
												/>
											</Col>
											<Col xs="12" md="6" className="mt-1">
												<Label>{t("projectLeg")}</Label>
												<Selector
													required
													id="projectLeg"
													size="small"
													isObjectOption
													optionAccessor="legName"
													onChange={this.getValue}
													error={this.state.invalidProjectLeg}
													options={this.state.projectLegs}
												/>
											</Col>
										</Row>
										<Row>
											<Col xs="12" md="6" className="mt-1">
												<Label>{t("vendor")}</Label>
												<Selector
													required
													id="vendor"
													size="small"
													isObjectOption
													optionAccessor="name"
													onChange={this.getValue}
													error={this.state.invalidVendor}
													options={this.state.vendors}
												/>
											</Col>
										</Row>
										<CardFooter className="d-flex justify-content-between mt-3">
											<Col xs="6" md="3" className="mt-1">
												<Input type="file" onClick={this.selectTemplate} onChange={this.uploadTrips} />
											</Col>
											<Button type="submit" color="info">
												{t("submit")}
											</Button>
										</CardFooter>
									</Form>
								</CardBody>
							</Card>
						</Col>
						{this.state.uploadedTrips.length !== 0 && (
							<Col xs="12" className="mt-2">
								<Card className="main-card mb-3">
									<CardBody>
										<TableWithPaginationSearch
											scrollable
											keyfield="number"
											columns={tripsColumns}
											data={this.state.uploadedTrips}
											loading={this.state.loading}
										/>
									</CardBody>
								</Card>
							</Col>
						)}
						{this.state.invalidTrips.length !== 0 && (
							<Col xs="12" sm="12" md="12" lg="12" xl="12" className="mt-2">
								<Card className="main-card mb-3">
									<CardBody>
										<TableWithPaginationSearch
											scrollable
											exportable
											filename="invalidvendortrips.csv"
											keyfield="number"
											columns={tripsErrorColumns}
											data={this.state.invalidTrips}
											loading={this.state.loading}
										/>
									</CardBody>
								</Card>
							</Col>
						)}
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}

export default withRouter(UploadVendorTrip);
