import i18n from "i18n";
import axios from "axios";
import moment from "moment";
import ViewIcon from "ops/common/view_icon";
import { withRouter } from "react-router-dom";
import DeleteIcon from "ops/common/delete_icon";
import DeletePopup from "ops/common/delete_popup";
import React, { Component, Fragment } from "react";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import { Card, CardBody, Col, Row } from "reactstrap";
import GetPermission from "ops/common/get_permission";
import BootstrapTable from "react-bootstrap-table-next";
import DateRangePicker from "ops/common/date_range_picker";
import { AlertNotification } from "ops/common/alert_notification";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";

class ViewNonPlannedWOList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			wos: [],
			loading: false,
		};
	}

	getWOs = (sDate, eDate) => {
		this.setState({ loading: true });
		axios.get(`workorder/?serializer=list&startDate__gte=${sDate}&startDate__lte=${eDate}`).then((res) => {
			this.setState({
				wos: res.data,
				loading: false,
			});
		});
	};

	getWOsInitialData = () => {
		this.setState({ loading: true });
		axios.get(`workorder/?serializer=list&endDate__isnull=true`).then((res) => {
			this.setState({
				wos: res.data,
				loading: false,
			});
		});
	};

	confirmDeleteWorkOrder = (workorder) => {
		return DeletePopup({
			title: "Are you sure ?",
			cancelbtntxt: "No",
			confirmbtntxt: "Yes",
			text: `This will delete Work Order ${workorder.code}`,
			confirmedresult: () => this.deleteWorkOrder(workorder.id),
		});
	};

	deleteWorkOrder = (workorderID) => {
		this.setState({ loading: true });
		axios
			.delete(`workorder/${workorderID}`)
			.then((res) => {
				if (res) {
					AlertNotification(i18n.t("deleted"), "success");
					let workorders = [...this.state.wos].filter((workorder) => workorder.id !== workorderID);
					this.setState({ wos: workorders }, () => {
						this.setState({ loading: false });
					});
				}
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	componentDidMount = () => {
		this.getWOsInitialData();
	};

	render() {
		const t = i18n.t;
		const headerFormatter = (column) => <div className="info-header">{column.text}</div>;
		const woColumns = [
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "code",
				text: t("code"),
			},
			{
				sort: true,
				align: "center",
				dataField: "project",
				headerAlign: "center",
				text: t("projectCode"),
			},
			{
				sort: true,
				align: "center",
				dataField: "project__description",
				headerAlign: "center",
				text: t("projectDescription"),
			},
			{
				sort: true,
				align: "center",
				dataField: "status",
				headerAlign: "center",
				text: t("status"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "startDate",
				text: t("startDate"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "endDate",
				text: t("endDate"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "startKM",
				text: t("startKM"),
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "endKM",
				text: t("endKM"),
				formatter: (cell) => {
					if (cell) return Number(cell).toLocaleString();
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "driver",
				text: t("driver"),
			},
			{
				hidden: true,
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "assisstant",
				text: t("assistant"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "asset.code",
				text: t("asset"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "attachedAsset.code",
				text: t("attachedAsset"),
			},
			{
				sort: true,
				align: "center",
				dataField: "tripsCount",
				headerAlign: "center",
				text: t("tripsCount"),
				formatter: (cell, row) => {
					return row.trips.length;
				},
			},
			{
				align: "center",
				headerAlign: "center",
				dataField: "actions",
				text: t("actions"),
				formatter: (cell, wo) => {
					return (
						<>
							<GetPermission perm="ops.view_workorder">
								<ViewIcon code="perm" target="_blank" to={`/viewworkorder/?id=${wo.id}`} />
							</GetPermission>
							<GetPermission perm="ops.delete_workorder">
								<div code="perm">
									<DeleteIcon ondelete={() => this.confirmDeleteWorkOrder(wo)} hidden={!wo.deletable} />
								</div>
							</GetPermission>
						</>
					);
				},
			},
		];
		const tripColumns = [
			{
				sort: true,
				editable: false,
				align: "center",
				dataField: "number",
				headerAlign: "center",
				text: i18n.t("number"),
				headerFormatter: headerFormatter,
			},
			{
				sort: true,
				align: "center",
				dataField: "startKM",
				headerAlign: "center",
				text: i18n.t("startKM"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				dataField: "endKM",
				headerAlign: "center",
				text: i18n.t("endKM"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "distance",
				text: i18n.t("distance"),
				formatter: (cell, trip, index) => {
					let distance = trip["endKM"] - trip["startKM"];
					return distance;
				},
				style: (cell, trip) => {
					let distance = trip["endKM"] - trip["startKM"];
					if (!(distance - trip["tripDistance"] >= -10 && distance - trip["tripDistance"] <= 10)) {
						return { color: "red" };
					}
				},
				headerFormatter: headerFormatter,
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "loadedQTY",
				text: i18n.t("loadedQTY"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "deliveredQTY",
				text: i18n.t("deliveredQTY"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "difference",
				text: i18n.t("difference"),
				formatter: (cell, trip, index) => {
					let difference = trip["loadedQTY"] - trip["deliveredQTY"];
					if (isNaN(difference)) {
						return "-";
					}
					return Number(difference).toLocaleString();
				},
				style: (cell, trip) => {
					let difference = trip["loadedQTY"] - trip["deliveredQTY"];
					if (Math.sign(difference) === -1) {
						return { color: "red" };
					}
				},
				headerFormatter: headerFormatter,
			},
			{
				align: "center",
				editable: false,
				headerAlign: "center",
				dataField: "loadingDate",
				text: i18n.t("loadingDate"),
				formatter: (cell) => {
					if (cell) {
						return moment(cell, "YYYY-MM-DDTHH:mm").format("DD-MM-YYYY hh:mm A");
					} else return cell;
				},
				headerFormatter: headerFormatter,
			},
			{
				align: "center",
				editable: false,
				headerAlign: "center",
				dataField: "unLoadingDate",
				text: i18n.t("unLoadingDate"),
				formatter: (cell) => {
					if (cell) {
						return moment(cell, "YYYY-MM-DDTHH:mm").format("DD-MM-YYYY hh:mm A");
					} else return cell;
				},
				headerFormatter: headerFormatter,
			},
			{
				sort: true,
				editable: false,
				align: "center",
				headerAlign: "center",
				dataField: "leg",
				text: i18n.t("leg"),
				formatter: (cell, trip, index) => {
					if (trip.projectLeg) {
						return trip.projectLeg;
					} else {
						return trip.leg;
					}
				},
				headerFormatter: headerFormatter,
			},
			{
				align: "center",
				editable: false,
				dataField: "owner.fullName",
				headerAlign: "center",
				text: i18n.t("owner"),
				headerFormatter: headerFormatter,
			},
		];
		const expandRow = {
			renderer: (row) => <BootstrapTable keyField="id" condensed={true} data={row.trips} columns={tripColumns} />,
		};

		return (
			<Fragment>
				<PageTitleAlt2 heading={t("workOrders")} icon="bi bi-hdd-network icon-gradient bg-happy-fisher" />
				<Row className="mb-3">
					<Col xs="6">
						<DateRangePicker getdata={this.getWOs} />
					</Col>
				</Row>
				<Row>
					<Col xs="12">
						<Card className="main-card mb-3" key="0">
							<CardBody>
								<TableWithPaginationSearch
									scrollable
									search
									exportable
									filename="workorders.csv"
									keyfield="id"
									columns={woColumns}
									data={this.state.wos}
									expandRow={expandRow}
									loading={this.state.loading}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Fragment>
		);
	}
}

export default withRouter(ViewNonPlannedWOList);
