import DropDown from "./drop_down";
import { Loader } from "react-loaders";
import ExportIcon from "./export_icon";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import BootstrapTable from "react-bootstrap-table-next";
import { InputGroup, InputGroupAddon } from "reactstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useTranslation, withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
import { Row, Col, Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap";

class TableWithPaginationSearch extends Component {
	render() {
		const { t } = this.props;
		const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
			<UncontrolledButtonDropdown>
				<DropdownToggle className="mb-2 mx-2" color={this.props.columnTogglerColor || "info"}>
					{this.props.columnsLabel || t("columns")}
					<i className="bi bi-caret-down-fill"></i>
				</DropdownToggle>
				<DropdownMenu right className="dropdown-menu-rounded">
					{columns
						.map((column) => ({
							...column,
							toggle: toggles[column.dataField],
						}))
						.map((column) => (
							<DropdownItem key={column.dataField}>
								<Input type="checkbox" checked={column.toggle} onChange={() => onColumnToggle(column.dataField)} />
								{t(column.text)}
							</DropdownItem>
						))}
				</DropdownMenu>
			</UncontrolledButtonDropdown>
		);

		const indexColumn = [
			{
				dataField: "index",
				csvExport: false,
				text: "#",
				formatter: (cell, row, rowIndex) => {
					return rowIndex + 1;
				},
			},
		];

		return (
			<ToolkitProvider
				search
				keyField="id"
				data={this.props.data}
				columns={this.props.scrollable ? [...indexColumn, ...this.props.columns] : this.props.columns}
				exportCSV={{
					separator: ",",
					noAutoBOM: false,
					exportAll: false,
					fileName: this.props.filename ? this.props.filename : "download.csv",
				}}
				columnToggle>
				{(props) => (
					<Row className="my-2">
						<Col xs="7" sm="7" md="7" lg="7" xl="7" hidden={!this.props.search}>
							<MySearchInputField {...props.searchProps} placeholder={this.props.placeholder} />
						</Col>
						{/*Filters*/}

						<Col xs="12" md={this.props.search ? "5" : "12"}>
							<div className="float-right">
								<DropDown
									right={true}
									title={this.props.filterTitle || "Filters"}
									onclick={this.props.onSelectFilter}
									values={this.props.filtersOptions || []}
									hide={!this.props.filtersOptions}
								/>
								{this.props.columnToggle === false ? <></> : <CustomToggleList {...props.columnToggleProps} />}
								<CSVExportIcon hidden={!this.props.exportable} {...props.csvProps} />
								<Button hidden={!this.props.addNew} onClick={this.props.onCLickAddNew} color={"info"}>
									{t("addButton")}
								</Button>
							</div>
						</Col>

						<Col xs="12" sm="12" md="12" lg="12" xl="12">
							<PaginationProvider
								pagination={paginationFactory({
									custom: true,
									sizePerPage: this.props.pagination ? this.props.sizePerpage : this.props.data.length,
									showTotal: false,
									pageStartIndex: 1,
									prePageText: "Back",
									nextPageText: "Next",
									lastPageText: "Last",
									hideSizePerPage: true,
									firstPageText: "First",
									alwaysShowAllBtns: true,
									prePageTitle: "Pre page",
									lastPageTitle: "Last page",
									nextPageTitle: "First page",
									firstPageTitle: "Next page",
									hidePageListOnlyOnePage: true,
									totalSize: this.props.data.length,
								})}>
								{({ paginationProps, paginationTableProps }) => (
									<div className={this.props.scrollable ? "table-responsive scrollable-table" : "table-responsive"}>
										<BootstrapTable
											classes={this.props.classname}
											hover
											striped
											condensed
											bordered={false}
											{...props.baseProps}
											{...paginationTableProps}
											bodyClasses={`text-center ${this.props.bodyClassname}`}
											headerClasses={this.props.scrollable ? "text-center table-header-sticky" : "text-center"}
											cellEdit={this.props.celledit}
											rowEvents={this.props.rowEvents}
											selectRow={this.props.selectRow}
											expandRow={this.props.expandRow}
											hiddenRows={this.props.hiddenRows}
											keyField={this.props.keyfield || "id"}
											noDataIndication={() => (
												<NoDataIndication loading={this.props.loading} message={this.props.noDataMessage} />
											)}
										/>
										{!this.props.scrollable && (
											<div style={{ marginLeft: "36%" }}>
												<PaginationListStandalone style={{ padding: "revert" }} {...paginationProps} />
											</div>
										)}
									</div>
								)}
							</PaginationProvider>
						</Col>
					</Row>
				)}
			</ToolkitProvider>
		);
	}
}

// Custom csv export component
const CSVExportIcon = (props) => {
	const handleClick = () => {
		props.onExport();
	};
	return <ExportIcon cssclass="export-btn-table" onclick={handleClick} hidden={props.hidden} />;
};

// Customise Table Search Field
const MySearchInputField = (props) => {
	const { t } = useTranslation();
	let input;
	const handleSearch = () => props.onSearch(input.value);
	return (
		<div className="form-group has-search float-left">
			<InputGroup>
				<input
					type="text"
					placeholder={props.placeholder || t("searchField")}
					onChange={handleSearch}
					ref={(n) => (input = n)}
					className="float-right form-control border-right-0"
				/>
				<InputGroupAddon addonType="prepend">
					<div className="input-group-text bg-transparent py-2 border-left-0">
						<FontAwesomeIcon icon={faSearch} style={{ opacity: 0.7 }} />
					</div>
				</InputGroupAddon>
			</InputGroup>
		</div>
	);
};

// This For loading icon and No Data when data is empty
const NoDataIndication = (props) => {
	const { t } = useTranslation();
	return (
		<div>
			<div className="text-center" hidden={props.loading}>
				{props.message || t("noData")}
			</div>
			<LoadingOverlay
				tag="div"
				active={props.loading}
				styles={{
					overlay: (base) => ({
						...base,
						background: "#fff",
						opacity: 0.5,
					}),
				}}
				spinner={<Loader active type={"ball-clip-rotate"} />}></LoadingOverlay>
		</div>
	);
};

export default withTranslation()(TableWithPaginationSearch);
