import i18n from "i18n";
import axios from "axios";
import Loader from "react-loaders";
import React, { Component } from "react";
import Selector from "ops/common/selector";
import { withRouter } from "react-router-dom";
import TripExpenseForm from "./trip_expense_form";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import { AlertNotification } from "ops/common/alert_notification";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";
import { Row, Card, CardBody, Col, Label, Button, CardFooter, Input } from "reactstrap";

class ListVendorTrips extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vendors: [],
			projects: [],
			loading: false,
			vendorTrips: [],
			selectedVendor: {},
			selectedProject: {},
			postExpenses: false,
			initExpenses: false,
			selectedVendorTrips: {},
			vendorTripExpenses: [],
			vendorTripsexpenses: [],
			projectsExpenseTypes: [],
		};
		this.expensesCount = 0;
		this.expenses = [];
	}

	getProjects = () => {
		this.setState({ loading: true });
		axios
			.get(`project/?serializer=`)
			.then((res) => {
				this.setState({ projects: res.data, loading: false });
				if (this.props.history.location) {
					this.setState({
						selectedProject: res.data.filter((project) => project.id === this.props.history.location.projectID)[0],
					});
				}
			})
			.catch((err) => {
				console.error(err.response);
			});
	};

	getVendors = () => {
		this.setState({ loading: true });
		axios
			.get(`supplier/`)
			.then((res) => {
				this.setState({ vendors: res.data, loading: false });
				if (this.props.history.location) {
					this.setState({ selectedVendor: res.data.filter((vendor) => vendor.id === this.props.history.location.vendorID)[0] });
				}
			})
			.catch((err) => {
				console.error(err.response);
			});
	};

	getInitialData = () => {
		this.getProjects();
		this.getVendors();
	};

	getValue = (e, v) => {
		let target = e.target.id.split("-")[0];
		this.setState({ [`selected${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: v });
	};

	getVendorTrips = (projectID, vendorID) => {
		if (!projectID || !vendorID) {
			if (!this.state.selectedProject || !this.state.selectedVendor) {
				AlertNotification(i18n.t("allFieldsRequired"), "warning");
				return;
			}
			projectID = this.state.selectedProject.id;
			vendorID = this.state.selectedVendor.id;
		}

		axios
			.get(`vendortrip/?serializer=get&project=${projectID}&vendor=${vendorID}`)
			.then((res) => {
				let data = res.data.map((trip, index) => {
					return { ...trip, index: index };
				});
				this.setState({ vendorTrips: data, initExpenses: false });
				if (res.data.length === 0) {
					AlertNotification(i18n.t("noVendorTrips"), "warning");
				}
			})
			.catch((err) => {
				console.error(err.response);
			});
	};

	initExpenses = () => {
		this.setState({ loading: true });
		axios
			.post(`project/getExpenseTypes/`, { projectsCodes: [this.state.selectedProject.code] })
			.then((res) => {
				if (res)
					this.setState({ projectsExpenseTypes: res.data[this.state.selectedProject.code], loading: false, initExpenses: true });
			})
			.catch((err) => {
				if (err) {
					console.error(err.response);
					AlertNotification(null, "error", err.response.status);
					this.setState({ loading: false });
				}
			});
	};

	postExpenses = () => {
		this.setState({ postExpenses: true });
	};

	getExpenseValue = (tripexpenses) => {
		this.expenses.push(...tripexpenses);
		this.expensesCount = this.expensesCount + 1;
		if (this.expensesCount !== 0 && this.expensesCount === Object.keys(this.state.selectedVendorTrips).length) {
			this.submitExpenses();
		}
	};

	submitExpenses = () => {
		let allExpenses = [...this.expenses];
		this.setState({ postExpenses: false });
		this.expensesCount = 0;
		this.expenses = [];
		this.setState({ loading: true });
		axios
			.post(`expense/`, { allExpenses: allExpenses })
			.then((res) => {
				if (res) {
					this.setState({ loading: false, vendorTrips: [], selectedVendorTrips: {}, initExpenses: false });
					AlertNotification(i18n.t("submit"), "success");
				}
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response.status);
					this.setState({ loading: false });
					console.error(err.response);
				}
			});
	};

	selectTrip = (e) => {
		let trips = { ...this.state.selectedVendorTrips };
		let vendorTrips = [...this.state.vendorTrips];
		let index = e.target.getAttribute("index");
		let trip = vendorTrips[index];
		if (e.target.checked) {
			trips[trip.id] = trip;
		} else {
			delete trips[trip.id];
		}
		this.setState({ selectedVendorTrips: trips });
	};

	deleteTrips = (e) => {
		let trips = Object.keys(this.state.selectedVendorTrips);
		this.setState({ loading: true });
		axios
			.delete(`vendortrip/bulkDelete/`, { data: trips })
			.then((res) => {
				this.setState({ selectedVendorTrips: {}, loading: false });
				AlertNotification(i18n.t("deleted"), "success");
				this.getVendorTrips(this.state.selectedProject.id, this.state.selectedVendor.id);
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(null, "error", err.response.status);
			});
	};

	componentDidMount() {
		this.getInitialData();
		if (this.props.history.location.projectID) {
			this.getVendorTrips(this.props.history.location.projectID, this.props.history.location.vendorID);
		}
	}

	render() {
		const t = i18n.t;
		const tripsColumns = [
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "selects",
				text: t("select"),
				formatter: (cell, trip, index) => (
					<Input
						style={{ position: "relative" }}
						type="checkbox"
						disabled={trip["expenses"].length !== 0}
						onChange={this.selectTrip}
						index={trip.index}
					/>
				),
			},
			{ sort: true, align: "center", dataField: "number", headerAlign: "center", text: t("number"), formatter: (cell) => cell },
			{ sort: true, align: "center", dataField: "date", headerAlign: "center", text: t("date") },
			{ sort: true, align: "center", dataField: "driverName", headerAlign: "center", text: t("driverName") },
			{ sort: true, align: "center", dataField: "truckNumber", headerAlign: "center", text: t("truckNumber") },
			{ sort: true, align: "center", dataField: "trailerNumber", headerAlign: "center", text: t("trailerNumber") },
			{ sort: true, align: "center", dataField: "projectLeg", headerAlign: "center", text: t("projectLeg") },
			{ sort: true, align: "center", dataField: "netWeight", headerAlign: "center", text: t("netWeight") },
			{ sort: true, align: "center", dataField: "waybill", headerAlign: "center", text: t("waybill") },
			{ sort: true, align: "center", dataField: "portScale", headerAlign: "center", text: t("portScale") },
			{ sort: true, align: "center", dataField: "notes", headerAlign: "center", text: t("notes") },
		];

		return (
			<>
				<PageTitleAlt2 heading={t("listVendorTrip")} icon="bi bi-truck icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.formLoading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row>
						<Col xs="12">
							<Card className="main-card">
								<CardBody>
									<Row>
										<Col xs="12" md="6" className="mt-1">
											<Label>{t("project")}</Label>
											<Selector
												required
												size="small"
												id="project"
												isObjectOption
												optionAccessor="code"
												onChange={this.getValue}
												options={this.state.projects}
												value={this.state.selectedProject || { id: 0, code: "" }}
											/>
										</Col>
										<Col xs="12" md="6" className="mt-1">
											<Label>{t("vendor")}</Label>
											<Selector
												required
												id="vendor"
												size="small"
												isObjectOption
												optionAccessor="name"
												onChange={this.getValue}
												options={this.state.vendors}
												value={this.state.selectedVendor || { id: 0, fullName: "" }}
											/>
										</Col>
									</Row>
									<CardFooter className="d-flex justify-content-end mt-3">
										{Object.keys(this.state.selectedVendorTrips).length !== 0 && (
											<GetPermission perm="ops.delete_expense">
												<Button
													type="button"
													color="dark"
													onClick={this.deleteTrips}
													className="mr-2"
													code="perm"
													disabled={Object.keys(this.state.selectedVendorTrips).length === 0}>
													{t("deleteSelected")}
												</Button>
											</GetPermission>
										)}
										{Object.keys(this.state.selectedVendorTrips).length !== 0 && (
											<GetPermission perm="ops.add_expense">
												<Button
													type="button"
													color="danger"
													onClick={this.initExpenses}
													className="mr-2"
													code="perm"
													disabled={Object.keys(this.state.selectedVendorTrips).length === 0}>
													{t("expenses")}
												</Button>
											</GetPermission>
										)}
										<GetPermission perm="ops.view_vendortrip">
											<Button type="button" color="info" onClick={() => this.getVendorTrips()} code="perm">
												{t("view")}
											</Button>
										</GetPermission>
									</CardFooter>
								</CardBody>
							</Card>
						</Col>
						{this.state.initExpenses && (
							<Col xs="12" className="mt-3">
								<Card className="main-card mb-3">
									<CardBody>
										<div className="dropdown-menu-header m-0">
											<div className="dropdown-menu-header-inner m-0 rounded bg-primary">
												<div className="menu-header-content">
													<h5 className="menu-header-title">{t("vendorTripsExpenses")}</h5>
												</div>
											</div>
										</div>
										<Row className="mt-2">
											{Object.values(this.state.selectedVendorTrips).map((trip, index) => {
												return (
													<Col xs="12" className="mt-1" key={index}>
														<TripExpenseForm
															trip={trip}
															tripindex={index}
															tripid={trip.id}
															postExpenses={this.state.postExpenses}
															getValue={this.getExpenseValue}
															expensetypes={this.state.projectsExpenseTypes}
														/>
													</Col>
												);
											})}
										</Row>
									</CardBody>
									<CardFooter className="d-flex justify-content-end">
										<GetPermission perm="ops.add_expense">
											<Button code="perm" className="ml-2" color="info" onClick={this.postExpenses}>
												{t("submit")}
											</Button>
										</GetPermission>
									</CardFooter>
								</Card>
							</Col>
						)}
						{this.state.vendorTrips.length !== 0 && (
							<Col xs="12" className="mt-2">
								<Card className="main-card mb-3">
									<CardBody>
										<TableWithPaginationSearch
											scrollable
											exportable
											filename="vendortrips.csv"
											search
											columns={tripsColumns}
											data={this.state.vendorTrips}
											loading={this.state.loading}
											keyfield="id"
										/>
									</CardBody>
								</Card>
							</Col>
						)}
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}

export default withRouter(ListVendorTrips);
