import i18n from "i18n";
import axios from "axios";
import Loader from "react-loaders";
import React, { Component } from "react";
import StandardForm from "./standard_form";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AlertNotification } from "ops/common/alert_notification";

class NewStandard extends Component {
	constructor() {
		super();
		this.state = {
			uoms: [],
			legs: [],
			loading: false,
			commodities: [],
			newStandard: {},
			commodityTypes: [],
		};
	}

	getValue = (e, v) => {
		let value;
		let target;
		let newStandard = { ...this.state.newStandard };
		if (v) {
			target = e.target.id.split("-")[0];
			value = v.id;
			newStandard[`${target}Object`] = v;
		} else {
			target = e.target.id;
			value = e.target.value;
		}
		newStandard[target] = value;
		newStandard[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
		this.setState({ newStandard: newStandard });
	};

	validate = (e) => {
		let target = e.target.id;
		if (!target) target = e.target.id.split("-")[0];
		let newStandard = { ...this.state.newStandard };
		newStandard[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		this.setState({ newStandard: newStandard });
	};

	onSubmit = (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		axios
			.post(`standard/?serializer=`, this.state.newStandard)
			.then((res) => {
				AlertNotification(i18n.t("create"), "success");
				this.setState({ loading: false });
				this.props.history.push({ pathname: "/liststandard" });
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	getData = () => {
		axios.get(`leg`).then((res) => {
			this.setState({ legs: this.state.legs.concat(res.data) });
		});
		axios.get(`uom/?serializer=`).then((res) => {
			this.setState({ uoms: this.state.uoms.concat(res.data) });
		});
		axios.get(`commoditytype`).then((res) => {
			this.setState({ commodityTypes: res.data });
		});
	};

	getCommodities = (e, v) => {
		if (v) {
			axios.get(`commodity/?commodityType_id__name=${v.name}`).then((res) => {
				this.setState({ commodities: this.state.commodities.concat(res.data) });
			});
		} else this.setState({ commodities: [] });
	};

	calculateTripTime = () => {
		//todo
		// T = (leg.distance/avg. speed) hr + loading time min/60 hr + discharging time min/60 hr
		// T * 60 min
	};

	componentDidMount() {
		this.getData();
	}

	render() {
		const t = i18n.t;
		return (
			<>
				<PageTitleAlt2 heading={t("newStandard")} icon="bi bi-ui-radios icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row className="m-3 justify-content-center">
						<Col xs="12">
							<Card>
								<CardBody>
									<StandardForm
										getValue={this.getValue}
										validate={this.validate}
										uoms={this.state.uoms}
										commodities={this.state.commodities}
										getCommodities={this.getCommodities}
										onSubmit={this.onSubmit}
										commodityTypes={this.state.commodityTypes}
										legs={this.state.legs}
										newStandard={this.state.newStandard}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}

export default withRouter(NewStandard);
