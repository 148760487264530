import React, { Component } from "react";
import NewCommodityType from "../components/new_commodity_type";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

class NewCommodityTypeScene extends Component {
	render() {
		return (
			<CSSTransitionGroup
				component="div"
				transitionAppear={true}
				transitionEnter={false}
				transitionLeave={false}
				transitionAppearTimeout={0}
				transitionName="TabsAnimation">
				<NewCommodityType />
			</CSSTransitionGroup>
		);
	}
}

export default NewCommodityTypeScene;
