import i18n from "i18n";
import React from "react";
import { Row, Col, Card, CardBody, CardHeader, Collapse, Button, ListGroupItem, Badge } from "reactstrap";

class LegCard extends React.Component {
	constructor() {
		super();
		this.state = {
			isToggled: false,
		};
	}
	render() {
		const t = i18n.t;
		const keys = Object.keys(this.props.data);
		return (
			this.props.data && (
				<div id="accordion" className="accordion-wrapper mb-3">
					<Card>
						<CardHeader id="headingOne">
							<Button
								block
								color="link"
								onClick={() => {
									this.setState({ isToggled: !this.state.isToggled });
								}}
								className="text-left m-0 p-0"
								aria-expanded={this.state.isToggled}>
								<h5 className="m-0 p-0">{this.props.data.leg.name}</h5>
							</Button>
						</CardHeader>
						<Collapse
							id="collapseOne"
							data-parent="#accordion"
							aria-labelledby="headingOne"
							isOpen={this.state.isToggled}>
							<CardBody>
								<Row>
									{keys.map((key) => {
										if (key === "project" || key === "leg" || key === "id") {
											return <Col hidden key={key}></Col>;
										} else if (key === "isDead" || key === "isStart" || key === "isEnd") {
											return (
												<Col
													lg="6"
													xs="12"
													key={key}
													hidden={this.props.data[key] ? false : true}
													className="mt-1">
													<ListGroupItem className="justify-content-between">
														{t(key)} <Badge pill>{this.props.data[key] ? "Yes" : "No"}</Badge>{" "}
													</ListGroupItem>
												</Col>
											);
										} else {
											return (
												<Col
													xs="12"
													lg="6"
													key={key}
													hidden={this.props.data[key] ? false : true}
													className="mt-1">
													<ListGroupItem className="justify-content-between">
														{t(key)} <Badge pill>{this.props.data[key]}</Badge>{" "}
													</ListGroupItem>
												</Col>
											);
										}
									})}
								</Row>
							</CardBody>
						</Collapse>
					</Card>
				</div>
			)
		);
	}
}
export default LegCard;
