import i18n from "i18n";
import moment from "moment";
import React, { Component } from "react";
import Selector from "ops/common/selector";
import GetPermission from "ops/common/get_permission";
import { Button, Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

class DriverForm extends Component {
	render() {
		const t = i18n.t;
		return (
			<Row>
				<Col>
					<Form onSubmit={this.props.submit} onInvalid={this.props.validate}>
						<Card className="rounded pr-4">
							<CardBody>
								<Row className="my-1">
									<Col xs="12" md="4" lg="4" className="my-1 d-flex">
										<Label className="mx-1">{t("name")}</Label>
										<Input
											required
											bsSize="sm"
											type="text"
											defaultValue={this.props.driver.first_name}
											onChange={this.props.getUpdateValue}
											minLength={3}
											name="name"
											invalid={this.props.driver["invalidName"]}
										/>
									</Col>
									<Col xs="12" md="4" lg="4" className="my-1 d-flex">
										<Label className="mx-1">{t("username")}</Label>
										<Input
											required
											bsSize="sm"
											type="text"
											defaultValue={this.props.driver.username}
											onChange={this.props.getUpdateValue}
											name="username"
											minLength={3}
											invalid={this.props.driver["invalidUsername"]}
										/>
									</Col>
									<Col xs="12" md="4" lg="4" className="my-1 d-flex">
										<Label className="mx-1">{t("phoneNumber")}</Label>
										<Input
											bsSize="sm"
											type="text"
											defaultValue={this.props.driver.profile.phoneNumber}
											onChange={this.props.getUpdateProfileValue}
											name="phoneNumber"
											minLength={11}
											invalid={this.props.driver["invalidPhoneNumber"]}
										/>
									</Col>
									<Col xs="12" md="4" lg="4" className="my-1 d-flex">
										<Label className="mx-1">{t("hrCode")}</Label>
										<Input
											defaultValue={this.props.driver.profile.hrCode}
											bsSize="sm"
											type="text"
											onChange={this.props.getUpdateProfileValue}
											name="hrCode"
											invalid={this.props.driver["invalidHrCode"]}
										/>
									</Col>
									<Col xs="12" md="4" lg="4" className="my-1 d-flex">
										<Label className="mx-1">{t("email")}</Label>
										<Input
											defaultValue={this.props.driver.email}
											bsSize="sm"
											type="email"
											onChange={this.props.getUpdateValue}
											name="email"
											invalid={this.props.driver["invalidEmail"]}
										/>
									</Col>
									<Col xs="12" md="4" lg="4" className="my-1 d-flex">
										<Label className="mx-1">{t("license/idNumber")}</Label>
										<Input
											required
											bsSize="sm"
											type="text"
											defaultValue={this.props.driver.profile.license.number}
											onChange={this.props.getUpdateLicenseValue}
											name="number"
											invalid={this.props.driver["invalidNumber"]}
										/>
									</Col>
									<Col xs="12" md="4" lg="4" className="my-1 d-flex">
										<Label className="mx-1">{t("license/idExpiryDate")}</Label>
										<Input
											required
											bsSize="sm"
											type="date"
											defaultValue={moment(this.props.driver.profile.license.expiryDate).format("YYYY-MM-DD")}
											onChange={this.props.getUpdateLicenseValue}
											name="expiryDate"
											invalid={this.props.driver["invalidExpiryDate"]}
										/>
									</Col>
									<Col xs="12" md="4" lg="4" className="my-1 d-flex">
										<Label className="mx-1">{t("hiringDate")}</Label>
										<Input
											bsSize="sm"
											type="date"
											defaultValue={moment(this.props.driver.profile.hiringDate).format("YYYY-MM-DD")}
											onChange={this.props.getUpdateProfileValue}
											name="hiringDate"
										/>
									</Col>
									<Col xs="12" md="4" lg="4" className="my-1 d-flex">
										<Label className="mx-1">{t("licenseClassification")}</Label>
										<Selector
											size="small"
											required
											isObjectOption
											options={this.props.licenseClassifications}
											optionAccessor="name"
											defaultValue={this.props.driver.profile.license.licenseClassification}
											onChange={this.props.getUpdateLicenseValue}
											id="licenseClassification"
										/>
									</Col>
									<Col xs="12" md="4" lg="4" className="my-1 d-flex">
										<Label className="mx-1">{t("assetType")}</Label>
										<Selector
											size="small"
											multiple
											isObjectOption
											options={this.props.assetTypes}
											optionAccessor="name"
											defaultValue={this.props.driver.profile.license.assetType}
											onChange={this.props.getUpdateAssetTypeValue}
											id="assetType"
										/>
									</Col>
									<Col xs="12" md="4" lg="4">
										<FormGroup tag="fieldset" className="d-flex">
											<FormGroup check>
												<Label check className="mx-1">
													<Input
														disabled={
															this.props.driver.profile.license.driverStatus === "Dispatched" || this.props.is_unavailable
														}
														type="checkbox"
														name="status"
														id="is_active"
														onChange={this.props.terminatedORUnavailable}
														defaultChecked={!this.props.driver.is_active}
													/>
													Terminated
												</Label>
											</FormGroup>
											<FormGroup check className="mx-2">
												<Label check className="mx-1">
													<Input
														disabled={
															!this.props.is_active ||
															this.props.is_unavailable ||
															this.props.driver.profile.license.driverStatus === "Dispatched"
														}
														id="Hold"
														name="status"
														type="checkbox"
														onChange={this.props.terminatedORUnavailable}
														defaultChecked={this.props.driver.profile.license.driverStatus === "Hold"}
													/>
													Hold
												</Label>
											</FormGroup>
											<FormGroup check className="mx-2">
												<Label check className="mx-1">
													<Input
														disabled={
															this.props.driver.profile.license.driverStatus === "Dispatched" ||
															!this.props.is_active ||
															this.props.hold
														}
														id="Unavailable"
														name="status"
														type="checkbox"
														onChange={this.props.terminatedORUnavailable}
														defaultChecked={this.props.driver.profile.license.driverStatus === "Unavailable"}
													/>
													Unavailable
												</Label>
											</FormGroup>
										</FormGroup>
										<FormGroup className="my-1 d-flex">
											<Label className="mx-1">{t("note")}</Label>
											<Input
												disabled={this.props.driver.profile.license.driverStatus === "Dispatched"}
												bsSize="sm"
												id="note"
												name="note"
												type="text"
												onChange={this.props.getUpdateLicenseValue}
												defaultValue={this.props.driver.profile.license.note}
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row hidden>
									<Col className="d-flex">
										<Label className="mx-1">{t("status")}</Label>
										<Selector
											width="50%"
											required
											options={this.props.driverStatus}
											defaultValue={this.props.driver.profile.license.driverStatus}
											onChange={this.props.getUpdateLicenseValue}
											id="driverStatus"
										/>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<Col className="d-flex justify-content-end my-1">
									<Button className="mr-2" color="dark" onClick={this.props.editView}>
										{t("cancel")}
									</Button>
									<GetPermission perm="users.update_ops_driver">
										<Button code="perm" color="info" type="submit">
											{t("submit")}
										</Button>
									</GetPermission>
								</Col>
							</CardFooter>
						</Card>
					</Form>
				</Col>
			</Row>
		);
	}
}
export default DriverForm;
