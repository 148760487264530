import { Col, Row } from "reactstrap";
import React, { Component } from "react";

class DataStudio extends Component {
	constructor() {
		super();
		this.state = {};
	}

	render() {
		return (
			<>
				<Row>
					<Col xs="12">
						<iframe
							allowFullScreen
							title="tripDetailCost"
							style={{ border: 0, height: "1000px", width: "100%" }}
							src="https://datastudio.google.com/embed/reporting/fcc5808a-7efe-4aca-a2ae-3466b548f4c9/page/p_o1rh813s1c"></iframe>
					</Col>
				</Row>
			</>
		);
	}
}

export default DataStudio;
