import i18n from "i18n";
import LegCard from "./leg_card";
import React, { Component } from "react";
import { Button, Card, CardBody, CardHeader, Col, Collapse, Row } from "reactstrap";

class LegAccordion extends Component {
	constructor() {
		super();
		this.state = { accordion: false };
	}

	toggleAccordion = () => {
		this.setState({ accordion: !this.state.accordion });
	};

	render() {
		const t = i18n.t;
		return (
			<>
				<Card className={`pt-0 pb-0 mt-4 bg-transparent shadow-none`}>
					<CardHeader
						className={`mb-3 h-auto pl-0 pr-0   ${this.state.accordion ? "bg-transparent" : "bg-white"}  border-0 rounded`}
						id="headingOne">
						<Button
							block
							color="link"
							style={{ cursor: "pointer" }}
							className={`text-left m-0 p-0  text-decoration-none ${this.state.accordion ? "text-dark" : ""}`}
							onClick={() => this.toggleAccordion()}
							aria-expanded={this.state.accordion}>
							<div
								className={`card-header-title fsize-2 text-capitalize font-weight-normal ${this.state.accordion ? "" : "p-3"}`}>
								{t("legs")}
							</div>
						</Button>
					</CardHeader>
					<Collapse isOpen={this.state.accordion} data-parent="#accordion" id="collapseOne" aria-labelledby="headingOne">
						<CardBody>
							<Row>
								{this.props.data.map((leg, index) => {
									return (
										<Col xs="12" md="6" key={index}>
											<LegCard data={leg} />
										</Col>
									);
								})}
							</Row>
						</CardBody>
					</Collapse>
				</Card>
			</>
		);
	}
}
export default LegAccordion;
