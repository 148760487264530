import { Col, Row } from "reactstrap";
import React, { Component } from "react";

class PrintableExpenseForm extends Component {
	calculateGrandTotalExpenses = () => {
		let grandTotal = 0;
		this.props.data.forEach((trip) => {
			trip.expenses.forEach((expense) => {
				if (expense.expenseType !== "Driver Bonus") grandTotal += Number(expense.amount);
			});
		});
		return grandTotal;
	};

	render() {
		return (
			<>
				<Row>
					<Col xs="12">{this.props.wo}</Col>
					<Col xs="12">
						<table>
							<tbody>
								{this.props.data.map((trip, index) => {
									if (index === 0)
										return (
											<>
												<tr key={(index + 1) * 1000}>
													<th style={{ fontSize: "9px", marginRight: "5px", border: "1px solid black" }}>Number</th>
													{// eslint-disable-next-line
													trip.expenses.map((expense) => {
														if (expense.expenseType !== "Total Expenses")
															return (
																<th
																	style={{ fontSize: "9px", marginRight: "5px", border: "1px solid black" }}
																	key={expense.expenseType}>
																	{expense.expenseType}
																</th>
															);
													})}
												</tr>
												<tr key={(index + 1) * 1001}>
													<td style={{ fontSize: "7px", marginRight: "5px", border: "1px solid black" }}>{index + 1}</td>
													{// eslint-disable-next-line
													trip.expenses.map((expense, inde) => {
														if (expense.expenseType !== "Total Expenses")
															return (
																<td
																	key={(inde + 1) * 1202}
																	style={{ fontSize: "7px", marginRight: "5px", border: "1px solid black" }}>
																	{expense.amount}
																</td>
															);
													})}
												</tr>
											</>
										);
									return (
										<tr key={index}>
											<td style={{ fontSize: "7px", marginRight: "5px", border: "1px solid black" }}>{index + 1}</td>
											{// eslint-disable-next-line
											trip.expenses.map((expense, ind) => {
												if (expense.expenseType !== "Total Expenses")
													return (
														<td key={(ind + 1) * 1271} style={{ fontSize: "7px", marginRight: "5px", border: "1px solid black" }}>
															{expense.amount}
														</td>
													);
											})}
										</tr>
									);
								})}
							</tbody>
						</table>
					</Col>
					<Col xs="12" className="d-flex justify-content-start">
						Grand Total: {this.calculateGrandTotalExpenses()}
					</Col>
				</Row>
			</>
		);
	}
}

export default PrintableExpenseForm;
