import CountUp from "react-countup";
import React, { Component } from "react";
import { Progress } from "react-sweet-progress";
import { Card, CardBody, Row, Col } from "reactstrap";

class ProgressCard extends Component {
	constructor() {
		super();
		this.state = {
			colors: {
				success: "#3ac47d",
				warning: "#f7b924",
				danger: "#d92550",
				info: "#5bc0de",
				dark: "#3a3f44",
			},
		};
	}
	render() {
		const { percent, color, count, title } = this.props;
		return (
			<>
				<Card className="rounded shadow-sm my-2">
					<CardBody>
						<Row>
							<Col>
								<div className="widget-content p-0">
									<div className="widget-content-wrapper">
										<div className="widget-content-left mr-3">
											<div className="icon-wrapper m-0">
												<div className="progress-circle-wrapper">
													<Progress
														type="circle"
														percent={percent}
														width="100%"
														symbolClassName={`text-${color}`}
														theme={{
															active: {
																trailColor: "#e1e1e1",
																color: this.state.colors[color],
															},
															success: {
																symbol: percent + "%",
																color: this.state.colors[color],
															},
														}}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Col>
							<Col>
								<div className="widget-content-left">
									<div className="widget-heading">{title}</div>
									<div className={`widget-subheading widget-numbers-sm text-${color}`}>
										<CountUp start={0} end={count} separator="" decimals={0} decimal="," prefix="" duration="5" />
									</div>
								</div>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</>
		);
	}
}

export default ProgressCard;
